import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import { closeDialog } from "../../store/user/actions/dialogActions/dialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddSubCategory from "../../ui/settings/categories/AddSubCategory";

const actions = {
  closeDialog,
};

const AddMainCategoryDialog = ({ closeDialog }) => {
  const handleClose = () => {
    closeDialog();
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">Add Sub Category</DialogTitle>
      <DialogContent>
        <AddSubCategory />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, actions)(AddMainCategoryDialog);
