import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_APP_TEMPLATES,
    FETCH_APP_TEMPLATE
} from "../../constants/appAdminTemplateConstants/appAdminTemplateConstants";



const initialState = {
    allAppTemplates: [],
    appTemplate: []
};

const allAppTemplates = (state, payload) => {
    return {
        ...state,
        allAppTemplates: payload.allAppTemplates,
    };
};

const getAppTemplate = (state, payload) => {
    return {
        ...state,
        appTemplate: payload.appTemplate,
    };
};

export default createReducer(initialState, {
    [FETCH_ALL_APP_TEMPLATES]: allAppTemplates,
    [FETCH_APP_TEMPLATE]: getAppTemplate,
});
