import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";



// REDUX FIREBASE+
import { Provider } from "react-redux";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./theme";
import thunkMiddleware from "redux-thunk";
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase";
import { getFirestore, reduxFirestore } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import firebase from "./config/firebase";
import rootReducer from "./store/rootReducer/rootReducer";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// STRIPE
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe('pk_live_51IEjhCKakaaIWGid5DHkmETTekVIfZzULMA2ZpgJ4fl4ysaYFOphdyqF9dLS8OJNlQXdUF6RLv48hbP2VCX2oePS00EsFcpfBo');

const middlewares = [
  thunkMiddleware.withExtraArgument({ getFirebase, getFirestore }),
];
const composedEnhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  reduxFirestore(firebase)
);
const store = createStore(rootReducer, composedEnhancer);

const reactReduxFirestoreConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  updateProfileOnLogin: false,
};

const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirestoreConfig,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
          <BrowserRouter>
            {/*<Elements stripe={stripePromise}>*/}
              <App />
            {/*</Elements>*/}
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
