import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import { FETCH_CURRENT_ORGANIZATION } from "../../constants/currentOrganizationConstants/currentOrganizationConstants";

export const fetchCurrentOrganization = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const organizationQuery = firestore
      .collection("organizations")
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let query = await organizationQuery.get();

      let organization = [];

      if (query.exists) {
        let org = {
          id: query.id,
          ...query.data(),
        };
        organization.push(org);
      }

      if (!query.exists) {
        return;
      }

      dispatch({ type: FETCH_CURRENT_ORGANIZATION, payload: { organization } });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
