import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { reduxForm } from "redux-form";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAllContacts } from "../../store/user/actions/contactsActions/contactsActions";

import { openDialog } from "../../store/user/actions/dialogActions/dialogActions";

import {
  fetchOpportunity,
  updateOpportunity,
  updateOpportunityDelivery,
} from "../../store/user/actions/opportunityActions/opportunityActions";
import EditOpportunityItemsTable from "../../ui/opportunities/EditOpportunityItemsTable";
import EditOpportunityDetails from "../../ui/opportunities/EditOpportunityDetails";
import { combineValidators, isRequired } from "revalidate";
import {fetchAllInventory} from "../../store/user/actions/inventoryActions/inventoryActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const validate = combineValidators({
  // EXISTING CLIENT
  clientId: isRequired({ message: "Client Name is required" }),

  // NEW CLIENT
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  address1: isRequired({ message: "Address is required" }),
  city: isRequired({ message: "City is required" }),
  state: isRequired({ message: "State is required" }),
  zipCode: isRequired({ message: "Zip Code is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
  email: isRequired({ message: "Email is required" }),

  // DELIVERY ADDRESS
  deliveryContactName: isRequired({ message: "Contact Name is required" }),
  deliveryAddress1: isRequired({ message: "Address is required" }),
  deliveryCity: isRequired({ message: "City is required" }),
  deliveryState: isRequired({ message: "State is required" }),
  deliveryZipCode: isRequired({ message: "Zip Code is required" }),
  deliveryContactNumber: isRequired({ message: "Contact Number is required" }),

  // INQUIRY DETAILS
  title: isRequired({ message: "Inquiry Name is required" }),
  setupDate: isRequired({ message: "Setup Date is required" }),
  pickupDate: isRequired({ message: "Pickup Date is required" }),
  eventStartDate: isRequired({ message: "Event Start Date is required" }),
  eventEndDate: isRequired({ message: "Event End Date is required" }),
});

const actions = {
  openDialog,
  fetchOpportunity,
  updateOpportunity,
  updateOpportunityDelivery,
  fetchAllContacts,
  fetchAllInventory
};

const mapStateToProps = (state) => {
  let contacts = [];
  let items = [];
  let opportunity = {};

  if (state.contacts.allContacts && state.contacts.allContacts.length > 0) {
    contacts = state.contacts.allContacts;
  }

  if (
    state.opportunities.opportunity &&
    state.opportunities.opportunity.length > 0
  ) {
    items = state.opportunities.opportunity[0].items;
    opportunity = state.opportunities.opportunity[0];
  }

  return {
    initialValues: state.opportunities.opportunity[0],
    contacts,
    items,
    opportunity,
  };
};

const EditOrder = ({
  openDialog,
  fetchOpportunity,
  updateOpportunity,
  updateOpportunityDelivery,
  fetchAllContacts,
  contacts,
  items,
  opportunity,
  handleSubmit,
  submitting,
                     fetchAllInventory
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const [contact, setContact] = useState({});
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(true);

  const [firstLoad, setFirstLoad] = useState(true)

  const clientId = opportunity.clientId;

  useEffect(() => {
    fetchAllContacts();
    fetchOpportunity(id);
    setContact(contacts.filter((contact) => contact.id === clientId)[0]);
    setShowDeliveryAddress(opportunity.sameDeliveryAddress ? false : true);
    if (firstLoad) {
      fetchAllInventory(opportunity, "name");
    }

  }, [
    fetchAllContacts,
    fetchOpportunity,
    id,
    setContact,
    contacts,
    clientId,
    // opportunity,
    setShowDeliveryAddress,
    fetchAllInventory,
      opportunity
  ]);

  const handleUpdateOpportunity = async (values) => {
    try {
      await updateOpportunity(id, values, opportunity, "order");
      history.push("/orders");
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactChange = (id) => {
    const filteredContacts = contacts.filter((contact) => contact.id === id);
    setContact(filteredContacts[0]);
  };

  const handleDeliveryAddress = () => {
    updateOpportunityDelivery(id, opportunity.sameDeliveryAddress);
  };

  const handleRefreshOpportunity = () => {
    fetchOpportunity(id)
  }

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleUpdateOpportunity)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Edit Order</Typography>
            </Grid>

            <Grid
              item
              style={
                matchesMD
                  ? { marginTop: "1.5em" }
                  : {
                      marginLeft: "auto",
                      marginTop: "1.5em",
                      paddingRight: "2em",
                    }
              }
            >
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/orders")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*DETAILS*/}
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={2} md={2} sm={1} xs={1}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={8} md={8} sm={9} xs={9}>
              <Typography variant={"h6"}>Details</Typography>
            </Grid>
          </Grid>

          <EditOpportunityDetails
            contacts={contacts}
            contact={contact}
            handleContactChange={handleContactChange}
            showDeliveryAddress={showDeliveryAddress}
            handleDeliveryAddress={handleDeliveryAddress}
          />

          {/*ITEMS*/}
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={2} md={2} sm={1} xs={1}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={8} md={8} sm={9} xs={9}>
              <Grid item container alignItems={"center"}>
                <Grid item>
                  <Typography variant={"h6"}>Items</Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "1em" }}>
                  <Button
                    variant={"contained"}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    }}
                    size={"small"}
                    onClick={() => {
                      setFirstLoad(false)
                      openDialog("ProductPickerDialog", {
                        id,
                        opportunity,
                        type: "order",
                      })
                    }

                    }
                  >
                    Add Items
                  </Button>
                </Grid>

                <Grid item style={{ paddingLeft: "1em" }}>
                  <Button
                      variant={"contained"}
                      style={{
                        backgroundColor: theme.palette.themeBlue,
                        color: "white",
                      }}
                      size={"small"}
                      onClick={() => handleRefreshOpportunity()}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*ITEMS TABLE*/}
          {items.length > 0 && (
            <Grid item container style={{ marginTop: "1.5em" }}>
              <Grid item lg={2} md={2} sm={1} xs={1}>
                {/*BLANK*/}
              </Grid>
              <Grid item lg={8} md={8} sm={9} xs={9}>
                <EditOpportunityItemsTable
                  opportunity={opportunity}
                  items={items}
                  jobId={id}
                  type={"order"}
                />
              </Grid>
            </Grid>
          )}

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto", paddingRight: "2em" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>

              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/orders")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "EditOrder",
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(EditOrder)
);
