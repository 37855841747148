import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import { FETCH_ITEM_TRANSACTIONS } from "../../constants/transactionConstants/transactionConstants";

export const fetchItemTransactions = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const transactionQuery = firestore
      .collection("itemTransactions")
      .where("itemId", "==", `${id}`);

    try {
      dispatch(asyncActionStart());
      let query = await transactionQuery.get();

      let itemTransactions = [];

      for (let i = 0; i < query.docs.length; i++) {
        let item = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
        };
        itemTransactions.push(item);
      }

      dispatch({
        type: FETCH_ITEM_TRANSACTIONS,
        payload: { itemTransactions },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
