import { createReducer } from "../../../../common/util/reducerUtil";
import {
    FETCH_ALL_ORGANIZATION_TEMPLATES,
    FETCH_ORGANIZATION_TEMPLATE
} from "../../constants/organizationTemplateConstants/organizationTemplateConstants";


const initialState = {
    allOrganizationTemplates: [],
    organizationTemplate: []
};

const allOrganizationTemplates = (state, payload) => {
    return {
        ...state,
        allOrganizationTemplates: payload.allOrganizationTemplates,
    };
};

const getOrganizationTemplate = (state, payload) => {
    return {
        ...state,
        organizationTemplate: payload.organizationTemplate,
    };
};

export default createReducer(initialState, {
    [FETCH_ALL_ORGANIZATION_TEMPLATES]: allOrganizationTemplates,
    [FETCH_ORGANIZATION_TEMPLATE]: getOrganizationTemplate,
});
