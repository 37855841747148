import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Grid, IconButton} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { openSubDialog } from "../../store/user/actions/dialogActions/dialogActions";
import { addOpportunityItem } from "../../store/user/actions/opportunityActions/opportunityActions";
import PhotoIcon from '@material-ui/icons/Photo';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
}));

const actions = {
  openSubDialog,
  addOpportunityItem,
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.loading,
  };
};

const OpportunityTableRow = ({
  openSubDialog,
  addOpportunityItem,
  row,
  jobId,
  opportunity,
  type,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [disabled, setDisabled] = useState(false);

  const handleAddItem = async () => {
    let values = {
      qty: 1,
    };

    setDisabled(true);
    await addOpportunityItem(row, jobId, opportunity, values, type);
  };



  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{row?.name}</TableCell>
      <TableCell className={classes.tableCell}><IconButton aria-label="photo" onClick={() =>
          openSubDialog("ItemImageDialog", { row })
      }>
          <PhotoIcon color={'primary'} />
      </IconButton></TableCell>
      {!matchesSM && (
        <Fragment>
          <TableCell className={classes.tableCell}>
            {row?.mainCategory}
          </TableCell>
          {/*<TableCell className={classes.tableCell}>*/}
          {/*  {row?.subCategory}*/}
          {/*</TableCell>*/}
        </Fragment>
      )}

      {!matchesXS && (
        <TableCell
          className={classes.tableCell}
          style={{ textAlign: "center" }}
        >
          {row?.totalOwned}
        </TableCell>
      )}

      <TableCell
        className={classes.tableCell}
        style={{
          textAlign: "center",
          fontWeight: "bold",
          color:
            row.availabilityStatus === "OK"
              ? theme.palette.available
              : row.availabilityStatus === "CHECK AVAILABILITY"
              ? theme.palette.caution
              : row.availabilityStatus === "NO AVAILABILITY"
              ? theme.palette.notAvailable
              : null,
        }}
      >
        {row?.available}
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Grid item container direction={matchesMD ? "column" : "row"}>
          <Grid item>
            <Button
              variant={"contained"}
              size={"small"}
              style={{
                backgroundColor: theme.palette.themeBlue,
                color: "white",
              }}
              onClick={() =>
                openSubDialog("AvailabilityDialog", { row, opportunity })
              }
            >
              Availability
            </Button>
          </Grid>
          {/*ADD ONE*/}
          <Grid
            item
            style={
              matchesMD ? { marginTop: "0.5em" } : { paddingLeft: "0.5em" }
            }
          >
            <Button
              disabled={row.available === 0 || disabled}
              color={"primary"}
              variant={"contained"}
              size={"small"}
              style={{ color: "white" }}
              onClick={() => handleAddItem()}
            >
              Add 1
            </Button>
          </Grid>
          {/*ADD QTY*/}
          <Grid
            item
            style={
              matchesMD ? { marginTop: "0.5em" } : { paddingLeft: "0.5em" }
            }
          >
            <Button
              disabled={row.available === 0}
              color={"primary"}
              variant={"contained"}
              size={"small"}
              style={{ color: "white" }}
              onClick={() =>
                openSubDialog("AddItemDialog", {
                  row,
                  jobId,
                  opportunity,
                  type,
                })
              }
            >
              Add Qty
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default connect(mapStateToProps, actions)(OpportunityTableRow);
