import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import OpportunityTableRow from "./OpportunityTableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  // ADD STYLES HERE
}));

const AddOpportunityItemTable = ({ rows, opportunity, jobId, type }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Item</TableCell>
              <TableCell className={classes.tableCell}>Image</TableCell>
              {!matchesSM && (
                <Fragment>
                  <TableCell className={classes.tableCell}>Category</TableCell>
                  {/*<TableCell className={classes.tableCell}>*/}
                  {/*  Sub Category*/}
                  {/*</TableCell>*/}
                </Fragment>
              )}

              {!matchesXS && (
                <TableCell className={classes.tableCell}>Owned</TableCell>
              )}

              <TableCell className={classes.tableCell}>Available</TableCell>
              <TableCell className={classes.tableCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <OpportunityTableRow
                  key={row.id}
                  row={row}
                  jobId={jobId}
                  opportunity={opportunity}
                  type={type}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{
          caption: classes.paginationText,
          root: classes.paginationText,
        }}
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AddOpportunityItemTable;
