import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  deleteTemplate,
  toggleTemplateStatus
} from "../../../store/organizationAdmin/actions/organizationTemplateActions/organizationTemplateActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  image: {
    width: "50px",
  },
  tableCell: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
  },
  paginationText: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
  },
}));

const actions = {
  toggleTemplateStatus,
  deleteTemplate
};

const TemplatesTable = ({ toggleTemplateStatus, deleteTemplate, rows }) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [templateId, setTemplateId] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleToggleTemplateStatus = (id, category, active) => {
    toggleTemplateStatus(id, category, active);
  };

  const handleDeleteOrganizationTemplate = async () => {
    await deleteTemplate(templateId)
    setTemplateId('')
    setDialogOpen(false)
  };

  return (
    <Fragment>
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteOrganizationTemplate()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  Template Name
                </TableCell>
                {!matchesXS && (
                  <TableCell className={classes.tableCell}>Category</TableCell>
                )}

                <TableCell className={classes.tableCell}>Status</TableCell>
                <TableCell className={classes.tableCell}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className={classes.tableCell}>
                      {row.templateName}
                    </TableCell>
                    {!matchesXS && (
                      <TableCell className={classes.tableCell}>
                        {row.templateCategory === "newUser" && "New User"}
                        {row.templateCategory === "quotation" && "Quotation"}
                        {row.templateCategory === "order" && "Order"}
                      </TableCell>
                    )}

                    <TableCell className={classes.tableCell}>
                      {row.templateActive ? "Active" : "Disabled"}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      <Grid
                        item
                        container
                        direction={matchesSM ? "column" : "row"}
                      >
                        <Grid
                          item
                          style={
                            matchesSM
                              ? { marginTop: "0.5em" }
                              : { marginLeft: "0.5em" }
                          }
                        >
                          <Button
                            variant={"contained"}
                            size={"small"}
                            // color={'primary'}
                            style={{
                              backgroundColor: row.templateActive
                                ? theme.palette.primary.main
                                : theme.palette.captionGrey,
                              color: "white",
                            }}
                            onClick={() =>
                              handleToggleTemplateStatus(
                                row.id,
                                row.templateCategory,
                                row.templateActive
                              )
                            }
                          >
                            {row.templateActive ? "Disable" : "Enable"}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          style={
                            matchesSM
                              ? { marginTop: "0.5em" }
                              : { marginLeft: "0.5em" }
                          }
                        >
                          <Button
                            variant={"contained"}
                            size={"small"}
                            style={{
                              backgroundColor: theme.palette.themeBlue,
                              color: "white",
                            }}
                            onClick={() =>
                              history.push(
                                `/settings/templates/edit?id=${row.id}`
                              )
                            }
                          >
                            Edit
                          </Button>
                        </Grid>
                        <Grid
                          item
                          style={
                            matchesSM
                              ? { marginTop: "0.5em" }
                              : { marginLeft: "0.5em" }
                          }
                        >
                          <Button
                            disabled={row.templateMaster}
                            color={"secondary"}
                            variant={"contained"}
                            size={"small"}
                            style={{ color: "white" }}
                            onClick={() => {
                              setTemplateId(row.id);
                              setDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            caption: classes.paginationText,
            root: classes.paginationText,
          }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default connect(null, actions)(TemplatesTable);
