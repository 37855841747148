import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import { format, parseISO } from "date-fns";
import {
  deleteOpportunity, updateOpportunityCollectedStatus,
  updateOpportunityDeliveredStatus
} from "../../store/user/actions/opportunityActions/opportunityActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "10px",
  },
  tableCell: {
    fontSize: "1.4em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  },
  paginationText: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
  },
}));

const actions = {
  deleteOpportunity,
  updateOpportunityDeliveredStatus,
  updateOpportunityCollectedStatus
};

const OpportunityTable = ({ rows, deleteOpportunity, updateOpportunityDeliveredStatus,updateOpportunityCollectedStatus, type, directory }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [opportunityId, setOpportunityId] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteOpportunity = () => {
    deleteOpportunity(opportunityId, type);
    setDialogOpen(false);
    setOpportunityId("");
  };

  const handleOpportunityDeliveryStatus = (id) => {
    updateOpportunityDeliveredStatus(id, type);
  };

  const handleOpportunityCollectedStatus = (id) => {
    updateOpportunityCollectedStatus(id, type, 'archived');
  };

  return (
    <Fragment>
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete {type}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteOpportunity()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Title</TableCell>

                <TableCell className={classes.tableCell}>Client Name</TableCell>
                <TableCell className={classes.tableCell}>Setup Date</TableCell>
                {!matchesMD && (
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      Event Start Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Event End Date
                    </TableCell>
                  </Fragment>
                )}

                {!matchesXS && (
                  <TableCell className={classes.tableCell}>
                    Pickup Date
                  </TableCell>
                )}

                <TableCell className={classes.tableCell}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id} style={row.delivered && row.opportunityType === 'order' ? {backgroundColor: theme.palette.calendarSetup} : null}>
                    <TableCell className={classes.tableCell}>
                      {row.title}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {row.contact?.firstName + " " + row.contact?.lastName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {format(parseISO(row.setupDate), "EEE MMM do y")}
                    </TableCell>
                    {!matchesMD && (
                      <Fragment>
                        <TableCell className={classes.tableCell}>
                          {format(parseISO(row.eventStartDate), "EEE MMM do y")}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {format(parseISO(row.eventEndDate), "EEE MMM do y")}
                        </TableCell>
                      </Fragment>
                    )}

                    {!matchesXS && (
                      <TableCell className={classes.tableCell}>
                        {format(parseISO(row.pickupDate), "EEE MMM do y")}
                      </TableCell>
                    )}

                    <TableCell className={classes.tableCell}>
                      <Grid
                        item
                        container
                        direction={matchesSM ? "column" : "row"}
                      >
                        {!row.delivered && row.opportunityType === 'order' && (
                            <Grid item>
                              <Button
                                  color={'primary'}
                                  variant={"outlined"}
                                  size={"small"}
                                  onClick={() => handleOpportunityDeliveryStatus(row.id)}
                              >
                                Delivered
                              </Button>
                            </Grid>
                        )}
                        {row.delivered && row.opportunityType === 'order' && (
                            <Grid item>
                              <Button
                                  disabled={row.opportunityType === 'archived'}
                                  style={{
                                    borderColor: theme.palette.themeBlue,
                                    color: theme.palette.themeBlue,
                                  }}
                                  variant={"outlined"}
                                  size={"small"}
                                  onClick={() => handleOpportunityCollectedStatus(row.id)}
                              >
                                Collected
                              </Button>
                            </Grid>
                        )}



                        <Grid item style={
                          matchesSM
                              ? { marginTop: "0.5em" }
                              : { marginLeft: "0.5em" }
                        }>
                          <Button
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            style={{ color: "white" }}
                            onClick={() =>
                              history.push(`/${directory}/view?id=${row.id}`)
                            }
                          >
                            View
                          </Button>
                        </Grid>
                        {row.opportunityType !== "archived" && (
                          <Grid
                            item
                            style={
                              matchesSM
                                ? { marginTop: "0.5em" }
                                : { marginLeft: "0.5em" }
                            }
                          >
                            <Button
                              variant={"contained"}
                              size={"small"}
                              style={{
                                backgroundColor: theme.palette.themeBlue,
                                color: "white",
                              }}
                              onClick={() =>
                                history.push(`/${directory}/edit?id=${row.id}`)
                              }
                            >
                              Edit
                            </Button>
                          </Grid>
                        )}

                        <Grid
                          item
                          style={
                            matchesSM
                              ? { marginTop: "0.5em" }
                              : { marginLeft: "0.5em" }
                          }
                        >
                          <Button
                            color={"secondary"}
                            variant={"contained"}
                            size={"small"}
                            style={{ color: "white" }}
                            onClick={() => {
                              setOpportunityId(row.id);
                              setDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            caption: classes.paginationText,
            root: classes.paginationText,
          }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default connect(null, actions)(OpportunityTable);
