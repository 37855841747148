import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import LiveSearch from "../../../../ui/search/LiveSearch";
// import { fetchAllOrganizationUsers } from "../../../store/organizationAdmin/actions/organizationUserActions/organizationUserActions";

import { useTheme } from "@material-ui/styles";
import AppAdminsTable from "../../../../ui/appAdmin/settings/appAdmins/AppAdminsTable";
import { fetchAllAppAdmins } from "../../../../store/appAdmin/actions/appAdminAdminsActions/appAdminAdminsActions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllAppAdmins,
};

const mapStateToProps = (state) => {
  let appAdmins = [];

  if (state.appAdmins.allAppAdmins && state.appAdmins.allAppAdmins.length > 0) {
    appAdmins = state.appAdmins.allAppAdmins;
  }
  return {
    appAdmins,
  };
};

const AppAdmins = ({ fetchAllAppAdmins, appAdmins, profile }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllAppAdmins();
  }, [fetchAllAppAdmins]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>App Admins</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LiveSearch data={appAdmins} setSearchResults={setSearchResults} />
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={matchesSM ? { marginTop: "0.5em" } : null}
        >
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/admin/settings/appAdmins/new")}
              >
                Add Admin
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <AppAdminsTable
          profile={profile}
          rows={
            searchResults < 1
              ? appAdmins
              : appAdmins.filter((contact) => contact.search)
          }
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AppAdmins);
