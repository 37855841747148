import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import AppTemplatesTable from "../../../../ui/appAdmin/settings/templates/AppTemplatesTable";
import {fetchAllAppTemplates} from "../../../../store/appAdmin/actions/organizationTemplateActions/appAdminTemplateActions";



const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllAppTemplates
};

const mapStateToProps = (state) => {
  let appTemplates = [];

  if (
    state.appTemplates.allAppTemplates &&
    state.appTemplates.allAppTemplates.length > 0
  ) {
    appTemplates =
      state.appTemplates.allAppTemplates;
  }
  return {
    appTemplates,
  };
};

const AppTemplates = ({
                        fetchAllAppTemplates,
  appTemplates,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  // const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllAppTemplates();
  }, [fetchAllAppTemplates]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>App Templates</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {/*BLANK*/}
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={matchesSM ? { marginTop: "0.5em" } : null}
        >
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/admin/settings/templates/new")}
              >
                Add Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <AppTemplatesTable
          rows={
            appTemplates
          }
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AppTemplates);
