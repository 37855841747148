import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";

import {
  differenceInCalendarDays,
  addDays,
  format,
  getUnixTime,
} from "date-fns";
import { fetchContact } from "../contactsActions/contactsActions";

import {
  FETCH_ALL_OPPORTUNITIES,
  FETCH_OPPORTUNITY,
} from "../../constants/opportunityConstants/opportunityConstants";

export const fetchAllOpportunities = (type) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    let opportunitiesQuery = null

    if (type !== 'archived') {
      opportunitiesQuery = firestore
          .collection("opportunities")
          .where("opportunityType", "==", `${type}`)
          .orderBy("setupDate", "asc")
          .where("organizationId", "==", `${organizationId}`);
    }

    if (type === 'archived') {
      opportunitiesQuery = firestore
          .collection("opportunities")
          .where("opportunityType", "==", `${type}`)
          .orderBy("setupDate", "desc")
          .where("organizationId", "==", `${organizationId}`);
    }



    const contactsQuery = firestore.collection("contacts").where("organizationId", "==", `${organizationId}`);

    try {
      dispatch(asyncActionStart());

      let query = await opportunitiesQuery.get();
      let contactQuery = await contactsQuery.get()

      let contacts = []
      let allOpportunities = [];

      for (let i = 0; i < contactQuery.docs.length; i++) {

        let contact = {
          ...contactQuery.docs[i].data(),
          id: contactQuery.docs[i].id,
        };
        contacts.push(contact);
      }


      for (let i = 0; i < query.docs.length; i++) {
        let contact = contacts.filter(array => array.id === query.docs[i].data().clientId)[0]
        let opportunity = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          contact: contact
        };
        allOpportunities.push(opportunity);
      }

      dispatch({
        type: FETCH_ALL_OPPORTUNITIES,
        payload: { allOpportunities },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchOpportunity = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);
    const opportunityItemsQuery = firestore
      .collection("opportunities")
      .doc(`${id}`)
      .collection("items");

    try {
      dispatch(asyncActionStart());

      let queryItems = await opportunityItemsQuery.get();
      let query = await opportunityQuery.get();

      let opportunity = [];
      let items = [];
      let totalCost = 0;

      if (!queryItems.empty) {
        for (let i = 0; i < queryItems.docs.length; i++) {
          let item = {
            ...queryItems.docs[i].data(),
            id: queryItems.docs[i].id,
            itemId: queryItems.docs[i].data().id,
          };
          items.push(item);
        }

        // TOTAL COST
        for (let i = 0; i < items.length; i++) {
          const rentalPrice = Number(items[i].rentalPrice);
          const qty = Number(items[i].qty);
          const lineTotal = rentalPrice * qty;
          totalCost = totalCost + lineTotal;
        }
      }

      if (query.exists) {
        const clientId = query.data().clientId;
        dispatch(fetchContact(clientId));

        let inq = {
          id: query.id,
          ...query.data(),
          items: items,
          totalCost: totalCost,
          // totalDays: totalDays,
        };
        opportunity.push(inq);
      }

      if (!query.exists) {
        return;
      }

      dispatch({ type: FETCH_OPPORTUNITY, payload: { opportunity } });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewOpportunity = (values, newClient, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const firebaseQuery = firebase.auth().currentUser;
    const opportunityQuery = firestore.collection("opportunities");

    const contactQuery = firestore.collection("contacts");

    try {
      dispatch(asyncActionStart());

      let newContact = "";
      let delAddress = "";

      const numberOfDays =
        differenceInCalendarDays(
          new Date(values.eventEndDate),
          new Date(values.eventStartDate)
        ) + 1;

      if (newClient) {
        let client = {
          firstName: values.firstName ? values.firstName : "",
          lastName: values.lastName ? values.lastName : "",
          address1: values.address1 ? values.address1 : "",
          address2: values.address2 ? values.address2 : "",
          city: values.city ? values.city : "",
          state: values.state ? values.state : "",
          zipCode: values.zipCode ? values.zipCode : "",
          contactNumber: values.contactNumber ? values.contactNumber : "",
          email: values.email ? values.email : "",
          notes: values.notes ? values.notes : "",
        };

        newContact = await contactQuery.add({
          ...client,
          createdAt: new Date().toISOString(),
          organizationId: organizationId,
        });
      }

      if (!values.sameDeliveryAddress) {
        delAddress = {
          deliveryContactName: values.deliveryContactName
            ? values.deliveryContactName
            : "",
          deliveryAddress1: values.deliveryAddress1
            ? values.deliveryAddress1
            : "",
          deliveryAddress2: values.deliveryAddress2
            ? values.deliveryAddress2
            : "",
          deliveryCity: values.deliveryCity ? values.deliveryCity : "",
          deliveryState: values.deliveryState ? values.deliveryState : "",
          deliveryZipCode: values.deliveryZipCode ? values.deliveryZipCode : "",
          deliveryContactNumber: values.deliveryContactNumber
            ? values.deliveryContactNumber
            : "",
          deliveryEmail: values.deliveryEmail ? values.deliveryEmail : "",
          deliveryNotes: values.deliveryNotes ? values.deliveryNotes : "",
        };
      }

      let opportunity = await opportunityQuery.add({
        ...delAddress,
        clientId: newClient ? newContact.id : values.clientId,
        title: values.title,
        description: values.description ? values.description : "",
        setupDate: values.setupDate,
        eventStartDate: values.eventStartDate,
        eventEndDate: values.eventEndDate,
        pickupDate: values.pickupDate,
        totalDays: numberOfDays,
        sameDeliveryAddress: values.sameDeliveryAddress ? true : false,
        opportunityType: type,
        addedBy: firebaseQuery.uid,
        createdAt: format(new Date(values.eventStartDate), "MMMM y"),
        organizationId: organizationId,
      });

      dispatch(asyncActionFinish());
      return opportunity;
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addOpportunityItem = (row, jobId, opportunity, values, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityItemQuery = firestore
      .collection("opportunities")
      .doc(`${jobId}`)
      .collection("items");
    const transactionQuery = firestore.collection("itemTransactions");

    try {
      dispatch(asyncActionStart());

      const numberOfDays = differenceInCalendarDays(
        new Date(opportunity.pickupDate),
        new Date(opportunity.setupDate)
      );

      await opportunityItemQuery.add({
        ...row,
        qty: values.qty,
      });

      // ITEM TRANSACTIONS
      for (let i = 0; i <= numberOfDays; i++) {
        let date = format(
          addDays(new Date(opportunity.setupDate), i),
          "EEE MMM do y"
        );

        let createdAt = getUnixTime(
          addDays(new Date(opportunity.setupDate), i)
        );

        let firstDay = false;
        let eventDay = false
        if (i === 0) {
          firstDay = true;
          if (opportunity.setupDate === opportunity.eventStartDate) {
            eventDay = true
          }
          else {
            eventDay = false
          }
        }


        if (i > 0) {
          if (opportunity.pickupDate === opportunity.eventEndDate) {
            eventDay = true
          } else {
            if (i === numberOfDays) {
              eventDay = false
            } else {
              eventDay = true
            }

          }
        }


        await transactionQuery.add({
          date: date,
          itemId: row.id,
          itemName: row.name,
          jobId: jobId,
          jobQty: values.qty,
          jobTitle: opportunity.title,
          organizationId: opportunity.organizationId,
          createdAt: createdAt,
          firstDay: firstDay,
          eventDay: eventDay,
          status: type,
        });
      }

      // dispatch(fetchOpportunity(jobId));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityItemPrice = (id, itemId, jobId, price) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityItemPriceQuery = firestore
      .collection("opportunities")
      .doc(`${jobId}`)
      .collection("items")
      .doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await opportunityItemPriceQuery.update({
        rentalPrice: price,
      });

      dispatch(fetchOpportunity(jobId));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityItemQty = (id, itemId, jobId, qty) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityItemQuery = firestore
      .collection("opportunities")
      .doc(`${jobId}`)
      .collection("items")
      .doc(`${id}`);
    const itemTransactionQuery = firestore
      .collection("itemTransactions")
      .where("itemId", "==", `${itemId}`)
      .where("jobId", "==", `${jobId}`);

    try {
      dispatch(asyncActionStart());

      await opportunityItemQuery.update({
        qty: qty,
      });

      let transactionQuery = await itemTransactionQuery.get();

      if (!transactionQuery.empty) {
        for (let i = 0; i < transactionQuery.docs.length; i++) {
          let docId = transactionQuery.docs[i].id;

          await firestore
            .collection("itemTransactions")
            .doc(`${docId}`)
            .update({ jobQty: qty });
        }
      }

      dispatch(fetchOpportunity(jobId));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteOpportunityItem = (id, itemId, jobId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore
      .collection("opportunities")
      .doc(`${jobId}`)
      .collection("items")
      .doc(`${id}`);
    const itemTransactionQuery = firestore
      .collection("itemTransactions")
      .where("itemId", "==", `${itemId}`)
      .where("jobId", "==", `${jobId}`);

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.delete();

      let transactionQuery = await itemTransactionQuery.get();

      if (!transactionQuery.empty) {
        for (let i = 0; i < transactionQuery.docs.length; i++) {
          let docId = transactionQuery.docs[i].id;

          await firestore
            .collection("itemTransactions")
            .doc(`${docId}`)
            .delete();
        }
      }

      dispatch(fetchOpportunity(jobId));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateItemTransactions = (opportunity, values, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const itemTransactionQuery = firestore
      .collection("itemTransactions")
      .where("jobId", "==", `${opportunity.id}`);
    const newItemTransactionQuery = firestore.collection("itemTransactions");

    try {
      dispatch(asyncActionStart());

      const numberOfDays = differenceInCalendarDays(
        new Date(values.pickupDate),
        new Date(values.setupDate)
      );

      let transactionQuery = await itemTransactionQuery.get();

      // DELETE OLD TRANSACTIONS
      if (!transactionQuery.empty) {
        for (let i = 0; i < transactionQuery.docs.length; i++) {
          let docId = transactionQuery.docs[i].id;

          await firestore
            .collection("itemTransactions")
            .doc(`${docId}`)
            .delete();
        }
      }

      for (let i = 0; i < opportunity.items.length; i++) {
        let itemId = opportunity.items[i].itemId;
        let itemName = opportunity.items[i].name;
        let itemQty = opportunity.items[i].qty;

        for (let i = 0; i <= numberOfDays; i++) {
          let date = format(
            addDays(new Date(values.setupDate), i),
            "EEE MMM do y"
          );

          let createdAt = getUnixTime(
            addDays(new Date(opportunity.setupDate), i)
          );

          let firstDay = false;
          let eventDay = false
          if (i === 0) {
            firstDay = true;
            if (values.setupDate === values.eventStartDate) {
              eventDay = true
            }
            else {
              eventDay = false
            }
          }


          if (i > 0) {
            if (values.pickupDate === values.eventEndDate) {
              eventDay = true
            } else {
              if (i === numberOfDays) {
                eventDay = false
              } else {
                eventDay = true
              }

            }
          }

          await newItemTransactionQuery.add({
            date: date,
            itemId: itemId,
            itemName: itemName,
            jobId: opportunity.id,
            jobQty: itemQty,
            jobTitle: opportunity.title,
            organizationId: opportunity.organizationId,
            createdAt: createdAt,
            firstDay: firstDay,
            eventDay: eventDay,
            status: type,
          });
        }
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunity = (id, values, opportunity, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);

    const opportunityStartDate = opportunity.setupDate;
    const opportunityEndDate = opportunity.pickupDate;
    const valuesStartDate = values.setupDate;
    const valuesEndDate = values.pickupDate;

    const numberOfDays =
      differenceInCalendarDays(
        new Date(values.eventEndDate),
        new Date(values.eventStartDate)
      ) + 1;

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.update({
        ...values,
        totalDays: numberOfDays,
        items: null,
        createdAt: format(new Date(values.eventStartDate), "MMMM y"),
      });

      if (
        opportunityStartDate !== valuesStartDate ||
        opportunityEndDate !== valuesEndDate
      ) {
        dispatch(updateItemTransactions(opportunity, values, type));
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteOpportunity = (id, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);
    const transactionQuery = firestore
      .collection("itemTransactions")
      .where("jobId", "==", `${id}`);
    const opportunityItemsQuery = firestore
      .collection("opportunities")
      .doc(`${id}`)
      .collection("items");

    try {
      dispatch(asyncActionStart());
      // DELETE TRANSACTIONS
      await transactionQuery.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });

      // DELETE ITEMS
      await opportunityItemsQuery.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });

      await opportunityQuery.delete();

      dispatch(fetchAllOpportunities(`${type}`));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityStatus = (id, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);
    const itemTransactionQuery = firestore
      .collection("itemTransactions")
      .where("jobId", "==", `${id}`);

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.update({
        opportunityType: `${type}`,
      });

      let transactionQuery = await itemTransactionQuery.get();

      if (!transactionQuery.empty) {
        for (let i = 0; i < transactionQuery.docs.length; i++) {
          let docId = transactionQuery.docs[i].id;

          await firestore
            .collection("itemTransactions")
            .doc(`${docId}`)
            .update({ status: `${type}` });
        }
      }

      dispatch(fetchOpportunity(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityDeliveredStatus = (id, type) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.update({
        delivered: true,
      });

      dispatch(fetchAllOpportunities(type));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityCollectedStatus = (id, type, archived) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);
    const itemTransactionQuery = firestore
        .collection("itemTransactions")
        .where("jobId", "==", `${id}`);

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.update({
        opportunityType: `${archived}`,
      });

      let transactionQuery = await itemTransactionQuery.get();

      if (!transactionQuery.empty) {
        for (let i = 0; i < transactionQuery.docs.length; i++) {
          let docId = transactionQuery.docs[i].id;

          await firestore
              .collection("itemTransactions")
              .doc(`${docId}`)
              .update({ status: `${type}` });
        }
      }

      dispatch(fetchAllOpportunities(type));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateOpportunityDelivery = (id, value) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const opportunityQuery = firestore.collection("opportunities").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await opportunityQuery.update({
        sameDeliveryAddress: !value,
      });

      dispatch(fetchOpportunity(id));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
