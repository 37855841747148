import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import GroupIcon from '@material-ui/icons/Group';
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import { useTheme } from "@material-ui/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import AppTemplates from "./templates/AppTemplates";
import AddAppTemplate from "./templates/AddAppTemplate";
import EditAppTemplate from "./templates/EditAppTemplate";
import AppAdmins from "./appAdmins/AppAdmins";
import AddAppAdmin from "./appAdmins/AddAppAdmin";


const sidebarItems = [
  {
    name: "Profile",
    icon: <AccountBoxIcon />,
    route: "/admin/settings/profile",
  },
  {
    name: "Templates",
    icon: <DescriptionIcon />,
    route: "/admin/settings/templates",
  },
  {
    name: "App Admins",
    icon: <GroupIcon />,
    route: "/admin/settings/appAdmins",
  },
];

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

const AppAdminSettings = ({ profile }) => {
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const isOrganizationAdmin = profile.organizationAdmin;
  const [active, setActive] = useState("Profile");

  const history = useHistory();
  return (
    <Grid container>
      {/*SIDEBAR*/}
      <Grid item md={1}>
        <Grid item container direction={"column"}>
          {sidebarItems.map((item) => (
            <Grid
              item
              key={item.name}
              style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
            >
              <Button
                variant={"contained"}
                style={
                  active === item.name
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }
                    : {
                        backgroundColor: theme.palette.captionGrey,
                        color: "white",
                      }
                }
                size={"small"}
                startIcon={item.icon}
                onClick={() => {
                  setActive(item.name);
                  history.push(`${item.route}`);
                }}
              >
                {item.name}
              </Button>
            </Grid>
          ))}

        </Grid>
      </Grid>

      {/*ROUTES*/}
      <Grid item style={{ paddingLeft: "3em" }} md={11}>
        <Grid item style={{ marginTop: "2em" }}>
          <Switch>
            {/*HOME*/}
            {/*PROFILE*/}
            <Route exact path={"/admin/settings/"}>
              {/*<Profile />*/}
              ADMIN PROFILE
            </Route>
            <Route exact path={"/admin/settings/profile"}>
              ADMIN PROFILE
            </Route>

            {/*TEMPLATES*/}
            <Route exact path={"/admin/settings/templates"}>
              <AppTemplates/>
            </Route>
            <Route exact path={"/admin/settings/templates/new"}>
              <AddAppTemplate/>
            </Route>
            <Route exact path={"/admin/settings/templates/edit"}>
              <EditAppTemplate/>
            </Route>

            {/*APP ADMINS*/}
            <Route exact path={"/admin/settings/appAdmins"}>
              <AppAdmins profile={profile}/>
            </Route>
            <Route exact path={"/admin/settings/appAdmins/new"}>
              <AddAppAdmin profile={profile}/>
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(AppAdminSettings);
