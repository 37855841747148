import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";
import TextInput from "../../common/form/TextInput";

import SelectInput from "../../common/form/SelectInput";
import TextArea from "../../common/form/TextArea";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import DropzoneInput from "../../ui/dropzone/DropzoneInput";
import { addNewProduct } from "../../store/user/actions/inventoryActions/inventoryActions";
import {
  fetchAllMainCategories,
  fetchAllSubCategories,
} from "../../store/user/actions/categoryActions/categoryActions";
import MenuItem from "@material-ui/core/MenuItem";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },

  dropzoneWrapper: {
    padding: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.4em",
      marginTop: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3em",
      marginTop: 0,
    },
  },
  dropzone: {
    marginTop: "0.5em",
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90px",
      height: "90px",
    },
  },

  image: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90px",
      height: "90px",
    },
  },
}));

const validate = combineValidators({
  name: isRequired({ message: "Product Name is required" }),
  mainCategory: isRequired({ message: "Main Category is required" }),
  // subCategory: isRequired({ message: "Sub Category is required" }),
  totalOwned: isRequired({ message: "Total Owned is required" }),
  rentalPrice: isRequired({ message: "Rental Price is required" }),
  replacementCost: isRequired({ message: "Replacement Cost is required" }),
});

const actions = {
  addNewProduct,
  fetchAllMainCategories,
  fetchAllSubCategories,
};

const mapStateToProps = (state) => {
  let mainCategories = [];
  let subCategories = [];

  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    mainCategories = state.categories.mainCategories;
  }

  if (
    state.categories.subCategories &&
    state.categories.subCategories.length > 0
  ) {
    subCategories = state.categories.subCategories;
  }

  return {
    mainCategories,
    subCategories,
  };
};

const AddProduct = ({
  addNewProduct,
  mainCategories,
  subCategories,
  fetchAllMainCategories,
  fetchAllSubCategories,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [image, setImage] = useState(null);

  const [mainCategoryId, setMainCatergoryId] = useState("");
  const [subCategoryId, setSubCatergoryId] = useState("");

  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [showSubCategory, setShowSubCategory] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchAllMainCategories();
    fetchAllSubCategories();
  }, [fetchAllMainCategories, fetchAllSubCategories]);

  const handleAddProduct = async (values) => {
    try {
      await addNewProduct(values, mainCategoryId, subCategoryId, image);
      history.push("/inventory");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMainCategory = (id) => {
    setMainCatergoryId(id);
    setFilteredSubCategories(
      subCategories.filter((category) => category.mainCategory === id)
    );
    setShowSubCategory(true);
  };

  const handleSelectSubCategory = (id) => {
    setSubCatergoryId(id);
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form autoComplete={"off"} onSubmit={handleSubmit(handleAddProduct)}>
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Add Product</Typography>
            </Grid>

            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/inventory")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={2}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={8}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"name"}
                  label={"Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item style={{ marginTop: "1.5em", marginBottom: "1.5em" }}>
                <Grid item container direction={"column"}>
                  <Grid item>
                    <Typography variant={"body2"}>Choose Image</Typography>
                  </Grid>
                  <Grid item>
                    <Grid item container alignItems={"flex-end"}>
                      <Grid item className={classes.dropzone}>
                        {!image && <DropzoneInput setFiles={setImage} />}
                        {image && (
                          <img
                            src={image[0].preview}
                            alt="preview 1"
                            className={classes.image}
                          />
                        )}
                      </Grid>
                      {image && (
                        <Grid item style={{ paddingLeft: "0.5em" }}>
                          <Button
                            variant={"contained"}
                            style={{
                              color: "white",
                              backgroundColor: theme.palette.error.main,
                            }}
                            onClick={() => setImage(null)}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*CATEGORY / COLOR*/}
              <Grid item container className={classes.fieldWrapper}>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingRight: "0.5em" }}
                >
                  <Field
                    name={"mainCategory"}
                    label={"Main Category"}
                    component={SelectInput}
                    type={"text"}
                    variant={"outlined"}
                  >
                    {mainCategories.map((category) => (
                      <MenuItem
                        key={category.id}
                        value={category.mainCategory}
                        onClick={() => handleSelectMainCategory(category.id)}
                      >
                        {category.mainCategory}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                {showSubCategory && (
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    style={{ paddingLeft: "0.5em" }}
                  >
                    <Field
                      name={"subCategory"}
                      label={"Sub Category"}
                      component={SelectInput}
                      type={"text"}
                      variant={"outlined"}
                    >
                      {filteredSubCategories.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category.subCategory}
                          onClick={() => handleSelectSubCategory(category.id)}
                        >
                          {category.subCategory}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                )}
              </Grid>

              {/*TOTAL OWNED*/}
              <Grid item container className={classes.fieldWrapper}>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingRight: "0.5em" }}
                >
                  <Field
                    name={"totalOwned"}
                    label={"Total Owned"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
              </Grid>

              {/*RENTAL / REPLACEMENT PRICE*/}
              <Grid item container className={classes.fieldWrapper}>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingRight: "0.5em" }}
                >
                  <Field
                    name={"rentalPrice"}
                    label={"Rental Price"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                    inputAdornment={"$"}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingLeft: "0.5em" }}
                >
                  <Field
                    name={"replacementCost"}
                    label={"Replacement Cost"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                    inputAdornment={"$"}
                  />
                </Grid>
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"description"}
                  label={"Description"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"notes"}
                  label={"Notes"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/inventory")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "addInventory", validate })(AddProduct));
