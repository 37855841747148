import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";
import TextInput from "../../../common/form/TextInput";
import MenuItem from "@material-ui/core/MenuItem";

import StateLookup from "../../../ui/forms/formLookups/StateLookup";
import SelectInput from "../../../common/form/SelectInput";
import TextArea from "../../../common/form/TextArea";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  addNewOrganization, sendOrganizationWelcomeEmail,

} from "../../../store/appAdmin/actions/appAdminActions/appAdminActions";
import { openSnackbar } from "../../../store/user/actions/snackbarActions/snackbarActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const actions = {
  addNewOrganization,
  sendOrganizationWelcomeEmail,
  openSnackbar,
};

const AppAdminAddOrganization = ({
  addNewOrganization,
                                   sendOrganizationWelcomeEmail,
  openSnackbar,
  handleSubmit,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const states = StateLookup.states;

  const [error, setError] = useState(null);

  const handleAddOrganization = async (values) => {
    let createOrganizationQuery = await addNewOrganization(values);

    if (createOrganizationQuery.data.message === "Organization Created") {
      openSnackbar("SuccessSnackbar", { message: "Organization Created" });


      const password = createOrganizationQuery.data.password
      let emailQuery = await sendOrganizationWelcomeEmail(values, password);
      if (emailQuery.data === "Message Sent Succesfully") {
        openSnackbar("SuccessSnackbar", { message: "Email Sent" });
      }
      history.push("/admin/organizations");
    }
    if (
      createOrganizationQuery.data.message ===
      "The email address is already in use by another account."
    ) {
      setError(createOrganizationQuery.data.message);
    }
    if (
      createOrganizationQuery.data ===
      "The email address is improperly formatted."
    ) {
      setError(createOrganizationQuery.data.message);
    }
  };


  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleAddOrganization)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Add Organization</Typography>
            </Grid>

            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/organizations")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={2}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={8}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationName"}
                  label={"Organization Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationAddress1"}
                  label={"Address 1"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationAddress2"}
                  label={"Address 2"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationCity"}
                  label={"City"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationState"}
                  label={"State"}
                  component={SelectInput}
                  type={"text"}
                  variant={"outlined"}
                >
                  {states.map((state) => (
                    <MenuItem key={state.key} value={state.text}>
                      {state.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationZipCode"}
                  label={"Zip Code"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationContactNumber"}
                  label={"Contact Number"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationEmail"}
                  label={"Email"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationWeb"}
                  label={"Web URL"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationNotes"}
                  label={"Notes"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>

              {error && (
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/organizations")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "addOrganization" })(AppAdminAddOrganization));
