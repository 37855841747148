import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";

import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TextInput from "../../../common/form/TextInput";
import SelectInput from "../../../common/form/SelectInput";
import {
  addOrganizationUser,
  fetchAllOrganizationUsers,
  sendOrganizationUserWelcomeEmail,
} from "../../../store/organizationAdmin/actions/organizationUserActions/organizationUserActions";
import { combineValidators, isRequired } from "revalidate";

import { openSnackbar } from "../../../store/user/actions/snackbarActions/snackbarActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  fullName: isRequired({ message: "Name is required" }),
  email: isRequired({ message: "Email is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
  organizationAdmin: isRequired({ message: "User type is required" }),
});

const actions = {
  openSnackbar,
  addOrganizationUser,
  fetchAllOrganizationUsers,
  sendOrganizationUserWelcomeEmail,
};

const mapStateToProps = (state) => {
  let organization = {};

  if (
    state.currentOrganization.organization &&
    state.currentOrganization.organization.length > 0
  ) {
    organization = state.currentOrganization.organization[0];
  }
  return {
    organization,
  };
};

const AddOrganizationUser = ({
  addOrganizationUser,
  fetchAllOrganizationUsers,
  sendOrganizationUserWelcomeEmail,
  organization,
  openSnackbar,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [error, setError] = useState(null);

  const handleAddOrganizationUser = async (values) => {
    let createUserQuery = await addOrganizationUser(values);

    if (createUserQuery.data.message === "User Created") {
      openSnackbar("SuccessSnackbar", { message: "User Created" });
      let emailQuery = await sendOrganizationUserWelcomeEmail(organization, values, createUserQuery.data.password);
      if (emailQuery.data === 'Message Sent Succesfully') {
        openSnackbar("SuccessSnackbar", { message: "Email Sent" });
      }
      fetchAllOrganizationUsers();
      history.push("/settings/users");
    }
    if (
      createUserQuery.data.message ===
      "The email address is already in use by another account."
    ) {
      setError(createUserQuery.data.message);
    }
    if (createUserQuery.data === "The email address is improperly formatted.") {
      setError(createUserQuery.data.message);
    }
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleAddOrganizationUser)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography
                variant={"h5"}
                style={matchesXS ? { fontSize: "1.5em" } : null}
              >
                Add Organization User
              </Typography>
            </Grid>

            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/users")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesSM && (
              <Grid item lg={2} md={2}>
                {/*BLANK*/}
              </Grid>
            )}

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"fullName"}
                  label={"Full Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"email"}
                  label={"Email"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"contactNumber"}
                  label={"Contact Number"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"organizationAdmin"}
                  label={"User Type"}
                  component={SelectInput}
                  type={"text"}
                  variant={"outlined"}
                >
                  <MenuItem value={"user"}>Organization User</MenuItem>
                  <MenuItem value={"admin"}>Organization Admin</MenuItem>
                </Field>
              </Grid>
              {error && (
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            alignItems={matchesSM ? "center" : null}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/users")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "addOrganizationUser", validate })(AddOrganizationUser));
