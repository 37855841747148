import React, {  useState } from "react";
import {Grid, Typography} from "@material-ui/core";
import { Route, Switch, useHistory } from "react-router-dom";
import Categories from "./categories/Categories";
import { connect } from "react-redux";
import OrganizationUsers from "./users/OrganizationUsers";
import AddOrganizationUser from "./users/AddOrganizationUser";
import ViewOrganizationUser from "./users/ViewOrganizationUser";
import EditOrganizationUser from "./users/EditOrganizationUser";
import Profile from "./profile/Profile";
import Button from "@material-ui/core/Button";

import ListIcon from "@material-ui/icons/List";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import GroupIcon from "@material-ui/icons/Group";
import DescriptionIcon from "@material-ui/icons/Description";
import { useTheme } from "@material-ui/styles";
import OrganizationTemplates from "./templates/OrganizationTemplates";
import AddOrganizationTemplate from "./templates/AddOrganizationTemplate";
import EditOrganizationTemplate from "./templates/EditOrganizationTemplate";


const sidebarItems = [
  {
    name: "Profile",
    icon: <AccountBoxIcon />,
    route: "/settings/profile",
    admin: false,
  },
  {
    name: "Categories",
    icon: <ListIcon />,
    route: "/settings/categories",
  },
];
const sidebarItemsAdmin = [
  {
    name: "Users",
    icon: <GroupIcon />,
    route: "/settings/users",
  },
    {
        name: "Templates",
        icon: <DescriptionIcon />,
        route: "/settings/templates",
    },
  // {
  //       name: "Payments",
  //       icon: <AttachMoneyIcon />,
  //       route: "/settings/payments",
  //   },
];

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

const Settings = ({ profile }) => {
  const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const isOrganizationAdmin = profile.organizationAdmin;
  const [active, setActive] = useState("Profile");

  const history = useHistory();
  return (
    <Grid container>
      {/*SIDEBAR*/}
      <Grid item md={1}>
        <Grid item container direction={"column"}>
          {sidebarItems.map((item) => (
            <Grid
              item
              key={item.name}
              style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
            >
              <Button
                variant={"contained"}
                style={
                  active === item.name
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                      }
                    : {
                        backgroundColor: theme.palette.captionGrey,
                        color: "white",
                      }
                }
                size={"small"}
                startIcon={item.icon}
                onClick={() => {
                  setActive(item.name);
                  history.push(`${item.route}`);
                }}
              >
                {item.name}
              </Button>
            </Grid>
          ))}
          {isOrganizationAdmin &&
            sidebarItemsAdmin.map((item) => (
              <Grid
                item
                key={item.name}
                style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
              >
                <Button
                  variant={"contained"}
                  style={
                    active === item.name
                      ? {
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }
                      : {
                          backgroundColor: theme.palette.captionGrey,
                          color: "white",
                        }
                  }
                  size={"small"}
                  startIcon={item.icon}
                  onClick={() => {
                    setActive(item.name);
                    history.push(`${item.route}`);
                  }}
                >
                  {item.name}
                </Button>
              </Grid>
            ))}
        </Grid>

        <Grid item style={{marginTop: '10em'}}>
          <Typography variant={'caption'}>Version - 1.3.0</Typography>
        </Grid>
      </Grid>

      {/*ROUTES*/}
      <Grid item style={{ paddingLeft: "3em" }} md={11}>
        <Grid item style={{ marginTop: "2em" }}>
          <Switch>
            {/*HOME*/}
            {/*PROFILE*/}
            <Route exact path={"/settings/"}>
              <Profile />
            </Route>
            <Route exact path={"/settings/profile"}>
              <Profile />
            </Route>

            {/*CATEGORIES*/}
            <Route exact path={"/settings/categories"}>
              <Categories />
            </Route>

            {/*ORGANIZATION ADMIN*/}
            {isOrganizationAdmin && (
              <Switch>
                <Route exact path={"/settings/users"}>
                  <OrganizationUsers profile={profile} />
                </Route>
                <Route exact path={"/settings/users/new"}>
                  <AddOrganizationUser />
                </Route>
                <Route exact path={"/settings/users/view"}>
                  <ViewOrganizationUser />
                </Route>
                <Route exact path={"/settings/users/edit"}>
                  <EditOrganizationUser />
                </Route>

                {/*TEMPLATES*/}
                <Route exact path={"/settings/templates"}>
                  <OrganizationTemplates />
                </Route>
                <Route exact path={"/settings/templates/new"}>
                  <AddOrganizationTemplate />
                </Route>
                <Route exact path={"/settings/templates/edit"}>
                  <EditOrganizationTemplate />
                </Route>

                {/*PAYMENTS*/}
                {/*<Route exact path={"/settings/payments"}>*/}
                {/*  <OrganizationPayments />*/}
                {/*</Route>*/}
              </Switch>
            )}
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(Settings);
