import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import SelectInput from "../../common/form/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextInput from "../../common/form/TextInput";
import TextArea from "../../common/form/TextArea";
import DatePickerInput from "../../common/form/DatePickerInput";
import Button from "@material-ui/core/Button";
import CheckboxInput from "../../common/form/CheckboxInput";
import AddOpportunityDeliveryAddress from "./AddOpportunityDeliveryAddress";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
}));

const EditOpportunityDetails = ({
  contacts,
  handleContactChange,
  contact,
  showDeliveryAddress,
  handleDeliveryAddress,
}) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Grid item container style={{ marginTop: "1em" }}>
      <Grid item lg={2} md={2} sm={1} xs={1}>
        {/*BLANK*/}
      </Grid>

      <Grid item lg={8} md={8} sm={9} xs={9}>
        <Grid item>
          <Typography variant={"subtitle1"}>
            <span style={{ fontWeight: "bold" }}>Client Details:</span>
          </Typography>
        </Grid>

        {/*CLIENT DETAILS*/}
        <Grid
          item
          className={classes.fieldWrapper}
          style={{ marginTop: "0.5em" }}
        >
          <Field
            name={"clientId"}
            label={"Client Name"}
            component={SelectInput}
            type={"text"}
            variant={"outlined"}
          >
            {contacts.map((contact) => (
              <MenuItem
                key={contact.id}
                value={contact.id}
                onClick={() => handleContactChange(contact.id)}
              >
                {contact.firstName + " " + contact.lastName}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        {/*ADDRESS*/}
        <Grid item className={classes.fieldWrapper}>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant={"subtitle1"}>
                <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                {contact?.address1},{" "}
                {contact?.address2 ? `${contact?.address2},` : ""}
                {contact?.city}, {contact?.state}, {contact?.zipCode}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"subtitle1"}>
                <span style={{ fontWeight: "bold" }}>Contact Number:</span>{" "}
                {contact?.contactNumber}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"subtitle1"}>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                {contact?.email}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"subtitle1"}>
                <span style={{ fontWeight: "bold" }}>Client Notes:</span>{" "}
                {contact?.notes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Button
            size={"small"}
            variant={"outlined"}
            color={"primary"}
            onClick={() => history.push(`/contacts/edit?id=${contact.id}`)}
          >
            Edit Client
          </Button>
        </Grid>

        {/*DELIVERY DETAILS*/}
        <Grid
          item
          className={classes.fieldWrapper}
          style={{ marginTop: "1.5em" }}
        >
          <Typography variant={"subtitle1"}>
            <span style={{ fontWeight: "bold" }}>Delivery Details:</span>
          </Typography>
        </Grid>
        <Grid item>
          <Field
            name={"sameDeliveryAddress"}
            label={"Same as above"}
            component={CheckboxInput}
            variant={"outlined"}
            onChange={() => handleDeliveryAddress()}
          />
        </Grid>

        {showDeliveryAddress && <AddOpportunityDeliveryAddress />}

        <Grid
          item
          className={classes.fieldWrapper}
          style={{ marginTop: "1.5em" }}
        >
          <Typography variant={"subtitle1"}>
            <span style={{ fontWeight: "bold" }}>Inquiry Details:</span>
          </Typography>
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"title"}
            label={"Inquiry Name"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"description"}
            label={"Description"}
            component={TextArea}
            type={"text"}
            variant={"outlined"}
            rows={6}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Grid item container>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              style={{ paddingRight: "0.5em" }}
            >
              <Field
                name={"eventStartDate"}
                label={"Event Start Date"}
                component={DatePickerInput}
                type={"text"}
                inputVariant={"outlined"}
                size={"small"}
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              style={{ paddingLeft: "0.5em" }}
            >
              <Field
                name={"eventEndDate"}
                label={"Event End Date"}
                component={DatePickerInput}
                type={"text"}
                inputVariant={"outlined"}
                size={"small"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Grid item container>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              style={{ paddingRight: "0.5em" }}
            >
              <Field
                name={"setupDate"}
                label={"Setup Date"}
                component={DatePickerInput}
                type={"text"}
                inputVariant={"outlined"}
                size={"small"}
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              style={{ paddingLeft: "0.5em" }}
            >
              <Field
                name={"pickupDate"}
                label={"Pickup Date"}
                component={DatePickerInput}
                type={"text"}
                inputVariant={"outlined"}
                size={"small"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditOpportunityDetails;
