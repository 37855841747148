import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_ORGANIZATIONS,
  FETCH_ORGANIZATION,
} from "../../constants/appAdminConstants/appAdminConstants";

const initialState = {
  allOrganizations: [],
  organization: [],
};

const getAllOrganizations = (state, payload) => {
  return {
    ...state,
    allOrganizations: payload.allOrganizations,
  };
};

const getOrganization = (state, payload) => {
  return {
    ...state,
    organization: payload.organization,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_ORGANIZATIONS]: getAllOrganizations,
  [FETCH_ORGANIZATION]: getOrganization,
});
