import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import { FETCH_INVENTORY_REPORTS } from "../../constants/reportsConstants/reportsConstants";
import { getUnixTime, startOfDay, endOfDay } from "date-fns";

export const fetchInventoryReports = (values) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const state = getState();

    const organizationId = state.firebase.profile.organizationId;

    const startDay = startOfDay(new Date(values.startDate));
    const endDay = endOfDay(new Date(values.endDate));

    const startUnixTime = getUnixTime(startDay);
    const endUnixTime = getUnixTime(endDay);

    const inventoryQuery = firestore
      .collection("inventory")
      .where("organizationId", "==", `${organizationId}`);

    const transactionsQuery = firestore
      .collection("itemTransactions")
      .where("status", "==", "order")
      .where("firstDay", "==", true)
      .where("createdAt", ">=", startUnixTime)
      .where("createdAt", "<=", endUnixTime);

    try {
      dispatch(asyncActionStart());
      let query = await inventoryQuery.get();

      let reports = [];

      for (let i = 0; i < query.docs.length; i++) {
        const itemId = query.docs[i].id;

        let itemTransaction = await transactionsQuery
          .where("itemId", "==", `${itemId}`)
          .get();

        let transactionTotal = 0;

        // GET TRANSACTION TOTALS
        for (let i = 0; i < itemTransaction.docs.length; i++) {
          let jobTotal = Number(itemTransaction.docs[i].data().jobQty);

          transactionTotal = transactionTotal + jobTotal;
        }

        let item = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
          transactionTotal: transactionTotal,
        };
        reports.push(item);
      }

      let inventoryReports = reports.sort(function (a, b) {
        return b.transactionTotal - a.transactionTotal;
      });

      dispatch({
        type: FETCH_INVENTORY_REPORTS,
        payload: { inventoryReports },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
