import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {CircularProgress, Grid} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import CurrencyFormat from "react-currency-format";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("xs")]: {
      fontWeight: "bold",
      fontSize: "0.8em",
    },
  },
  value: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3em",
    },
  },
}));

const MonthlyPipelineItem = ({ inquiries, value, dollar, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid item md={4} sm={4} xs={4}>
      <Grid item container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h6"} className={classes.title}>
            {inquiries}
          </Typography>
        </Grid>
        <Grid item style={matchesXS ? { marginTop: "0.5em" } : null}>
          {loading && (
              <CircularProgress/>
          )}
          {!loading && (
              <Typography variant={"h2"} className={classes.value}>
                {dollar ? (
                    <CurrencyFormat
                        value={value}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                    />
                ) : (
                    value
                )}
              </Typography>
          )}

        </Grid>
      </Grid>
    </Grid>
  );
};

export default MonthlyPipelineItem;
