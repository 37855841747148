import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  SUB_DIALOG_OPEN,
  SUB_DIALOG_CLOSE,
} from "../../constants/dialogConstants/dialogConstants";

export const openDialog = (dialogType, dialogProps) => {
  return {
    type: DIALOG_OPEN,
    payload: {
      dialogType,
      dialogProps,
    },
  };
};

export const closeDialog = () => {
  return {
    type: DIALOG_CLOSE,
  };
};

export const openSubDialog = (dialogType, dialogProps) => {
  return {
    type: SUB_DIALOG_OPEN,
    payload: {
      dialogType,
      dialogProps,
    },
  };
};

export const closeSubDialog = () => {
  return {
    type: SUB_DIALOG_CLOSE,
  };
};
