import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";
import TextInput from "../../common/form/TextInput";
import MenuItem from "@material-ui/core/MenuItem";

import StateLookup from "../../ui/forms/formLookups/StateLookup";
import SelectInput from "../../common/form/SelectInput";
import TextArea from "../../common/form/TextArea";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchContact,
  updateContact,
} from "../../store/user/actions/contactsActions/contactsActions";
import { combineValidators, isRequired } from "revalidate";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1.5em",
  },
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  address1: isRequired({ message: "Address is required" }),
  city: isRequired({ message: "City is required" }),
  state: isRequired({ message: "State is required" }),
  zipCode: isRequired({ message: "Zip Code is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
  email: isRequired({ message: "Email is required" }),
});

const actions = {
  fetchContact,
  updateContact,
};

const mapStateToProps = (state) => {
  return {
    initialValues: state.contacts.contact[0],
  };
};

const EditContact = ({
  fetchContact,
  updateContact,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const states = StateLookup.states;

  useEffect(() => {
    fetchContact(id);
  }, [id, fetchContact]);

  const handleUpdateContact = async (values) => {
    try {
      await updateContact(id, values);
      history.push("/contacts");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid
        item
        container
        direction={"column"}
        style={matchesXS ? { width: "90%" } : { width: "70%" }}
      >
        {/*FORM*/}
        <form autoComplete={"off"} onSubmit={handleSubmit(handleUpdateContact)}>
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Edit Contact</Typography>
            </Grid>

            <Grid item style={{ marginLeft: "auto", marginTop: "1.5em" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/contacts")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesMD && (
              <Grid item lg={2}>
                {/*BLANK*/}
              </Grid>
            )}

            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Grid item>
                <Grid item container direction={matchesSM ? "column" : "row"}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={matchesSM ? null : { paddingRight: "0.5em" }}
                  >
                    <Grid item className={classes.fieldWrapper}>
                      <Field
                        name={"firstName"}
                        label={"First Name"}
                        component={TextInput}
                        type={"text"}
                        variant={"outlined"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={matchesSM ? null : { paddingLeft: "0.5em" }}
                  >
                    <Grid item className={classes.fieldWrapper}>
                      <Field
                        name={"lastName"}
                        label={"Last Name"}
                        component={TextInput}
                        type={"text"}
                        variant={"outlined"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"address1"}
                  label={"Address 1"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"address2"}
                  label={"Address 2"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"city"}
                  label={"City"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"state"}
                  label={"State"}
                  component={SelectInput}
                  type={"text"}
                  variant={"outlined"}
                >
                  {states.map((state) => (
                    <MenuItem key={state.key} value={state.text}>
                      {state.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"zipCode"}
                  label={"Zip Code"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"contactNumber"}
                  label={"Contact Number"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"email"}
                  label={"Email"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"notes"}
                  label={"Notes"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/contacts")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editContact",
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(EditContact)
);
