import { createReducer } from "../../../../common/util/reducerUtil";
import { FETCH_ITEM_TRANSACTIONS } from "../../constants/transactionConstants/transactionConstants";

const initialState = {
  itemTransactions: [],
};

const getItemTransactions = (state, payload) => {
  return {
    ...state,
    itemTransactions: payload.itemTransactions,
  };
};

export default createReducer(initialState, {
  [FETCH_ITEM_TRANSACTIONS]: getItemTransactions,
});
