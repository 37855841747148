import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {

  closeSubDialog,

} from "../../store/user/actions/dialogActions/dialogActions";
import { connect } from "react-redux";
import {Grid} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
    image: {
      width: '280px',
      height: '280px',
    }
}));

const actions = {
  closeSubDialog,
};

const ItemImageDialog = ({
  closeSubDialog,
  row,
  type,
  handleSubmit,
}) => {
  const classes = useStyles();


  const handleClose = () => {
    closeSubDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{row?.name} Photo</DialogTitle>
      <DialogContent>
          <Grid item container direction={'column'} alignItems={'center'}>
              <Grid item>
                  <img className={classes.image} src={row?.photoURL} alt="product"/>
              </Grid>
          </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeSubDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, actions)(ItemImageDialog);
