import { createReducer } from "../../../../common/util/reducerUtil";
import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  SUB_DIALOG_OPEN,
  SUB_DIALOG_CLOSE,
} from "../../constants/dialogConstants/dialogConstants";

const initialState = {
  mainDialog: null,
  subDialog: null,
};

const openDialog = (state, payload) => {
  return {
    ...state,
    mainDialog: {
      dialogType: payload.dialogType,
      dialogProps: payload.dialogProps,
    },
  };
};

const closeDialog = (state) => {
  return {
    ...state,
    mainDialog: null,
  };
};

// SUB DIALOG
const openSubDialog = (state, payload) => {
  return {
    ...state,
    subDialog: {
      dialogType: payload.dialogType,
      dialogProps: payload.dialogProps,
    },
  };
};

const closeSubDialog = (state) => {
  return {
    ...state,
    subDialog: null,
  };
};

export default createReducer(initialState, {
  [DIALOG_OPEN]: openDialog,
  [DIALOG_CLOSE]: closeDialog,
  [SUB_DIALOG_OPEN]: openSubDialog,
  [SUB_DIALOG_CLOSE]: closeSubDialog,
});
