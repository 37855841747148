import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import { format, parseISO } from "date-fns";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from '@material-ui/icons/Print';

import {
  deleteOpportunity,
  fetchOpportunity,
  updateOpportunityStatus,
} from "../../store/user/actions/opportunityActions/opportunityActions";
import {
  emailQuote,
  saveQuote,
} from "../../store/user/actions/mailerActions/mailerActions";
import { openSnackbar } from "../../store/user/actions/snackbarActions/snackbarActions";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1.5em",
  },
  button: {
    margin: "0.4em",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
    },
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  tableCell: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const actions = {
  fetchOpportunity,
  deleteOpportunity,
  updateOpportunityStatus,
  emailQuote,
  saveQuote,
  openSnackbar,
};

const mapStateToProps = (state) => {
  let opportunity = {};
  let contact = {};

  if (
    state.opportunities.opportunity &&
    state.opportunities.opportunity.length > 0
  ) {
    opportunity = state.opportunities.opportunity[0];
  }

  if (state.contacts.contact && state.contacts.contact.length > 0) {
    contact = state.contacts.contact[0];
  }

  return {
    opportunity,
    contact,
    loading: state.loading.loading,
  };
};

const ViewQuote = ({
  fetchOpportunity,
  deleteOpportunity,
  opportunity,
  contact,
  updateOpportunityStatus,
  emailQuote,
  saveQuote,
  openSnackbar,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [dialogOpen, setDialogOpen] = useState(false);

  const [sendingEmail, setSendingEmail] = useState(false);
  const [savingQuote, setSavingQuote] = useState(false);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchOpportunity(id);
  }, [id, fetchOpportunity]);

  const handleDeleteOpportunity = async () => {
    await deleteOpportunity(id, "quote");
    history.push("/quotes");
    setDialogOpen(false);
  };

  const handleOpportunityStatus = async (status) => {
    await updateOpportunityStatus(id, status);
    if (status === "inquiry") {
      history.push(`/inquiries/view?id=${id}`);
    }
    if (status === "quote") {
      history.push(`/quotes/view?id=${id}`);
    }
    if (status === "order") {
      history.push(`/orders/view?id=${id}`);
    }
    if (status === "archived") {
      history.push(`/quotes`);
    }
  };

  const handleEmailQuote = async () => {
    setSendingEmail(true);
    const email = await emailQuote(contact.id, opportunity.id);

    if (email.data === "Message Sent Succesfully") {
      openSnackbar("SuccessSnackbar", {
        message: "Email sent successfully!",
      });
      setSendingEmail(false);
    }
  };

  const handleSaveQuote = async () => {
    setSavingQuote(true);
    const quote = await saveQuote(contact.id, opportunity.id);

    if (quote.data) {
      const downloadUrl = window.URL.createObjectURL(new Blob([quote.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${opportunity.title}_QUOTE.pdf`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSavingQuote(false);
    }
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete quote?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteOpportunity()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/*DETAILS*/}
      <Grid
        item
        container
        direction={"column"}
        style={matchesXS ? { width: "90%" } : { width: "70%" }}
      >
        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item>
            <Typography variant={"h4"}>View Quote</Typography>
          </Grid>

          <Grid
            item
            style={
              matchesMD
                ? { marginTop: "1.5em" }
                : { marginLeft: "auto", marginTop: "1.5em" }
            }
          >
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/quotes/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/quotes")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction={"column"}
          alignItems={"center"}
          style={{ marginTop: "2em" }}
        >
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                Client Details
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                {contact.firstName + " " + contact.lastName}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                {contact.address1},{" "}
                {contact.address2 ? `${contact.address2},` : ""}
                {contact.city}, {contact.state}, {contact.zipCode}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                {contact.contactNumber}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                {contact.email}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Current Status:</span>{" "}
                {opportunity?.opportunityType === "inquiry" && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    Inquiry
                  </span>
                )}
                {opportunity?.opportunityType === "quote" && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    Quote
                  </span>
                )}
                {opportunity?.opportunityType === "order" && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    Order
                  </span>
                )}
                {opportunity?.opportunityType === "archived" && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    Archived
                  </span>
                )}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Total Cost:</span> $
                {(opportunity?.totalCost * opportunity?.totalDays).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Grid item container>
                <Grid item>
                  <Button
                    onClick={() => handleOpportunityStatus("order")}
                    size={"small"}
                    style={{
                      color: "white",
                      backgroundColor: theme.palette.primary.main,
                    }}
                    variant={"contained"}
                  >
                    Convert to Order
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: "0.5em" }}>
                  <Button
                    onClick={() => handleOpportunityStatus("archived")}
                    size={"small"}
                    style={{
                      color: "white",
                      backgroundColor: theme.palette.themeBlue,
                    }}
                    variant={"contained"}
                  >
                    Archive Quote
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/*EMAIL/SAVE QUOTE*/}
            <Grid item style={{ marginTop: "1em" }}>
              <Grid item container>
                <Grid item className={classes.buttonWrapper}>
                  <Button
                    disabled={sendingEmail}
                    onClick={() => handleEmailQuote()}
                    color={"primary"}
                    size={"small"}
                    style={{
                      color: "white",
                    }}
                    variant={"contained"}
                    startIcon={<EmailIcon />}
                  >
                    Email Client Quote
                  </Button>
                  {sendingEmail && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  style={{ marginLeft: "0.5em" }}
                  className={classes.buttonWrapper}
                >
                  <Button
                    disabled={savingQuote}
                    onClick={() => handleSaveQuote()}
                    size={"small"}
                    color={"primary"}
                    style={{
                      color: "white",
                    }}
                    variant={"contained"}
                    startIcon={<PrintIcon />}
                  >
                    Print Quote
                  </Button>
                  {savingQuote && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/*DELIVERY DETAILS*/}
            <Grid item style={{ marginTop: "2em" }}>
              <Typography variant={"h5"} className={classes.title}>
                Delivery Details
              </Typography>
            </Grid>
            {opportunity.sameDeliveryAddress && (
              <Grid item style={{ marginTop: "1em" }}>
                <Typography variant={"body1"} className={classes.text}>
                  <span style={{ fontWeight: "bold" }}>
                    Same as client address
                  </span>{" "}
                </Typography>
              </Grid>
            )}

            {!opportunity.sameDeliveryAddress && (
              <Fragment>
                <Grid item style={{ marginTop: "1em" }}>
                  <Typography variant={"body1"} className={classes.text}>
                    <span style={{ fontWeight: "bold" }}>Contact Name:</span>{" "}
                    {opportunity?.deliveryContactName}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                  <Typography variant={"body1"} className={classes.text}>
                    <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                    {opportunity?.deliveryAddress1},{" "}
                    {opportunity?.deliveryAddress2
                      ? `${opportunity?.deliveryAddress2},`
                      : ""}
                    {opportunity?.deliveryCity}, {opportunity?.deliveryState},{" "}
                    {opportunity?.deliveryZipCode}
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "1em" }}>
                  <Typography variant={"body1"} className={classes.text}>
                    <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                    {opportunity?.deliveryContactNumber}
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "1em" }}>
                  <Typography variant={"body1"} className={classes.text}>
                    <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                    {opportunity?.deliveryEmail}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "1em" }}>
                  <Typography variant={"body1"} className={classes.text}>
                    <span style={{ fontWeight: "bold" }}>Notes:</span>{" "}
                    {opportunity?.deliveryNotes}
                  </Typography>
                </Grid>
              </Fragment>
            )}

            {/*/!*JOB DETAILS*!/*/}
            <Grid item style={{ marginTop: "2em" }}>
              <Typography variant={"h5"} className={classes.title}>
                Dates
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Setup Date:</span>{" "}
                {opportunity?.setupDate
                  ? format(parseISO(opportunity?.setupDate), "EEE MMM do y")
                  : ""}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Event Start Date:</span>{" "}
                {opportunity?.eventStartDate
                  ? format(
                      parseISO(opportunity?.eventStartDate),
                      "EEE MMM do y"
                    )
                  : ""}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Event End Date:</span>{" "}
                {opportunity?.eventEndDate
                  ? format(parseISO(opportunity?.eventEndDate), "EEE MMM do y")
                  : ""}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Pickup Date:</span>{" "}
                {opportunity?.pickupDate
                  ? format(parseISO(opportunity?.pickupDate), "EEE MMM do y")
                  : ""}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Number of Days:</span>{" "}
                {opportunity?.totalDays}
              </Typography>
            </Grid>

            {/*/!*TITLE*!/*/}
            <Grid item style={{ marginTop: "2em" }}>
              <Typography variant={"h5"} className={classes.title}>
                Title
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                {opportunity?.title}
              </Typography>
            </Grid>
            {/*/!*DESCRIPTION*!/*/}
            <Grid item style={{ marginTop: "2em" }}>
              <Typography variant={"h5"} className={classes.title}>
                Description
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                {opportunity?.description}
              </Typography>
            </Grid>

            {/*TABLE*/}
            {opportunity.items?.length > 0 && (
              <Fragment>
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography variant={"h5"} className={classes.title}>
                    Items
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "2em" }}>
                  <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size={"small"}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              Item
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              Qty
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Rental Price/day
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Line Total/day
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {opportunity.items?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className={classes.tableCell}>
                                {row?.name}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {row?.qty}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                ${Number(row?.rentalPrice).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                $
                                {(
                                  Number(row?.rentalPrice) * Number(row?.qty)
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Grid>

        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item style={{ marginTop: "2em", marginLeft: "auto" }}>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/quotes/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/quotes")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewQuote);
