import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TextInput from "../../../../common/form/TextInput";

import { combineValidators, isRequired } from "revalidate";

import { openSnackbar } from "../../../../store/user/actions/snackbarActions/snackbarActions";
import {
  addAppAdmin, fetchAllAppAdmins,
  sendAdminWelcomeEmail
} from "../../../../store/appAdmin/actions/appAdminAdminsActions/appAdminAdminsActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  email: isRequired({ message: "Email is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
});

const actions = {
  openSnackbar,
  addAppAdmin,
  sendAdminWelcomeEmail,
  fetchAllAppAdmins

};


const AddAppAdmin = ({
                       addAppAdmin,
                       sendAdminWelcomeEmail,
    fetchAllAppAdmins,
  organization,
  openSnackbar,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [error, setError] = useState(null);

  const handleAddOrganizationUser = async (values) => {
    let createAdminQuery = await addAppAdmin(values);


    if (createAdminQuery.data.message === "User Created") {
      openSnackbar("SuccessSnackbar", { message: "Admin Created" });
      let emailQuery = await sendAdminWelcomeEmail(
        values,
        createAdminQuery.data.password
      );
      if (emailQuery.data === "Message Sent Succesfully") {
        openSnackbar("SuccessSnackbar", { message: "Email Sent" });
      }
      fetchAllAppAdmins();
      history.push("/admin/settings/appAdmins");
    }
    if (
      createAdminQuery.data.message ===
      "The email address is already in use by another account."
    ) {
      setError(createAdminQuery.data.message);
    }
    if (createAdminQuery.data === "The email address is improperly formatted.") {
      setError(createAdminQuery.data.message);
    }
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleAddOrganizationUser)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography
                variant={"h5"}
                style={matchesXS ? { fontSize: "1.5em" } : null}
              >
                Add App Admin
              </Typography>
            </Grid>

            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/settings/appAdmins")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesSM && (
              <Grid item lg={2} md={2}>
                {/*BLANK*/}
              </Grid>
            )}

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"firstName"}
                  label={"First Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                    name={"lastName"}
                    label={"Last Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"email"}
                  label={"Email"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"contactNumber"}
                  label={"Contact Number"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>


              {error && (
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            alignItems={matchesSM ? "center" : null}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/admin/settings/appAdmins")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "addAppAdmin", validate })(AddAppAdmin));
