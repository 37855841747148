import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import LiveSearch from "../../../ui/search/LiveSearch";
import { fetchAllOrganizationUsers } from "../../../store/organizationAdmin/actions/organizationUserActions/organizationUserActions";
import UsersTable from "../../../ui/settings/users/UsersTable";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllOrganizationUsers,
};

const mapStateToProps = (state) => {
  let organizationUsers = [];

  if (
    state.organizationUsers.allOrganizationUsers &&
    state.organizationUsers.allOrganizationUsers.length > 0
  ) {
    organizationUsers = state.organizationUsers.allOrganizationUsers;
  }
  return {
    organizationUsers,
  };
};

const OrganizationUsers = ({
  fetchAllOrganizationUsers,
  organizationUsers,
  profile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllOrganizationUsers();
  }, [fetchAllOrganizationUsers]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Organization Users</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LiveSearch
            data={organizationUsers}
            setSearchResults={setSearchResults}
          />
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={matchesSM ? { marginTop: "0.5em" } : null}
        >
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/settings/users/new")}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <UsersTable
          profile={profile}
          rows={
            searchResults < 1
              ? organizationUsers
              : organizationUsers.filter((contact) => contact.search)
          }
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(OrganizationUsers);
