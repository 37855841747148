import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import TextInput from "../../common/form/TextInput";
import SelectInput from "../../common/form/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import TextArea from "../../common/form/TextArea";
import StateLookup from "../forms/formLookups/StateLookup";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
}));

const AddOpportunityNewClient = () => {
  const classes = useStyles();

  const states = StateLookup.states;
  return (
    <Grid item container>
      <Grid item lg={12}>
        <Grid item>
          <Grid item container>
            <Grid item md={6} style={{ paddingRight: "0.5em" }}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"firstName"}
                  label={"First Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
            </Grid>
            <Grid item md={6} style={{ paddingLeft: "0.5em" }}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"lastName"}
                  label={"Last Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"address1"}
            label={"Address 1"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"address2"}
            label={"Address 2"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"city"}
            label={"City"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"state"}
            label={"State"}
            component={SelectInput}
            type={"text"}
            variant={"outlined"}
          >
            {states.map((state) => (
              <MenuItem key={state.key} value={state.text}>
                {state.text}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"zipCode"}
            label={"Zip Code"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"contactNumber"}
            label={"Contact Number"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"email"}
            label={"Email"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"notes"}
            label={"Notes"}
            component={TextArea}
            type={"text"}
            variant={"outlined"}
            rows={6}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddOpportunityNewClient;
