import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";
import TextInput from "../../common/form/TextInput";

import SelectInput from "../../common/form/SelectInput";
import TextArea from "../../common/form/TextArea";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import DropzoneInput from "../../ui/dropzone/DropzoneInput";
import {
  deleteProductPhoto,
  fetchInventoryProduct,
  updateProduct,
} from "../../store/user/actions/inventoryActions/inventoryActions";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchAllMainCategories,
  fetchAllSubCategories,
} from "../../store/user/actions/categoryActions/categoryActions";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },

  dropzoneWrapper: {
    padding: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.4em",
      marginTop: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3em",
      marginTop: 0,
    },
  },
  dropzone: {
    marginTop: "0.5em",
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      height: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
    },
  },

  image: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      height: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
    },
  },
}));

const validate = combineValidators({
  name: isRequired({ message: "Product Name is required" }),
  mainCategory: isRequired({ message: "Main Category is required" }),
  totalOwned: isRequired({ message: "Total Owned is required" }),
  rentalPrice: isRequired({ message: "Rental Price is required" }),
  replacementCost: isRequired({ message: "Replacement Cost is required" }),
});

const actions = {
  fetchInventoryProduct,
  fetchAllMainCategories,
  fetchAllSubCategories,
  updateProduct,
  deleteProductPhoto,
};

const mapStateToProps = (state) => {
  let product = {};
  let mainCategories = [];
  let subCategories = [];

  if (state.inventory.product && state.inventory.product.length > 0) {
    product = state.inventory.product[0];
  }

  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    mainCategories = state.categories.mainCategories;
  }

  if (
    state.categories.subCategories &&
    state.categories.subCategories.length > 0
  ) {
    subCategories = state.categories.subCategories;
  }
  return {
    initialValues: state.inventory.product[0],
    product,
    mainCategories,
    subCategories,
  };
};

const EditProduct = ({
  fetchInventoryProduct,
  fetchAllMainCategories,
  fetchAllSubCategories,
  updateProduct,
  deleteProductPhoto,
  product,
  mainCategories,
  subCategories,
  handleSubmit,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [mainCategoryId, setMainCatergoryId] = useState("");
  const [subCategoryId, setSubCatergoryId] = useState("");

  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(true);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchInventoryProduct(id);
    fetchAllMainCategories();
    fetchAllSubCategories();

    if (!product.photoName) {
      setCurrentImage(false);
    }
  }, [
    id,
    fetchInventoryProduct,
    fetchAllMainCategories,
    fetchAllSubCategories,
    product,
  ]);

  const handleUpdateProduct = async (values) => {
    try {
      await updateProduct(
        id,
        values,
        mainCategoryId ? mainCategoryId : product.mainCategoryId,
        subCategoryId ? subCategoryId : product.subCategoryId,
        image
      );
      history.push("/inventory");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePhoto = async () => {
    try {
      await deleteProductPhoto(id, product.photoName);
      setCurrentImage(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMainCategory = (id) => {
    setMainCatergoryId(id);
    setFilteredSubCategories(
      subCategories.filter((category) => category.mainCategory === id)
    );
  };

  const handleSelectSubCategory = (id) => {
    setSubCatergoryId(id);
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid
        item
        container
        direction={"column"}
        style={matchesMD ? { width: "90%" } : { width: "70%" }}
      >
        {/*FORM*/}
        <form autoComplete={"off"} onSubmit={handleSubmit(handleUpdateProduct)}>
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Edit {product?.name}</Typography>
            </Grid>

            <Grid
              item
              style={
                matchesMD
                  ? { marginTop: "1.5em" }
                  : {
                      marginLeft: "auto",
                      marginTop: "1.5em",
                      paddingRight: "2em",
                    }
              }
            >
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/inventory")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={2} md={2} sm={1} xs={1}>
              {/*BLANK*/}
            </Grid>
            <Grid item lg={8} md={8} sm={9} xs={9}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"name"}
                  label={"Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item style={{ marginTop: "1.5em", marginBottom: "1.5em" }}>
                <Grid item container direction={"column"}>
                  {!currentImage && (
                    <Fragment>
                      <Grid item>
                        <Typography variant={"body2"}>Choose Image</Typography>
                      </Grid>
                      <Grid item>
                        <Grid item container alignItems={"flex-end"}>
                          <Grid item className={classes.dropzone}>
                            {!image && <DropzoneInput setFiles={setImage} />}
                            {image && (
                              <img
                                src={image[0].preview}
                                alt="preview 1"
                                className={classes.image}
                              />
                            )}
                          </Grid>
                          {image && (
                            <Grid item style={{ paddingLeft: "0.5em" }}>
                              <Button
                                variant={"contained"}
                                style={{
                                  color: "white",
                                  backgroundColor: theme.palette.error.main,
                                }}
                                onClick={() => setImage(null)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}

                  {currentImage && (
                    <Fragment>
                      <Grid item>
                        <Typography variant={"body2"}>Current Image</Typography>
                      </Grid>
                      <Grid item>
                        <Grid item container alignItems={"flex-end"}>
                          <Grid item className={classes.dropzone}>
                            <img
                              src={product.photoURL}
                              alt={`${product?.name}`}
                              className={classes.image}
                            />
                          </Grid>
                          <Grid item style={{ paddingLeft: "0.5em" }}>
                            <Button
                              variant={"contained"}
                              style={{
                                color: "white",
                                backgroundColor: theme.palette.error.main,
                              }}
                              onClick={() => handleDeletePhoto()}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>

              {/*CATEGORY / COLOR*/}
              <Grid
                item
                container
                className={classes.fieldWrapper}
                direction={matchesXS ? "column" : "row"}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={matchesXS ? null : { paddingRight: "0.5em" }}
                >
                  <Field
                    name={"mainCategory"}
                    label={"Main Category"}
                    component={SelectInput}
                    type={"text"}
                    variant={"outlined"}
                  >
                    {mainCategories.map((category) => (
                      <MenuItem
                        key={category.id}
                        value={category.mainCategory}
                        onClick={() => handleSelectMainCategory(category.id)}
                      >
                        {category.mainCategory}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={
                    matchesXS
                      ? { marginTop: "1.5em" }
                      : { paddingLeft: "0.5em" }
                  }
                >
                  <Field
                    name={"subCategory"}
                    label={"Sub Category"}
                    component={SelectInput}
                    type={"text"}
                    variant={"outlined"}
                  >
                    {filteredSubCategories.length > 0
                      ? filteredSubCategories.map((category) => (
                          <MenuItem
                            key={category.id}
                            value={category.subCategory}
                            onClick={() => handleSelectSubCategory(category.id)}
                          >
                            {category.subCategory}
                          </MenuItem>
                        ))
                      : subCategories
                          .filter(
                            (category) =>
                              category.mainCategory === product.mainCategoryId
                          )
                          .map((category) => (
                            <MenuItem
                              key={category.id}
                              value={category.subCategory}
                              onClick={() =>
                                handleSelectSubCategory(category.id)
                              }
                            >
                              {category.subCategory}
                            </MenuItem>
                          ))}
                  </Field>
                </Grid>
              </Grid>

              {/*TOTAL OWNED*/}
              <Grid item container className={classes.fieldWrapper}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={matchesXS ? null : { paddingRight: "0.5em" }}
                >
                  <Field
                    name={"totalOwned"}
                    label={"Total Owned"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
              </Grid>

              {/*RENTAL / REPLACEMENT PRICE*/}
              <Grid
                item
                container
                className={classes.fieldWrapper}
                direction={matchesXS ? "column" : "row"}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={matchesXS ? null : { paddingRight: "0.5em" }}
                >
                  <Field
                    name={"rentalPrice"}
                    label={"Rental Price"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                    inputAdornment={"$"}
                  />
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  style={
                    matchesXS
                      ? { marginTop: "1.5em" }
                      : { paddingLeft: "0.5em" }
                  }
                >
                  <Field
                    name={"replacementCost"}
                    label={"Replacement Cost"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                    inputAdornment={"$"}
                  />
                </Grid>
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"description"}
                  label={"Description"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"notes"}
                  label={"Notes"}
                  component={TextArea}
                  type={"text"}
                  variant={"outlined"}
                  rows={6}
                />
              </Grid>
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/inventory")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editProduct",
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(EditProduct)
);
