// TEMPLATES
import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../../user/actions/asyncActions/asyncActions";
import {
    FETCH_ALL_APP_TEMPLATES,
    FETCH_APP_TEMPLATE
} from "../../constants/appAdminTemplateConstants/appAdminTemplateConstants";



export const fetchAllAppTemplates = () => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();

        const templatesQuery = firestore
            .collection("appTemplates")
            .orderBy("templateCategory", "asc")

        try {
            dispatch(asyncActionStart());
            let query = await templatesQuery.get();

            let allAppTemplates = [];

            for (let i = 0; i < query.docs.length; i++) {
                let template = {
                    ...query.docs[i].data(),
                    id: query.docs[i].id,
                };
                allAppTemplates.push(template);
            }

            dispatch({
                type: FETCH_ALL_APP_TEMPLATES,
                payload: { allAppTemplates },
            });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const fetchAppTemplate = (id) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        const templatesQuery = firestore.collection("appTemplates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            let query = await templatesQuery.get();

            let appTemplate = [];

            if (query.exists) {
                let template = {
                    id: query.id,
                    ...query.data(),
                };
                appTemplate.push(template);
            }

            if (!query.exists) {
                return;
            }

            dispatch({
                type: FETCH_APP_TEMPLATE,
                payload: { appTemplate },
            });

            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const addAppTemplate = (values, html) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        const templateQuery = firestore.collection("appTemplates");

        try {
            dispatch(asyncActionStart());

            const query = await templateQuery.add({
                ...values,
                html: html,
                templateActive: false,
            });

            dispatch(asyncActionFinish());

            return query;
        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const updateAppTemplate = (id, values, html) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        const templateQuery = firestore.collection("appTemplates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            const query = await templateQuery.update({
                ...values,
                html: html,
            });

            dispatch(asyncActionFinish());

            return query;
        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const toggleAppTemplateStatus = (id, category, value) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        const templatesQuery = firestore
            .collection("appTemplates").where('templateCategory', '==', `${category}`)

        const templateQuery = firestore.collection("appTemplates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            // TEMPLATES QUERY
            let allTemplatesQuery = await templatesQuery.get();

            for (let i = 0; i < allTemplatesQuery.docs.length; i++) {
                const updateQuery = firestore.collection("appTemplates").doc(`${allTemplatesQuery.docs[i].id}`)
                await updateQuery.update({templateActive: false})
            }


            await templateQuery.update({
                templateActive: !value
            });

            dispatch(fetchAllAppTemplates())
            dispatch(asyncActionFinish());

        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const deleteAppTemplate = (id) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        // const state = getState();

        const templateQuery = firestore.collection("appTemplates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            await templateQuery.delete()

            dispatch(fetchAllAppTemplates())
            dispatch(asyncActionFinish());

        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};