import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import { closeDialog } from "../../store/user/actions/dialogActions/dialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useHistory } from "react-router-dom";
import {Typography} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";

const actions = {
  closeDialog,
};

const ChangePasswordDialog = ({ closeDialog, profile }) => {
  const theme = useTheme();
  const history = useHistory();
  const handleClose = () => {
    closeDialog();
  };

  const handleChangePassword = () => {
    if (profile.isAppAdmin) {
      history.push("/admin/settings");
      closeDialog();
    }
    if (profile.isAppAdmin === false) {
      history.push("/settings");
      closeDialog();
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
      <DialogContent><Typography>You must change your password.</Typography></DialogContent>
      <DialogActions>
        <Button onClick={() => handleChangePassword()} color="primary">
          Change
        </Button>
        <Button onClick={() => closeDialog()} style={{ color: theme.palette.error.main}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, actions)(ChangePasswordDialog);
