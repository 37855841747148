import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import DatePickerInput from "../../common/form/DatePickerInput";
import { fetchInventoryReports } from "../../store/user/actions/reportsActions/reportsActions";
import ReportTable from "../../ui/reports/ReportTable";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const validate = combineValidators({
  // EXISTING CLIENT
  startDate: isRequired({ message: "Start Date is required" }),
  endDate: isRequired({ message: "End Date is required" }),
});

const actions = {
  fetchInventoryReports,
};

const mapStateToProps = (state) => {
  let reports = [];

  if (
    state.reports.inventoryReports &&
    state.reports.inventoryReports.length > 0
  ) {
    reports = state.reports.inventoryReports;
  }

  return {
    reports,
    loading: state.loading.loading,
  };
};

const Reports = ({ handleSubmit, reports, fetchInventoryReports }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const handleGetInventoryReports = async (values) => {
    await fetchInventoryReports(values);
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Inventory Reports</Typography>
      </Grid>

      <Grid item container style={{ marginTop: "1.5em" }}>
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleGetInventoryReports)}
        >
          <Grid
            item
            container
            alignItems={matchesSM ? "flex-end" : "center"}
            direction={matchesSM ? "column" : "row"}
          >
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingRight: "0.5em" }}
                >
                  <Field
                    name={"startDate"}
                    label={"Start Date"}
                    component={DatePickerInput}
                    type={"text"}
                    inputVariant={"outlined"}
                    size={"small"}
                  />
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ paddingLeft: "0.5em" }}
                >
                  <Field
                    name={"endDate"}
                    label={"End Date"}
                    component={DatePickerInput}
                    type={"text"}
                    inputVariant={"outlined"}
                    size={"small"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className={classes.buttonWrapper}
              style={matchesSM ? { marginTop: "0.5em" } : null}
            >
              <Button
                size={"small"}
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                type={"submit"}
              >
                Get Report
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <ReportTable rows={reports} />
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "inventoryReports", validate })(Reports));
