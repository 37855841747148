import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_MAIN_CATEGORIES,
  FETCH_ALL_SUB_CATEGORIES,
  FETCH_MAIN_CATEGORY,
  FETCH_SUB_CATEGORY,
} from "../../constants/categoryConstants/categoryConstants";

const initialState = {
  mainCategories: [],
  subCategories: [],
  mainCategory: [],
  subCategory: [],
};

const getMainCategories = (state, payload) => {
  return {
    ...state,
    mainCategories: payload.mainCategories,
  };
};

const getSubCategories = (state, payload) => {
  return {
    ...state,
    subCategories: payload.subCategories,
  };
};

const getMainCategory = (state, payload) => {
  return {
    ...state,
    mainCategory: payload.mainCategory,
  };
};

const getSubCategory = (state, payload) => {
  return {
    ...state,
    subCategory: payload.subCategory,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_MAIN_CATEGORIES]: getMainCategories,
  [FETCH_ALL_SUB_CATEGORIES]: getSubCategories,
  [FETCH_MAIN_CATEGORY]: getMainCategory,
  [FETCH_SUB_CATEGORY]: getSubCategory,
});
