import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import TextInput from "../../common/form/TextInput";
import SelectInput from "../../common/form/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import TextArea from "../../common/form/TextArea";
import StateLookup from "../forms/formLookups/StateLookup";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
}));

const AddOpportunityDeliveryAddress = () => {
  const classes = useStyles();

  const states = StateLookup.states;
  return (
    <Grid item container>
      <Grid item lg={12}>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryContactName"}
            label={"Delivery Contact Name"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryAddress1"}
            label={"Address 1"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryAddress2"}
            label={"Address 2"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryCity"}
            label={"City"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryState"}
            label={"State"}
            component={SelectInput}
            type={"text"}
            variant={"outlined"}
          >
            {states.map((state) => (
              <MenuItem key={state.key} value={state.text}>
                {state.text}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryZipCode"}
            label={"Zip Code"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryContactNumber"}
            label={"Contact Number"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>
        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryEmail"}
            label={"Email"}
            component={TextInput}
            type={"text"}
            variant={"outlined"}
          />
        </Grid>

        <Grid item className={classes.fieldWrapper}>
          <Field
            name={"deliveryNotes"}
            label={"Notes"}
            component={TextArea}
            type={"text"}
            variant={"outlined"}
            rows={6}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddOpportunityDeliveryAddress;
