import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_ORGANIZATION_USERS,
  FETCH_ORGANIZATION_USER,
} from "../../constants/organizationUserConstants/organizationUserConstants";

const initialState = {
  allOrganizationUsers: [],
  organizationUser: [],
};

const allOrganizationUsers = (state, payload) => {
  return {
    ...state,
    allOrganizationUsers: payload.organizationUsers,
  };
};

const getOrganizationUser = (state, payload) => {
  return {
    ...state,
    organizationUser: payload.organizationUser,
  };
};


export default createReducer(initialState, {
  [FETCH_ALL_ORGANIZATION_USERS]: allOrganizationUsers,
  [FETCH_ORGANIZATION_USER]: getOrganizationUser,
});
