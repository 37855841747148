import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import { closeDialog } from "../../store/user/actions/dialogActions/dialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import SubDialogManager from "../SubDialogManager";

import AddOpportunityItem from "../../ui/opportunities/AddOpportunityItem";

const actions = {
  closeDialog,
};

const ProductPickerDialog = ({ closeDialog, id, opportunity, type }) => {
  const handleClose = () => {
    closeDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">Product Picker</DialogTitle>
      <DialogContent>
        <AddOpportunityItem
          jobId={id}
          opportunity={opportunity}
          type={type}
        />

        {/*AVAILABILITY DIALOG*/}
        <SubDialogManager />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, actions)(ProductPickerDialog);
