import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import inventoryPlaceholder from "../../assets/placeholder/inventory/inventory.png";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "250px",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "150px",
    },
  },
  horizontalLine: {
    borderColor: theme.palette.primary.main,
  },
}));

const InventoryItem = ({ item }) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  return (
    <Grid
      item
      lg={3}
      md={4}
      sm={6}
      xs={6}
      style={{ cursor: "pointer" }}
      onClick={() => history.push(`/inventory/view?id=${item.id}`)}
    >
      <Grid item container direction={"column"}>
        {/*IMAGE*/}
        <Grid item>
          <img
            src={item.photoURL ? item.photoURL : inventoryPlaceholder}
            alt={
              item.photoURL
                ? `${item.name} inventory image `
                : "inventory placeholder"
            }
            className={classes.image}
          />
        </Grid>
        <Grid item>
          <hr className={classes.horizontalLine} />
        </Grid>

        <Grid item>
          <Typography variant={"h6"}>{item?.name}</Typography>
        </Grid>
        <Grid item style={{ paddingTop: "0.3em" }}>
          <Typography variant={"subtitle2"}>
            <span style={{ color: theme.palette.primary.main }}>
              Main Category:
            </span>{" "}
            {item?.mainCategory}
          </Typography>
        </Grid>
        <Grid item style={{ paddingTop: "0.3em" }}>
          <Typography variant={"subtitle2"}>
            <span style={{ color: theme.palette.primary.main }}>
              Sub Category:
            </span>{" "}
            {item?.subCategory}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"caption"}>
            {item?.totalOwned} total owned
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InventoryItem;
