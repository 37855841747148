import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { closeDialog } from "../../store/user/actions/dialogActions/dialogActions";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { updateOpportunityItemQty } from "../../store/user/actions/opportunityActions/opportunityActions";
import Typography from "@material-ui/core/Typography";
import { fetchInventoryProduct } from "../../store/user/actions/inventoryActions/inventoryActions";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
}));

const actions = {
  closeDialog,
  updateOpportunityItemQty,
  fetchInventoryProduct,
};

const mapStateToProps = (state) => {
  let product = {};

  if (state.inventory.product && state.inventory.product.length > 0) {
    product = state.inventory.product[0];
  }

  return {
    product,
  };
};

const UpdateItemQtyDialog = ({
  closeDialog,
  updateOpportunityItemQty,
  opportunity,
  product,
  fetchInventoryProduct,
  row,
  jobId,
}) => {
  const classes = useStyles();

  const [qty, setQty] = useState("");
  const [error, serError] = useState("");

  let currentQty = Number(row?.qty);

  useEffect(() => {
    fetchInventoryProduct(row.itemId, opportunity);
    setQty(row.qty);
  }, [setQty, row, fetchInventoryProduct, opportunity]);

  const handleChange = (event) => {
    setQty(event.target.value);
  };

  const handleUpdateItem = () => {
    if (qty - currentQty > product.available) {
      serError(`Qty available is ${product.available}`);
    } else {
      updateOpportunityItemQty(row.id, row.itemId, jobId, qty);
      closeDialog();
    }
  };

  const handleClose = () => {
    closeDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Update Qty</DialogTitle>
      <DialogContent>
        <form autoComplete={"off"}>
          <Grid item container alignItems={"center"}>
            <Grid item className={classes.fieldWrapper}>
              <TextField
                variant={"outlined"}
                label={"Enter Qty"}
                type={"text"}
                value={qty}
                size={"small"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                onClick={() => handleUpdateItem()}
              >
                Update
              </Button>
            </Grid>
          </Grid>

          {error && (
            <Grid item container>
              <Grid item>
                <Typography variant={"subtitle1"} className={classes.error}>
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, actions)(UpdateItemQtyDialog);
