import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import LiveSearch from "../../ui/search/LiveSearch";
import Button from "@material-ui/core/Button";
import ContactsTable from "../../ui/contacts/ContactsTable";
import { connect } from "react-redux";
import { fetchAllContacts } from "../../store/user/actions/contactsActions/contactsActions";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import Loading from "../../ui/loading/Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllContacts,
};

const mapStateToProps = (state) => {
  let contacts = [];

  if (state.contacts.allContacts && state.contacts.allContacts.length > 0) {
    contacts = state.contacts.allContacts;
  }
  return {
    contacts,
    loading: state.loading.loading
  };
};

const Contacts = ({ fetchAllContacts, contacts, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllContacts();
  }, [fetchAllContacts]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Contacts</Typography>
      </Grid>

      <Grid
        item
        container
        direction={matchesXS ? "column" : "row"}
        alignItems={matchesXS ? null : "center"}
        style={{ marginTop: "2em" }}
      >
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <LiveSearch data={contacts} setSearchResults={setSearchResults} />
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={4}
          xs={12}
          style={matchesXS ? { marginTop: "0.5em" } : null}
        >
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"small"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/contacts/new")}
              >
                Add Contact
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        {loading && (
            <Loading/>
        )}
        {!loading && (
            <ContactsTable
                rows={
                  searchResults < 1
                      ? contacts
                      : contacts.filter((contact) => contact.search)
                }
            />
        )}

      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Contacts);
