import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    width: "100%",
  },
}));

const LiveSearch = ({ data, setSearchResults, disabled }) => {
  const classes = useStyles();

  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);

    const searchData = data.map((searchItem) =>
      Object.values(searchItem).filter(
        (option) => option !== true && option !== false
      )
    );

    const matches = searchData.map((searchItem) =>
      searchItem.map((option) =>
        option
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );

    const searchList = [...data];
    matches.map((searchItem, index) =>
      searchItem.includes(true)
        ? (searchList[index].search = true)
        : (searchList[index].search = false)
    );

    setSearchResults(searchList);

    if (event.target.value === "") {
      setSearchResults([]);
    }
  };
  return (
    <Grid item container justify={"center"}>
      <TextField
        disabled={disabled}
        size={"small"}
        value={search}
        onChange={handleSearch}
        id={"search-box"}
        label={"Search"}
        variant={"outlined"}
        className={classes.searchBox}
        color={"primary"}
        autoComplete={"off"}
      />
    </Grid>
  );
};

export default LiveSearch;
