import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {CircularProgress, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  title: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9em",
    },
  },
  value: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3em",
    },
  },
}));

const DashboardItem = ({ title, value, loading }) => {
  const classes = useStyles();
  return (
    <Grid item md={4} sm={4} xs={4} className={classes.itemWrapper}>
      <Grid
        item
        container
        direction={"column"}
        alignItems={"center"}
        className={classes.container}
      >
        <Grid item>
          <Typography variant={"h6"} className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "1em" }}>
          {loading && (
              <CircularProgress/>
          )}
          {!loading && (
              <Typography variant={"h2"} className={classes.value}>
                {value}
              </Typography>
          )}

        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardItem;
