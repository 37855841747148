import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { format, addDays } from "date-fns";

import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { fetchItemTransactions } from "../../store/user/actions/transactionActions/transactionActions";
import {useTheme} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  availabilityContainer: {
    border: "1px solid" + theme.palette.primary.main,
    borderRadius: "5px",
  },
  dateContainer: {
    marginBottom: "1em",
  },
  infoColumn: {
    width: "20%",
    paddingLeft: "1em",
  },
  infoContainer: {
    marginBottom: "1em",
  },
  qtyColumn: {
    width: "11.42%",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: '16%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '20%'
    }
  },
  todaysDate: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  availability: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  noAvailability: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
}));

const actions = {
  fetchItemTransactions,
  // fetchItemAvailability
};

const mapStateToProps = (state) => {
  let transactions = [];

  if (
    state.transactions.itemTransactions &&
    state.transactions.itemTransactions.length > 0
  ) {
    transactions = state.transactions.itemTransactions;
  }

  return {
    transactions,
    loading: state.loading.loading,
  };
};

const AvailabilityTable = ({
  // fetchItemAvailability,
  fetchItemTransactions,
  product,
  loading,
  transactions,
    setupDate
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const todaysDate = new Date()
  const futureDate = new Date(`${setupDate}`)

  // const isoDate = new Date();
  const isoDate = setupDate ? futureDate : todaysDate;
  const [next, setNext] = useState(0);

  useEffect(() => {
    fetchItemTransactions(product.id);
  }, [fetchItemTransactions, product]);

  let day1availability = 0;
  let day2availability = 0;
  let day3availability = 0;
  let day4availability = 0;
  let day5availability = 0;
  let day6availability = 0;
  let day7availability = 0;

  let day1inquiries = 0;
  let day2inquiries = 0;
  let day3inquiries = 0;
  let day4inquiries = 0;
  let day5inquiries = 0;
  let day6inquiries = 0;
  let day7inquiries = 0;

  let day1quotes = 0;
  let day2quotes = 0;
  let day3quotes = 0;
  let day4quotes = 0;
  let day5quotes = 0;
  let day6quotes = 0;
  let day7quotes = 0;

  // ORDER
  // DAY 1
  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day1availability = day1availability + Number(item.jobQty)));

  // DAY 2
  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 1), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day2availability = day2availability + Number(item.jobQty)));

  // DAY 3

  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 2), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day3availability = day3availability + Number(item.jobQty)));

  // DAY 4

  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 3), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day4availability = day4availability + Number(item.jobQty)));

  // DAY 5

  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 4), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day5availability = day5availability + Number(item.jobQty)));

  // DAY 6

  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 5), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day6availability = day6availability + Number(item.jobQty)));

  // DAY 7

  transactions
    .filter(
      (item) => item.date === format(addDays(isoDate, next + 6), "EEE MMM do y") && item.status === 'order'
    )
    .map((item) => (day7availability = day7availability + Number(item.jobQty)));


  // QUOTES
  // DAY 1
  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day1quotes = day1quotes + Number(item.jobQty)));

  // DAY 2
  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 1), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day2quotes = day2quotes + Number(item.jobQty)));

  // DAY 3

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 2), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day3quotes = day3quotes + Number(item.jobQty)));

  // DAY 4

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 3), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day4quotes = day4quotes + Number(item.jobQty)));

  // DAY 5

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 4), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day5quotes = day5quotes + Number(item.jobQty)));

  // DAY 6

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 5), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day6quotes = day6quotes + Number(item.jobQty)));

  // DAY 7

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 6), "EEE MMM do y") && item.status === 'quote'
      )
      .map((item) => (day7quotes = day7quotes + Number(item.jobQty)));



  // INQUIRIES
  // DAY 1
  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day1inquiries = day1inquiries + Number(item.jobQty)));

  // DAY 2
  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 1), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day2inquiries = day2inquiries + Number(item.jobQty)));

  // DAY 3

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 2), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day3inquiries = day3inquiries + Number(item.jobQty)));

  // DAY 4

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 3), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day4inquiries = day4inquiries + Number(item.jobQty)));

  // DAY 5

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 4), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day5inquiries = day5inquiries + Number(item.jobQty)));

  // DAY 6

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 5), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day6inquiries = day6inquiries + Number(item.jobQty)));

  // DAY 7

  transactions
      .filter(
          (item) => item.date === format(addDays(isoDate, next + 6), "EEE MMM do y") && item.status === 'inquiry'
      )
      .map((item) => (day7inquiries = day7inquiries + Number(item.jobQty)));

  const totalOwned = Number(product.totalOwned);
  let availabilityData = [
    {
      day1: {
        date: format(addDays(isoDate, next), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day1availability,
        availability: totalOwned - day1availability > 0,
        bookedOut: day1availability,
        inquiries: day1inquiries,
        quotes: day1quotes
      },
    },
    {
      day2: {
        date: format(addDays(isoDate, next + 1), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day2availability,
        availability: totalOwned - day2availability > 0,
        bookedOut: day2availability,
        inquiries: day2inquiries,
        quotes: day2quotes
      },
    },
    {
      day3: {
        date: format(addDays(isoDate, next + 2), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day3availability,
        availability: totalOwned - day3availability > 0,
        bookedOut: day3availability,
        inquiries: day3inquiries,
        quotes: day3quotes
      },},
    {
      day4: {
        date: format(addDays(isoDate, next + 3), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day4availability,
        availability: totalOwned - day4availability > 0,
        bookedOut: day4availability,
        inquiries: day4inquiries,
        quotes: day4quotes
      },
    },

    {
      day5: {
        date: format(addDays(isoDate, next + 4), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day5availability,
        availability: totalOwned - day5availability > 0,
        bookedOut: day5availability,
        inquiries: day5inquiries,
        quotes: day5quotes
      },
    },

    {
      day6: {
        date: format(addDays(isoDate, next + 5), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day6availability,
        availability: totalOwned - day6availability > 0,
        bookedOut: day6availability,
        inquiries: day6inquiries,
        quotes: day6quotes
      },
    },

    {
      day7: {
        date: format(addDays(isoDate, next + 6), "EEE MMM do"),
        totalOwned: totalOwned,
        available: totalOwned - day7availability,
        availability: totalOwned - day7availability > 0,
        bookedOut: day7availability,
        inquiries: day7inquiries,
        quotes: day7quotes
      },
    },
  ];

  // HANDLERS
  const handleNext = () => {
    if (matchesXS) {
      setNext(next + 4);
    }
    if (matchesSM) {
      setNext(next + 5);
    }
    if (!matchesSM) {
      setNext(next + 7);
    }

  };

  const handlePrevious = () => {
    if (matchesXS) {
      setNext(next - 4);
    }
    if (matchesSM) {
      setNext(next - 5);
    }

    if (!matchesSM) {
      setNext(next - 7);
    }

  };
  return (
    <Fragment>
      <Grid
        item
        container
        direction={"column"}
        className={classes.availabilityContainer}
      >
        <Grid item>
          {/*ARROWS*/}
          <Grid item container>
            <Grid item>
              <IconButton onClick={() => handlePrevious()}>
                <SkipPreviousIcon />
              </IconButton>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <IconButton onClick={() => handleNext()}>
                <SkipNextIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/*DATES*/}
          <Grid item container className={classes.dateContainer}>
            <Grid item className={classes.infoColumn}></Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {availabilityData[0]?.day1.date}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next + 1), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {availabilityData[1]?.day2.date}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next + 2), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {availabilityData[2]?.day3.date}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next + 3), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {availabilityData[3]?.day4.date}
              </Typography>
            </Grid>
            {!matchesXS && (
                <Grid item className={classes.qtyColumn}>
                  <Typography
                      variant={"caption"}
                      className={
                        format(addDays(isoDate, next + 4), "EEE MMM do y") ===
                        format(isoDate, "EEE MMM do y")
                            ? classes.todaysDate
                            : null
                      }
                  >
                    {availabilityData[4]?.day5.date}
                  </Typography>
                </Grid>
            )}


            {!matchesSM && (
                <Fragment>
                  <Grid item className={classes.qtyColumn}>
                    <Typography
                        variant={"caption"}
                        className={
                          format(addDays(isoDate, next + 5), "EEE MMM do y") ===
                          format(isoDate, "EEE MMM do y")
                              ? classes.todaysDate
                              : null
                        }
                    >
                      {availabilityData[5]?.day6.date}
                    </Typography>
                  </Grid>



                  <Grid item className={classes.qtyColumn}>
                    <Typography
                        variant={"caption"}
                        className={
                          format(addDays(isoDate, next + 6), "EEE MMM do y") ===
                          format(isoDate, "EEE MMM do y")
                              ? classes.todaysDate
                              : null
                        }
                    >
                      {availabilityData[6]?.day7.date}
                    </Typography>
                  </Grid>
                </Fragment>
            )}


          </Grid>

          {/*TOTAL OWNED*/}
          <Grid
            item
            container
            alignItems={"center"}
            className={classes.infoContainer}
          >
            <Grid item className={classes.infoColumn}>
              <Typography variant={"caption"}>Total Owned</Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[0]?.day1.totalOwned.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[1]?.day2.totalOwned.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[2]?.day3.totalOwned.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[3]?.day4.totalOwned.toString()}
              </Typography>
            </Grid>
            {!matchesXS && (
                <Grid item className={classes.qtyColumn}>
                  <Typography variant={"caption"}>
                    {!loading && availabilityData[4]?.day5.totalOwned.toString()}
                  </Typography>
                </Grid>
            )}


            {!matchesSM && (
                <Fragment>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[5]?.day6.totalOwned.toString()}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[6]?.day7.totalOwned.toString()}
                    </Typography>
                  </Grid>
                </Fragment>
            )}

          </Grid>



          {/*QUOTES*/}
          <Grid
              item
              container
              alignItems={"center"}
              className={classes.infoContainer}
          >
            <Grid item className={classes.infoColumn}>
              <Typography variant={"caption"}>Quotes</Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[0]?.day1.quotes}
                {/*{day1availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[1]?.day2.quotes?.toString()}
                {/*{day2availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[2]?.day3.quotes?.toString()}
                {/*{day3availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[3]?.day4.quotes?.toString()}
                {/*{day4availability.toString()}*/}
              </Typography>
            </Grid>
            {!matchesXS && (
                <Grid item className={classes.qtyColumn}>
                  <Typography variant={"caption"}>
                    {!loading && availabilityData[4]?.day5.quotes?.toString()}
                    {/*{day5availability.toString()}*/}
                  </Typography>
                </Grid>
            )}

            {!matchesSM && (
                <Fragment>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[5]?.day6.quotes?.toString()}
                      {/*{day6availability.toString()}*/}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[6]?.day7.quotes?.toString()}
                      {/*{day7availability.toString()}*/}
                    </Typography>
                  </Grid>
                </Fragment>
            )}

          </Grid>

          {/*BOOKED OUT*/}
          <Grid
            item
            container
            alignItems={"center"}
            className={classes.infoContainer}
          >
            <Grid item className={classes.infoColumn}>
              <Typography variant={"caption"}>Booked Out</Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[0]?.day1.bookedOut}
                {/*{day1availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[1]?.day2.bookedOut?.toString()}
                {/*{day2availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[2]?.day3.bookedOut?.toString()}
                {/*{day3availability.toString()}*/}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography variant={"caption"}>
                {!loading && availabilityData[3]?.day4.bookedOut?.toString()}
                {/*{day4availability.toString()}*/}
              </Typography>
            </Grid>
            {!matchesXS && (
                <Grid item className={classes.qtyColumn}>
                  <Typography variant={"caption"}>
                    {!loading && availabilityData[4]?.day5.bookedOut?.toString()}
                    {/*{day5availability.toString()}*/}
                  </Typography>
                </Grid>
            )}

            {!matchesSM && (
                <Fragment>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[5]?.day6.bookedOut?.toString()}
                      {/*{day6availability.toString()}*/}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.qtyColumn}>
                    <Typography variant={"caption"}>
                      {!loading && availabilityData[6]?.day7.bookedOut?.toString()}
                      {/*{day7availability.toString()}*/}
                    </Typography>
                  </Grid>
                </Fragment>
            )}

          </Grid>



          {/*AVAILABLE*/}
          <Grid
            item
            container
            alignItems={"center"}
            className={classes.infoContainer}
          >
            <Grid item className={classes.infoColumn}>
              <Typography variant={"caption"}>Available</Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  !loading && availabilityData[0]?.day1.availability
                    ? classes.availability
                    : classes.noAvailability
                }
              >
                {!loading && availabilityData[0]?.day1.available.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  !loading && availabilityData[1]?.day2.availability
                    ? classes.availability
                    : classes.noAvailability
                }
              >
                {!loading && availabilityData[1]?.day2.available.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  !loading && availabilityData[2]?.day3.availability
                    ? classes.availability
                    : classes.noAvailability
                }
              >
                {!loading && availabilityData[2]?.day3.available.toString()}
              </Typography>
            </Grid>
            <Grid item className={classes.qtyColumn}>
              <Typography
                variant={"caption"}
                className={
                  !loading && availabilityData[3]?.day4.availability
                    ? classes.availability
                    : classes.noAvailability
                }
              >
                {!loading && availabilityData[3]?.day4.available.toString()}
              </Typography>
            </Grid>
            {!matchesXS && (
                <Grid item className={classes.qtyColumn}>
                  <Typography
                      variant={"caption"}
                      className={
                        !loading && availabilityData[4]?.day5.availability
                            ? classes.availability
                            : classes.noAvailability
                      }
                  >
                    {!loading && availabilityData[4]?.day5.available.toString()}
                  </Typography>
                </Grid>
            )}


            {!matchesSM && (
                <Fragment>
                  <Grid item className={classes.qtyColumn}>
                    <Typography
                        variant={"caption"}
                        className={
                          !loading && availabilityData[5]?.day6.availability
                              ? classes.availability
                              : classes.noAvailability
                        }
                    >
                      {!loading && availabilityData[5]?.day6.available.toString()}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.qtyColumn}>
                    <Typography
                        variant={"caption"}
                        className={
                          !loading && availabilityData[6]?.day7.availability
                              ? classes.availability
                              : classes.noAvailability
                        }
                    >
                      {!loading && availabilityData[6]?.day7.available.toString()}
                    </Typography>
                  </Grid>
                </Fragment>
            )}

          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(AvailabilityTable);
