import React, { useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddMainCategory from "../../../ui/settings/categories/AddMainCategory";
import { connect } from "react-redux";
import {
  fetchAllMainCategories,
  fetchAllSubCategories,
} from "../../../store/user/actions/categoryActions/categoryActions";
import CategoryListItem from "../../../ui/settings/categories/CategoryListItem";
import AddSubCategory from "../../../ui/settings/categories/AddSubCategory";
import SubCategoryListItem from "../../../ui/settings/categories/SubCategoryListItem";
import { useTheme } from "@material-ui/styles";

const actions = {
  fetchAllMainCategories,
  fetchAllSubCategories,
};

const mapStateToProps = (state) => {
  let mainCategories = [];
  let subCategories = [];

  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    mainCategories = state.categories.mainCategories;
  }

  if (
    state.categories.subCategories &&
    state.categories.subCategories.length > 0
  ) {
    subCategories = state.categories.subCategories;
  }

  return {
    mainCategories,
    subCategories,
  };
};

const Categories = ({
  fetchAllMainCategories,
  fetchAllSubCategories,
  mainCategories,
  subCategories,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchAllMainCategories();
    fetchAllSubCategories();
  }, [fetchAllMainCategories, fetchAllSubCategories]);
  return (
    <Grid
      container
      direction={matchesSM ? "column" : "row"}
      style={{ paddingLeft: "1em", paddingRight: "1em" }}
    >
      <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingRight: "3em" }}>
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h6"}>Add Main Category</Typography>
          </Grid>
          <Grid
            item
            style={{
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            <AddMainCategory />
          </Grid>

          <Grid item style={matchesSM ? null : { marginTop: "1.5em" }}>
            {mainCategories.map((category) => (
              <CategoryListItem key={category.id} category={category} />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={
          matchesSM
            ? { marginTop: "1.5em", paddingRight: "3em" }
            : { paddingRight: "3em" }
        }
      >
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography variant={"h6"}>Add Sub Category</Typography>
          </Grid>

          <Grid item>
            <Grid
              item
              style={{
                marginTop: "0.5em",
                marginBottom: "0.5em",
              }}
            >
              <AddSubCategory />
            </Grid>
          </Grid>

          <Grid item style={matchesSM ? null : { marginTop: "1.5em" }}>
            {subCategories.map((subCategory) => (
              <SubCategoryListItem
                key={subCategory.id}
                subCategory={subCategory}
                mainCategories={mainCategories}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Categories);
