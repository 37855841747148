import { createReducer } from "../../../../common/util/reducerUtil";
import { FETCH_CURRENT_ORGANIZATION } from "../../constants/currentOrganizationConstants/currentOrganizationConstants";

const initialState = {
  organization: [],
};

const getCurrentOrganization = (state, payload) => {
  return {
    ...state,
    organization: payload.organization,
  };
};

export default createReducer(initialState, {
  [FETCH_CURRENT_ORGANIZATION]: getCurrentOrganization,
});
