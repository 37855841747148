import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { openDialog } from "../../store/user/actions/dialogActions/dialogActions";

import { connect } from "react-redux";

import { deleteOpportunityItem } from "../../store/user/actions/opportunityActions/opportunityActions";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
}));

const actions = {
  openDialog,
  deleteOpportunityItem,
};

const EditOpportunityItemsTable = ({
  items,
  opportunity,
  jobId,
  openDialog,
  deleteOpportunityItem,
  type,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Item</TableCell>

              <TableCell className={classes.tableCell}>Qty</TableCell>
              {!matchesSM && (
                <Fragment>
                  <TableCell className={classes.tableCell}>
                    Price /day
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Total /day
                  </TableCell>
                </Fragment>
              )}

              <TableCell className={classes.tableCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>{row?.name}</TableCell>

                <TableCell className={classes.tableCell}>{row?.qty}</TableCell>
                {!matchesSM && (
                  <Fragment>
                    <TableCell className={classes.tableCell}>
                      ${Number(row?.rentalPrice).toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      $
                      {(Number(row?.rentalPrice) * Number(row?.qty)).toFixed(2)}
                    </TableCell>
                  </Fragment>
                )}

                <TableCell className={classes.tableCell}>
                  <Grid item container direction={matchesMD ? "column" : "row"}>
                    <Grid item>
                      <Button
                        variant={"contained"}
                        size={"small"}
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                        onClick={() =>
                          openDialog("UpdateItemQtyDialog", {
                            row,
                            jobId,
                            opportunity,
                          })
                        }
                      >
                        Update Qty
                      </Button>
                    </Grid>

                    <Grid
                      item
                      style={
                        matchesMD
                          ? { marginTop: "0.5em" }
                          : { marginLeft: "0.5em" }
                      }
                    >
                      <Button
                        variant={"contained"}
                        size={"small"}
                        style={{
                          backgroundColor: theme.palette.themeBlue,
                          color: "white",
                        }}
                        onClick={() =>
                          openDialog("UpdateItemPriceDialog", { row, jobId })
                        }
                      >
                        Update Price
                      </Button>
                    </Grid>

                    <Grid
                      item
                      style={
                        matchesMD
                          ? { marginTop: "0.5em" }
                          : { marginLeft: "0.5em" }
                      }
                    >
                      <Button
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        style={{
                          color: "white",
                        }}
                        onClick={() =>
                          deleteOpportunityItem(row.id, row.itemId, jobId)
                        }
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default connect(null, actions)(EditOpportunityItemsTable);
