import { createReducer } from "../../../../common/util/reducerUtil";
import { FETCH_DASHBOARD_VALUES } from "../../constants/dashboardConstants/dashboardConstants";

const initialState = {
  values: [],
};

const getDashboardValues = (state, payload) => {
  return {
    ...state,
    values: payload.values,
  };
};

export default createReducer(initialState, {
  [FETCH_DASHBOARD_VALUES]: getDashboardValues,
});
