import React from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Editor } from "@tinymce/tinymce-react";

// const useStyles = makeStyles((theme) => ({
//   // ADD STYLES HERE
// }));

const TextEditor = ({ editorValue, handleChange }) => {
  // const classes = useStyles();
  // const theme = useTheme();
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Editor
      initialValue={editorValue}
      init={{
        menubar: true,
        height: 600,
        content_style: "body {margin: 10px !important}",
        plugins: [
          " advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code",
        ],
        toolbar:
          "undo redo | formatselect | fontselect |fontsizeselect lineheight | bold italic underline forecolor backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat",
      }}
      onEditorChange={handleChange}
    />
  );
};

export default TextEditor;
