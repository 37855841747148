import cuid from "cuid";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import {
  FETCH_ALL_INVENTORY,
  FETCH_INVENTORY_PRODUCT,
  FETCH_INVENTORY_PRODUCT_HISTORY,
} from "../../constants/inventoryConstants/inventoryConstants";
import { addDays, differenceInCalendarDays, format } from "date-fns";

export const fetchAllInventory = (opportunity, value) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    let inventoryQuery = firestore
        .collection("inventory")
        .orderBy(`${value}`, "asc")
        .where("organizationId", "==", `${organizationId}`);


    // const transactionQuery = firestore.collection("itemTransactions").where("organizationId", "==", `${organizationId}`).where('status', '==', 'order')
    const transactionQuery = firestore.collection("itemTransactions").where("organizationId", "==", `${organizationId}`).where('status', '==', 'order').where('eventDay', '==', true)

    try {
      dispatch(asyncActionStart());

      let query = await inventoryQuery.get();

      if (!opportunity) {
        let inventory = [];
        for (let i = 0; i < query.docs.length; i++) {
          let item = {
            ...query.docs[i].data(),
            id: query.docs[i].id,
          };
          inventory.push(item);
        }

        dispatch({
          type: FETCH_ALL_INVENTORY,
          payload: { inventory },
        });
      }

      if (opportunity) {
        let transQuery = await transactionQuery.get();
        const eventStartDate = new Date(opportunity.eventStartDate);
        const eventEndDate = new Date(opportunity.eventEndDate);


        const numberOfDays =
          differenceInCalendarDays(eventEndDate, eventStartDate) + 1;

        let inventory = [];
        let itemDurationTotal = [];

        let bookedOut = 0;
        let available = 0;
        let availabilityStatus = "";

        // TRANSQUERY
        let itemTrans = []
        for (let i = 0; i < transQuery.docs.length; i++) {
          let item = {
            ...transQuery.docs[i].data(),
            id: transQuery.docs[i].id,
          };
          itemTrans.push(item);
        }

        // for (let i = 0; i < query.docs.length; i++) {
        for (let i = 0; i < query.docs.length; i++) {
          let totalOwned = query.docs[i].data().totalOwned;
          let itemId = query.docs[i].id;

          // TRANSACTIONS
          let itemTransactions = [];
          let filteredItemTransactions = itemTrans.filter(item => item.itemId === itemId)

          for (let i = 0; i < filteredItemTransactions.length; i++) {
            let item = {
              ...filteredItemTransactions[i],
              id: filteredItemTransactions[i].id
            };
            itemTransactions.push(item);
          }

          for (let i = 0; i < numberOfDays; i++) {
            itemTransactions
              .filter(
                (item) =>
                  item.date === format(addDays(eventStartDate, i), "EEE MMM do y") &&
                  item.status === "order"
                    // item.date === format(addDays(eventStartDate, i), "EEE MMM do y")
              )
              .map((item) => {
                bookedOut = bookedOut + Number(item.jobQty);
              });

            let day = {
              date: new Date(addDays(eventStartDate, i)),
              bookedOut: bookedOut,
              available: totalOwned - bookedOut,
              itemId: itemId,
            };
            itemDurationTotal.push(day);
            bookedOut = 0;
          }

          for (let i = 0; i < itemDurationTotal.length; i++) {
            const length = itemDurationTotal.length;
            const previous = itemDurationTotal[length - 1];

            if (itemDurationTotal[i].available === 0) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "NO AVAILABILITY";
            }

            if (
              itemDurationTotal[i].available > 0 &&
              itemDurationTotal[i].available <= totalOwned
            ) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "OK";
            }

            if (
              previous.itemId === itemDurationTotal[i].itemId &&
              previous.available > itemDurationTotal[i].available
            ) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "CHECK AVAILABILITY";
              break;
            }
          }

          let item = {
            ...query.docs[i].data(),
            id: query.docs[i].id,
            itemTransactions: itemTransactions,
            bookedOut: bookedOut,
            available: available,
            availabilityStatus: availabilityStatus,
          };
          inventory.push(item);

          bookedOut = 0;
        }

        dispatch({
          type: FETCH_ALL_INVENTORY,
          payload: { inventory },
        });
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};


export const fetchInventoryProduct = (id, opportunity) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const productQuery = firestore.collection("inventory").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let product = [];

      if (!opportunity) {
        let query = await productQuery.get();

        if (query.exists) {
          let item = {
            id: query.id,
            ...query.data(),
          };
          product.push(item);
        }

        if (!query.exists) {
          return;
        }

        dispatch({ type: FETCH_INVENTORY_PRODUCT, payload: { product } });
      }

      if (opportunity) {
        let query = await productQuery.get();
        const setupDate = new Date(opportunity.setupDate);
        const pickupDate = new Date(opportunity.pickupDate);

        const numberOfDays =
          differenceInCalendarDays(pickupDate, setupDate) + 1;

        let itemDurationTotal = [];

        let bookedOut = 0;
        let available = 0;
        let availabilityStatus = "";
        let itemId = "";

        if (query.exists) {
          let totalOwned = query.data().totalOwned;

          // TRANSACTIONS
          const transactionQuery = firestore
            .collection("itemTransactions")
            .where("itemId", "==", `${query.id}`);
          let itemQuery = await transactionQuery.get();

          let itemTransactions = [];

          for (let i = 0; i < itemQuery.docs.length; i++) {
            let item = {
              ...itemQuery.docs[i].data(),
              id: itemQuery.docs[i].id,
            };
            itemTransactions.push(item);
          }

          for (let i = 0; i < numberOfDays; i++) {
            itemTransactions
              .filter(
                (item) =>
                  item.date === format(addDays(setupDate, i), "EEE MMM do y") &&
                  item.status === "order"
              )
              .map((item) => {
                bookedOut = bookedOut + Number(item.jobQty);
                itemId = item.itemId;
              });

            let day = {
              date: new Date(addDays(setupDate, i)),
              bookedOut: bookedOut,
              available: totalOwned - bookedOut,
              itemId: itemId,
            };
            itemDurationTotal.push(day);
            bookedOut = 0;
          }

          for (let i = 0; i < itemDurationTotal.length; i++) {
            const length = itemDurationTotal.length;
            const previous = itemDurationTotal[length - 1];

            if (itemDurationTotal[i].available === 0) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "NO AVAILABILITY";
            }

            if (
              itemDurationTotal[i].available > 0 &&
              itemDurationTotal[i].available <= totalOwned
            ) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "OK";
            }

            if (
              previous.itemId === itemDurationTotal[i].itemId &&
              previous.available > itemDurationTotal[i].available
            ) {
              available = itemDurationTotal[i].available;
              bookedOut = itemDurationTotal[i].bookedOut;
              availabilityStatus = "CHECK AVAILABILITY";
              break;
            }
          }

          let item = {
            ...query.data(),
            id: query.id,
            itemTransactions: itemTransactions,
            bookedOut: bookedOut,
            available: available,
            availabilityStatus: availabilityStatus,
          };
          product.push(item);

          bookedOut = 0;

          dispatch({ type: FETCH_INVENTORY_PRODUCT, payload: { product } });
        }
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchInventoryProductHistory = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const transactionQuery = firestore
      .collection("itemTransactions")
      .orderBy("createdAt", "asc")
      .where("itemId", "==", `${id}`);

    try {
      dispatch(asyncActionStart());

      // TRANSACTIONS

      let itemTransactionQuery = await transactionQuery.get();

      let productHistory = [];

      for (let i = 0; i < itemTransactionQuery.docs.length; i++) {
        let item = {
          ...itemTransactionQuery.docs[i].data(),
          id: itemTransactionQuery.docs[i].id,
        };
        productHistory.push(item);
      }

      dispatch({
        type: FETCH_INVENTORY_PRODUCT_HISTORY,
        payload: { productHistory },
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewProduct = (values, mainCategoryId, subCategoryId, image) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const firebaseQuery = firebase.auth().currentUser;
    const inventoryQuery = firestore.collection("inventory");

    try {
      dispatch(asyncActionStart());

      let productId = await inventoryQuery.add({
        ...values,
        mainCategoryId: mainCategoryId,
        subCategoryId: subCategoryId,
        currentStockQty: values.totalOwned,
        addedBy: firebaseQuery.uid,
        createdAt: new Date().toISOString(),
        organizationId: organizationId,
      });

      if (image) {
        const imageName = cuid() + ".jpg";
        const path = `${productId.id}`;
        const options = {
          name: imageName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          image[0],
          null,
          options
        );

        // get download URL
        let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

        // add image name and URL to firestore
        await inventoryQuery.doc(`${productId.id}`).update({
          photoURL: downloadURL,
          photoName: imageName,
        });
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateProduct = (
  id,
  values,
  mainCategoryId,
  subCategoryId,
  image
) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const productQuery = firestore.collection("inventory").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await productQuery.update({
        ...values,
        mainCategoryId: mainCategoryId,
        subCategoryId: subCategoryId,
      });

      if (image) {
        const imageName = cuid() + ".jpg";
        const path = `${id}`;
        const options = {
          name: imageName,
        };

        let uploadedFile = await firebase.uploadFile(
          path,
          image[0],
          null,
          options
        );

        // get download URL
        let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

        // add image name and URL to firestore
        await productQuery.update({
          photoURL: downloadURL,
          photoName: imageName,
        });
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteProduct = (id, imgName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const productQuery = firestore.collection("inventory").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      if (imgName) {
        await dispatch(deleteProductPhoto(id, imgName));
      }

      await productQuery.delete();

      dispatch(fetchAllInventory(null, 'name'));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteProductPhoto = (id, imgName) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let firestoreQuery = firestore.collection("inventory");

    try {
      dispatch(asyncActionStart());

      // DELETE PHOTO FROM STORAGE
      await firebase.deleteFile(`${id}/${imgName}`);

      await firestoreQuery.doc(`${id}`).update({ photoURL: "", photoName: "" });

      dispatch(fetchInventoryProduct(id));
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
    }
  };
};
