import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../common/form/TextInput";

import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const validate = combineValidators({
  fullName: isRequired({ message: "Name is required" }),
  email: isRequired({ message: "Email is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
});

const mapStateToProps = (state) => {
  return {
    initialValues: state.firebase.profile,
  };
};

const EditProfile = ({
  updateProfile,
  closeForm,
  handleSubmit,
  submitting,

  error,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit(updateProfile())}>
      {/*BUTTON/TITLE CONTAINER*/}
      <Grid item container direction={"column"} alignItems={"center"}></Grid>

      {/*INPUT CONTAINER*/}
      <Grid item container style={{ marginTop: "2em" }}>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <Grid item className={classes.fieldWrapper}>
            <Field
              name={"fullName"}
              label={"Full Name"}
              component={TextInput}
              type={"text"}
              variant={"outlined"}
            />
          </Grid>

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={"email"}
              label={"Email"}
              component={TextInput}
              type={"text"}
              variant={"outlined"}
            />
          </Grid>

          <Grid item className={classes.fieldWrapper}>
            <Field
              name={"contactNumber"}
              label={"Contact Number"}
              component={TextInput}
              type={"text"}
              variant={"outlined"}
            />
          </Grid>

          {error && (
            <Grid item style={{ marginTop: "2em" }}>
              <Typography
                variant={"subtitle1"}
                style={{
                  color: theme.palette.error.main,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item container style={{ marginTop: "0.5em" }}>
            <Grid item>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => closeForm()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "editProfile",
    enableReinitialize: true,
    validate,
  })(EditProfile)
);
