import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { format } from "date-fns";
import MonthlyPipelineItem from "./MonthlyPipelineItem";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1em",
  },
  itemContainer: {
    marginTop: "1.5em",
  },
  title: {
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
}));

const MonthlyRentalPipeline = ({ values, loading }) => {
  const classes = useStyles();
  const isoDate = new Date();
  const month = format(isoDate, "MMMM");

  return (
    <Grid container direction={"column"} className={classes.container}>
      <Grid item>
        <Typography variant={"body2"} className={classes.title}>
          Rental pipeline for {month}
        </Typography>
      </Grid>
      <Grid item container className={classes.itemContainer}>
        <MonthlyPipelineItem
          inquiries={"QUOTES"}
          value={values?.quotes ? values?.quotes : 0}
          loading={loading}
        />
        <MonthlyPipelineItem
          inquiries={"ORDERS"}
          value={values?.orders ? values?.orders : 0}
          loading={loading}
        />
        <MonthlyPipelineItem
          inquiries={"ORDER VALUE"}
          value={values?.currentMonthRevenue ? values?.currentMonthRevenue : 0}
          dollar={true}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default MonthlyRentalPipeline;
