import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import asyncReducer from "../user/reducers/asyncReducers/asyncReducers";

import dialogReducer from "../user/reducers/dialogReducer/dialogReducer";
import contactsReducer from "../user/reducers/contactsReducer/contactsReducer";
import inventoryReducers from "../user/reducers/inventoryReducers/inventoryReducers";
import categoryReducers from "../user/reducers/categoryReducers/categoryReducers";
import transactionReducer from "../user/reducers/transactionReducers/transactionReducer";

import dashboardReducer from "../user/reducers/dashboardReducers/dashboardReducer";

import opportunityReducer from "../user/reducers/opportunityReducers/opportunityReducer";
import appAdminReducers from "../appAdmin/reducers/appAdminReducers/appAdminReducers";
import currentOrganizationReducer from "../user/reducers/currentOrganizationReducer/currentOrganizationReducer";
import organizationUserReducer from "../organizationAdmin/reducers/organizationUserReducers/organizationUserReducer";
import snackbarReducer from "../user/reducers/snackbarReducer/snackbarReducer";
import reportsReducer from "../user/reducers/reportsReducers/reportsReducer";
import organizationTemplateReducer
  from "../organizationAdmin/reducers/organizationTemplateReducer/organizationTemplateReducer";
import appAdminTemplateReducer from "../appAdmin/reducers/appAdminTemplateReducer/appAdminTemplateReducer";
import appAdminAdminsReducer from "../appAdmin/reducers/appAdminAdminReducers/appAdminAdminsReducer";

const rootReducer = combineReducers({
  // ASYNC
  loading: asyncReducer,

  // FIREBASE/FIRESTORE
  firebase: firebaseReducer,
  firestore: firestoreReducer,

  // REDUX FORM REDUCER
  form: formReducer,

  // DIALOG REDUCER
  dialog: dialogReducer,

  // SNACKBAR REDUCER
  snackbar: snackbarReducer,

  // DASHBOARD REDUCER
  dashboard: dashboardReducer,

  // CONTACTS REDUCER
  contacts: contactsReducer,

  // INVENTORY REDUCER
  inventory: inventoryReducers,

  // REPORTS REDUCER
  reports: reportsReducer,

  // CATEGORY REDUCER
  categories: categoryReducers,

  // TRANSACTIONS REDUCER
  transactions: transactionReducer,

  // OPPORTUNITIES
  opportunities: opportunityReducer,

  // USER ORGANIZATION
  currentOrganization: currentOrganizationReducer,

  // ORGANIZATION ADMIN REDUCERS
  organizationUsers: organizationUserReducer,

  organizationTemplates: organizationTemplateReducer,

  // APP ADMIN REDUCERS
  organizations: appAdminReducers,
  appTemplates: appAdminTemplateReducer,
  appAdmins: appAdminAdminsReducer
});

export default rootReducer;
