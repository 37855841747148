import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import Button from "@material-ui/core/Button";
import TextInput from "../../../common/form/TextInput";

import { addNewSubCategory } from "../../../store/user/actions/categoryActions/categoryActions";
import SelectInput from "../../../common/form/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const validate = combineValidators({
  mainCategory: isRequired({ message: "Select Main Category" }),
  subCategory: isRequired({ message: "Sub Category is required" }),
});

const actions = {
  addNewSubCategory,
  reset,
};

const mapStateToProps = (state) => {
  let category = [];
  let mainCategories = [];

  if (state.form.addSubCategory) {
    category = state.form.addSubCategory;
  }

  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    mainCategories = state.categories.mainCategories;
  }
  return {
    category,
    mainCategories,
  };
};

const AddSubCategory = ({
  addNewSubCategory,
  handleSubmit,
  mainCategories,
  reset,
  submitting,
}) => {
  const classes = useStyles();
  const [showSubCategory, setShowSubCategory] = useState(false);

  const handleAddSubCategory = async (values) => {
    try {
      await addNewSubCategory(values);
      reset("addSubCategory");
      setShowSubCategory(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction={"column"}>
      <Grid item container direction={"column"}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleAddSubCategory)}
        >
          {/*BUTTON/INPUT CONTAINER*/}

          <Grid item container alignItems={"center"} spacing={1}>
            <Grid
              item
              lg={8}
              md={8}
              sm={8}
              xs={8}
              className={classes.fieldWrapper}
            >
              <Field
                name={"mainCategory"}
                label={"Main Category"}
                component={SelectInput}
                type={"text"}
                variant={"outlined"}
              >
                {mainCategories.map((category) => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                    onClick={() => setShowSubCategory(true)}
                  >
                    {category.mainCategory}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>

          {showSubCategory && (
            <Grid
              item
              container
              alignItems={"center"}
              className={classes.fieldWrapper}
              spacing={1}
            >
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Field
                  name={"subCategory"}
                  label={"Sub Category Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  color={"primary"}
                  className={classes.button}
                  type={"submit"}
                  disabled={submitting}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "addSubCategory", validate })(AddSubCategory));
