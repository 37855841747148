import { createMuiTheme } from "@material-ui/core/styles";

const themeMain = "#53B647";
const themeSecondary = "#f50057";
const themeBlue = "#3f51b5";
const text = "black";

const availableGreen = "#82BC00";
const checkAvailability = "#ff9933";
const notAvailableRed = "#ff0000";

const captionGrey = "#999999";

const calendarSetup = "#bae2b6";
const calendarEvent = "#66c2ff";
const calendarPickup = "#ffc299";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeMain,
    },
    secondary: {
      main: themeSecondary,
    },
    themeBlue: themeBlue,
    available: availableGreen,
    caution: checkAvailability,
    notAvailable: notAvailableRed,
    calendarSetup: calendarSetup,
    calendarEvent: calendarEvent,
    calendarPickup: calendarPickup,
    captionGrey: captionGrey
  },
  typography: {
    tab: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: "500",
      fontSize: "1rem",
    },
    h1: {
      color: text,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "5rem",
    },
    h3: {
      color: text,
      fontFamily: "Raleway",
      fontWeight: 400,
      fontSize: "3rem",
    },
    h5: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "2rem",
    },
    h6: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "1.5rem",
    },
    body1: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "1.2rem",
    },
    body2: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "1.1rem",
    },
    subtitle1: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "0.9rem",
    },
    subtitle2: {
      color: text,
      fontFamily: "Raleway",
      fontSize: "0.75rem",
    },
    caption: {
      color: captionGrey,
      fontFamily: "Raleway",
      fontSize: "0.8rem",
    },
  },
});

export default theme;
