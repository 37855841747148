import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import LiveSearch from "../../ui/search/LiveSearch";

import { connect } from "react-redux";

import { fetchAllOpportunities } from "../../store/user/actions/opportunityActions/opportunityActions";
import OpportunityTable from "../../ui/opportunities/OpportunityTable";
import Loading from "../../ui/loading/Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllOpportunities,
};

const mapStateToProps = (state) => {
  let opportunities = [];

  if (
    state.opportunities.allOpportunities &&
    state.opportunities.allOpportunities.length > 0
  ) {
    opportunities = state.opportunities.allOpportunities;
  }
  return {
    opportunities,
    loading: state.loading.loading
  };
};

const Archived = ({ fetchAllOpportunities, opportunities, loading }) => {
  const classes = useStyles();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllOpportunities("archived");
  }, [fetchAllOpportunities]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Archived</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <LiveSearch
            data={opportunities}
            setSearchResults={setSearchResults}
          />
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        {loading && (
            <Loading/>
        )}
        {!loading && (
            <OpportunityTable
                type={"archived"}
                directory={"archived"}
                rows={
                  searchResults < 1
                      ? opportunities
                      : opportunities.filter((contact) => contact.search)
                }
            />
        )}

      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Archived);
