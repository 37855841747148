import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  deleteMainCategory,
  fetchMainCategory,
} from "../../../store/user/actions/categoryActions/categoryActions";
import { connect } from "react-redux";
import EditCategory from "./EditMainCategory";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  button: {
    margin: "0.4em",
  },
}));

const actions = {
  deleteMainCategory,
  fetchMainCategory,
};

const CategoryListItem = ({
  category,
  deleteMainCategory,
  fetchMainCategory,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [update, setUpdate] = useState(false);

  const handleUpdated = () => {
    setUpdate(false);
  };

  return (
    <Grid container className={classes.container} direction={"column"}>
      {!update && (
        <Grid item container alignItems={"center"}>
          <Grid item>
            <Typography variant={"subtitle1"}>
              {category.mainCategory}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Grid item container>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{
                    backgroundColor: theme.palette.themeBlue,
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={() => {
                    fetchMainCategory(category.id);
                    setUpdate(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={() => deleteMainCategory(category.id)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {update && <EditCategory category={category} setUpdate={handleUpdated} />}
    </Grid>
  );
};

export default connect(null, actions)(CategoryListItem);
