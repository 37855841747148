import { createReducer } from "../../../../common/util/reducerUtil";
import {
  SNACKBAR_CLOSE,
  SNACKBAR_OPEN,
} from "../../constants/snackbarConstants/snackbarConstants";

const initialState = {
  snackbar: null,
};

const openSnackbar = (state, payload) => {
  return {
    ...state,
    snackbar: {
      snackbarType: payload.snackbarType,
      snackbarProps: payload.snackbarProps,
    },
  };
};

const closeSnackbar = (state) => {
  return {
    ...state,
    snackbar: null,
  };
};

export default createReducer(initialState, {
  [SNACKBAR_OPEN]: openSnackbar,
  [SNACKBAR_CLOSE]: closeSnackbar,
});
