import React, { useEffect, useState } from "react";

import { Switch, Route, useHistory } from "react-router-dom";

// COMPONENT IMPORTS
import UserHeader from "./ui/headers/userHeader/UserHeader";
import Dashboard from "./pages/dashboard/Dashboard";

import Authenticate from "./Authenticate";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Contacts from "./pages/contacts/Contacts";
import AddContact from "./pages/contacts/AddContact";
import EditContact from "./pages/contacts/EditContact";
import ViewContact from "./pages/contacts/ViewContact";
import Inventory from "./pages/inventory/Inventory";
import AddInventory from "./pages/inventory/AddProduct";
import ViewProduct from "./pages/inventory/ViewProduct";
import EditProduct from "./pages/inventory/EditProduct";
import Settings from "./pages/settings/Settings";

import MainDialogManager from "./dialogs/MainDialogManager";

import Quotes from "./pages/quotes/Quotes";
import ViewQuote from "./pages/quotes/ViewQuote";
import EditQuote from "./pages/quotes/EditQuote";
import ViewOrder from "./pages/orders/ViewOrder";
import Orders from "./pages/orders/Orders";
import EditOrder from "./pages/orders/EditOrder";
import AppAdminDashboard from "./pages/appAdmin/dashboard/AppAdminDashboard";
import AppAdminHeader from "./ui/headers/appAdminHeader/AppAdminHeader";
import AppAdminOrganizations from "./pages/appAdmin/organizations/AppAdminOrganizations";
import AppAdminAddOrganization from "./pages/appAdmin/organizations/AppAdminAddOrganization";
import { fetchCurrentOrganization } from "./store/user/actions/currentOrganizationActions/currentOrganizationActions";
import AddQuote from "./pages/quotes/AddQuote";
import Archived from "./pages/archived/Archived";
import ViewArchived from "./pages/archived/ViewArchived";
import AddOrder from "./pages/orders/AddOrder";
import SnackbarManager from "./snackbars/SnackbarManager";
import Reports from "./pages/reports/Reports";
import AppAdminSettings from "./pages/appAdmin/settings/AppAdminSettings";
import {openDialog} from "./store/user/actions/dialogActions/dialogActions";

const actions = {
  fetchCurrentOrganization,
  openDialog
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const App = ({ auth, profile, openDialog, fetchCurrentOrganization }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [appAdmin, setAppAdmin] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // USER ROUTES
    if (auth.isLoaded === true && auth.isEmpty === false) {
      setAuthenticated(true);
      setAppAdmin(false);
      if (profile.passwordReset === false) {
        openDialog('ChangePasswordDialog', {profile})
      }
    }
    if (auth.isLoaded === true && auth.isEmpty === true) {
      setAuthenticated(false);
      setAppAdmin(false);
      history.push("/");
    }

    // ADMIN ROUTES
    if (auth.isLoaded === true && auth.isEmpty === false) {
      if (
        profile.isLoaded === true &&
        profile.isEmpty === false &&
        profile.isAppAdmin === true
      ) {
        setAuthenticated(true);
        setAppAdmin(true);
        history.push("/admin/dashboard");
        if (profile.passwordReset === false) {
          openDialog('ChangePasswordDialog', {profile})
        }
      }
    }
  }, [
    auth,
    history,
    profile,
    fetchCurrentOrganization,
    setAuthenticated,
    setAppAdmin,
      openDialog
  ]);

  return (
    <Grid container direction={"column"}>
      {authenticated && !appAdmin && <UserHeader />}
      {authenticated && appAdmin && <AppAdminHeader />}

      {!authenticated && <Authenticate />}

      {/*DIALOG*/}
      <MainDialogManager />

      {/*SNACKBAR*/}
      <SnackbarManager />

      {authenticated && (
        <Switch>
          {/*DASHBOARD*/}
          <Route exact path={"/"}>
            <Dashboard />
          </Route>
          <Route exact path={"/dashboard"}>
            <Dashboard />
          </Route>

          {/*CONTACTS*/}
          <Route exact path={"/contacts"}>
            <Contacts />
          </Route>
          <Route exact path={"/contacts/new"}>
            <AddContact />
          </Route>
          <Route exact path={"/contacts/edit"}>
            <EditContact />
          </Route>
          <Route exact path={"/contacts/view"}>
            <ViewContact />
          </Route>

          {/*QUOTES*/}
          <Route exact path={"/quotes"}>
            <Quotes />
          </Route>
          <Route exact path={"/quotes/new"}>
            <AddQuote />
          </Route>
          <Route exact path={"/quotes/view"}>
            <ViewQuote />
          </Route>
          <Route exact path={"/quotes/edit"}>
            <EditQuote />
          </Route>

          {/*ORDERS*/}
          <Route exact path={"/orders"}>
            <Orders />
          </Route>
          <Route exact path={"/orders/new"}>
            <AddOrder />
          </Route>
          <Route exact path={"/orders/view"}>
            <ViewOrder />
          </Route>
          <Route exact path={"/orders/edit"}>
            <EditOrder />
          </Route>

          {/*ARCHIVE*/}
          <Route exact path={"/archived"}>
            <Archived />
          </Route>
          <Route exact path={"/archived/view"}>
            <ViewArchived />
          </Route>

          {/*REPORTS*/}
          <Route exact path={"/reports"}>
            <Reports />
          </Route>

          {/*INVENTORY*/}
          <Route exact path={"/inventory"}>
            <Inventory />
          </Route>
          <Route exact path={"/inventory/new"}>
            <AddInventory />
          </Route>
          <Route exact path={"/inventory/view"}>
            <ViewProduct />
          </Route>
          <Route exact path={"/inventory/edit"}>
            <EditProduct />
          </Route>

          {/*SETTINGS*/}
          <Route path={"/settings"}>
            <Settings />
          </Route>
        </Switch>
      )}

      {/*APP ADMIN*/}
      {appAdmin && (
        <Switch>
          {/*/!*DASHBOARD*!/*/}
          <Route exact path={"/admin/dashboard"}>
            <AppAdminDashboard />
          </Route>
          {/*ORGANIZATIONS*/}
          <Route exact path={"/admin/organizations"}>
            <AppAdminOrganizations />
          </Route>
          <Route exact path={"/admin/organizations/new"}>
            <AppAdminAddOrganization />
          </Route>
          <Route path={"/admin/settings"}>
            <AppAdminSettings/>
          </Route>
        </Switch>
      )}
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(App);
