import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_OPPORTUNITIES,
  FETCH_OPPORTUNITY,
} from "../../constants/opportunityConstants/opportunityConstants";

const initialState = {
  allOpportunities: [],
  opportunity: [],
};

const getAllOpportunities = (state, payload) => {
  return {
    ...state,
    allOpportunities: payload.allOpportunities,
  };
};

const getOpportunity = (state, payload) => {
  return {
    ...state,
    opportunity: payload.opportunity,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_OPPORTUNITIES]: getAllOpportunities,
  [FETCH_OPPORTUNITY]: getOpportunity,
});
