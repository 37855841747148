import {
  SNACKBAR_CLOSE,
  SNACKBAR_OPEN,
} from "../../constants/snackbarConstants/snackbarConstants";

export const openSnackbar = (snackbarType, snackbarProps) => {
  return {
    type: SNACKBAR_OPEN,
    payload: {
      snackbarType,
      snackbarProps,
    },
  };
};

export const closeSnackbar = () => {
  return {
    type: SNACKBAR_CLOSE,
  };
};
