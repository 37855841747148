import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";

import axios from "../../../../config/axios";

export const emailQuote = (contactId, opportunityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const organizationId = state.currentOrganization.organization[0].id;

    try {
      dispatch(asyncActionStart());

      const sendEmail = axios
          .get("/appUserEmail/emailQuote", {
            params: {
              contactId: contactId,
              opportunityId: opportunityId,
              organizationId: organizationId,
            }
          })

      return sendEmail

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const saveQuote = (contactId, opportunityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const organizationId = state.currentOrganization.organization[0].id;

    try {
      dispatch(asyncActionStart());

      const saveQuote = axios
          .get("/appUserSave/saveQuote", {
            params: {
              contactId: contactId,
              opportunityId: opportunityId,
              organizationId: organizationId,
            },
            responseType: 'blob'
          })

      return saveQuote

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};


// ORDERS
export const emailOrder = (contactId, opportunityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const organizationId = state.currentOrganization.organization[0].id;

    try {
      dispatch(asyncActionStart());

      const sendEmail = axios
          .get("/appUserEmail/emailOrder", {
            params: {
              contactId: contactId,
              opportunityId: opportunityId,
              organizationId: organizationId,
            },
          })

      return sendEmail

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const saveOrder = (contactId, opportunityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const state = getState();
    const organizationId = state.currentOrganization.organization[0].id;

    try {
      dispatch(asyncActionStart());

      const saveQuote = axios
          .get("/appUserSave/saveOrder", {
            params: {
              contactId: contactId,
              opportunityId: opportunityId,
              organizationId: organizationId,
            },
            responseType: 'blob'
          })

      return saveQuote

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};