import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import LiveSearch from "../../ui/search/LiveSearch";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from '@material-ui/icons/FilterList';

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import { fetchAllInventory } from "../../store/user/actions/inventoryActions/inventoryActions";
import InventoryItem from "../../ui/inventory/InventoryItem";

import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import { fetchAllMainCategories } from "../../store/user/actions/categoryActions/categoryActions";
import { openDialog } from "../../store/user/actions/dialogActions/dialogActions";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },
}));

const actions = {
  fetchAllInventory,
  fetchAllMainCategories,
  openDialog,
};

const mapStateToProps = (state) => {
  let inventory = [];
  let categories = [];

  if (state.inventory.allInventory && state.inventory.allInventory.length > 0) {
    inventory = state.inventory.allInventory;
  }

  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    categories = state.categories.mainCategories;
  }

  return {
    inventory,
    categories,
  };
};

const Inventory = ({
  fetchAllInventory,
  fetchAllMainCategories,
  inventory,
  categories,
  openDialog,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);
  const [categoryResults, setCategoryResults] = useState([]);

  const [filteredInventory, setFilteredInventory] = useState(false);

  const [filterButton, setFilterButton] = useState('name')

  const handleSelectedCategory = (value) => {
    if (value === "allCategories") {
      setCategoryResults([]);

      setFilteredInventory(false);
    } else {
      setCategoryResults(
        inventory.filter((inventory) => inventory.mainCategory === value)
      );
      setFilteredInventory(true);
    }
  };

  const handleGetInventory = (value) => {
    fetchAllInventory(null, value)
  }

  useEffect(() => {
    fetchAllInventory(null, 'name');
    fetchAllMainCategories();
  }, [fetchAllInventory, fetchAllMainCategories]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Inventory</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid item container direction={matchesSM ? "column" : "row"}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={matchesSM ? null : { paddingRight: "0.5em" }}
            >
              <LiveSearch
                disabled={filteredInventory}
                data={inventory}
                setSearchResults={setSearchResults}
              />
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={
                matchesSM ? { marginTop: "1em" } : { paddingLeft: "0.5em" }
              }
            >
              <TextField
                fullWidth
                select
                name={"filterCategory"}
                variant={"outlined"}
                label={"Filter By Category"}
                size={"small"}
                defaultValue={"allCategories"}
                onChange={(e) => handleSelectedCategory(e.target.value)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                <MenuItem value={"allCategories"}>All Categories</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.mainCategory}>
                    {category.mainCategory}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          style={matchesMD ? { marginTop: "1em" } : null}
        >
          <Grid item container justify={matchesMD ? "center" : "flex-end"}>
            <Grid
              item
              className={classes.buttonWrapper}
              style={{ marginRight: "0.5em" }}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={matchesXS ? null : <AddIcon />}
                onClick={() => openDialog("AddMainCategoryDialog")}
              >
                Add Category
              </Button>
            </Grid>
            <Grid
              item
              className={classes.buttonWrapper}
              style={{ marginRight: "0.5em" }}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={matchesXS ? null : <AddIcon />}
                onClick={() => openDialog("AddSubCategoryDialog")}
              >
                Add Sub Category
              </Button>
            </Grid>

            <Grid
              item
              className={classes.buttonWrapper}
              style={matchesXS ? { marginTop: "1em" } : null}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={matchesXS ? null : <AddIcon />}
                onClick={() => history.push("/inventory/new")}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/*SORT BUTTONS*/}
        <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            style={matchesMD ? { marginTop: "1em" } : { marginTop: "0.5em" }}
        >
          <Grid item container justify={matchesMD ? "center" : "flex-start"}>
            <Grid
                item
                className={classes.buttonWrapper}
                style={{ marginRight: "0.5em" }}
            >
              <Button
                  variant={"contained"}
                  style={filterButton === 'name' ? {backgroundColor: theme.palette.primary.main, color: 'white'} : {backgroundColor: theme.palette.captionGrey}}
                  size={"medium"}
                  startIcon={matchesXS ? null : <FilterListIcon />}
                  onClick={() => {setFilterButton('name'); handleGetInventory('name')}}
              >
                Name
              </Button>
            </Grid>
            <Grid
                item
                className={classes.buttonWrapper}
                style={{ marginRight: "0.5em" }}
            >
              <Button
                  variant={"contained"}
                  style={filterButton === 'mainCategory' ? {backgroundColor: theme.palette.primary.main, color: 'white'} : {backgroundColor: theme.palette.captionGrey}}
                  size={"medium"}
                  startIcon={matchesXS ? null : <FilterListIcon />}
                  onClick={() => {setFilterButton('mainCategory'); handleGetInventory('mainCategory')}}
              >
                Main Category
              </Button>
            </Grid>

            <Grid
                item
                className={classes.buttonWrapper}
                style={matchesXS ? { marginTop: "1em" } : null}
            >
              <Button
                  variant={"contained"}
                  style={filterButton === 'subCategory' ? {backgroundColor: theme.palette.primary.main, color: 'white'} : {backgroundColor: theme.palette.captionGrey}}
                  size={"medium"}
                  startIcon={matchesXS ? null : <FilterListIcon />}
                  onClick={() => {setFilterButton('subCategory'); handleGetInventory('subCategory')}}
              >
                Sub Category
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={2} style={{ marginTop: "1.5em" }}>
        {/*SEARCH RESULTS*/}
        {!filteredInventory && searchResults < 1
          ? inventory.map((item) => <InventoryItem key={item.id} item={item} />)
          : inventory
              .filter((inventory) => inventory.search)
              .map((item) => <InventoryItem key={item.id} item={item} />)}

        {/*CATEGORY RESULTS*/}
        {filteredInventory &&
          categoryResults.map((item) => (
            <InventoryItem key={item.id} item={item} />
          ))}
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Inventory);
