import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "10px",
  },
  tableCell: {
    fontSize: "1.4em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  },
  paginationText: {
    fontSize: "1.4em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9em",
    },
  },
}));

const ReportTable = ({ rows }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      {rows.length === 0 && (
        <Grid item container justify={"center"} style={{ marginTop: "2em" }}>
          <Grid item>
            <Typography variant={"body1"} className={classes.text}>
              Select a range of dates to generate report!
            </Typography>
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Product Name
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    Main Category
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    Sub Category
                  </TableCell>

                  {!matchesSM && (
                    <TableCell className={classes.tableCell}>
                      Description
                    </TableCell>
                  )}

                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    Total Rentals
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.tableCell}>
                        {row.name}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        {row.mainCategory}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        {row.subCategory}
                      </TableCell>
                      {!matchesSM && (
                        <TableCell className={classes.tableCell}>
                          {row.description}
                        </TableCell>
                      )}

                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        {row.transactionTotal}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{
              caption: classes.paginationText,
              root: classes.paginationText,
            }}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Fragment>
  );
};

export default ReportTable;
