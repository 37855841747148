import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import { FETCH_DASHBOARD_VALUES } from "../../constants/dashboardConstants/dashboardConstants";
import { format } from "date-fns";

export const fetchDashboardValues = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const isoDate = new Date();
    const currentMonth = format(isoDate, "MMMM y");


    // const inquiryQuery = firestore
    //   .collection("opportunities")
    //   .where("opportunityType", "==", "inquiry")
    //   .where("createdAt", "==", `${currentMonth}`)
    //   .where("organizationId", "==", `${organizationId}`);
    const quotesQuery = firestore
      .collection("opportunities")
      .where("opportunityType", "==", "quote")
      .where("createdAt", "==", `${currentMonth}`)
      .where("organizationId", "==", `${organizationId}`);
    const orderQuery = firestore
      .collection("opportunities")
      .where("opportunityType", "==", "order")
      .where("createdAt", "==", `${currentMonth}`)
      .where("organizationId", "==", `${organizationId}`);
    const currentMonthRevenueQuery = firestore
      .collection("opportunities")
      .where("opportunityType", "in", ["order", "archived"])
      .where("createdAt", "==", `${currentMonth}`)
      .where("organizationId", "==", `${organizationId}`);

    const activityQuery = firestore
      .collection("opportunities")
      .where("opportunityType", "==", "order")
      .where("organizationId", "==", `${organizationId}`);

    try {
      dispatch(asyncActionStart());
      // let inquiryCount = await inquiryQuery.get();
      let quotesCount = await quotesQuery.get();
      let ordersCount = await orderQuery.get();
      let currentMonthRevenue = await currentMonthRevenueQuery.get();
      let activityCount = await activityQuery.get();

      let currentMonthTotalRevenue = 0;

      for (let i = 0; i < currentMonthRevenue.docs.length; i++) {
        if (currentMonthRevenue.docs[i].data().totalCost) {
          currentMonthTotalRevenue =
              currentMonthTotalRevenue +
              (currentMonthRevenue.docs[i].data().totalCost *
              currentMonthRevenue.docs[i].data().totalDays);
        } else {
          currentMonthTotalRevenue =
              currentMonthTotalRevenue +
              (0 *
              currentMonthRevenue.docs[i].data().totalDays);
        }

      }

      let activity = [];

      for (let i = 0; i < activityCount.docs.length; i++) {
        let opportunity = {
          ...activityCount.docs[i].data(),
          id: activityCount.docs[i].id,
        };
        activity.push(opportunity);
      }

      const todayDate = format(new Date(), "yyyy-M-dd");

      let values = [
        {
          // inquiries: inquiryCount.docs.length,
          quotes: quotesCount.docs.length,
          orders: ordersCount.docs.length,
          currentMonthRevenue: currentMonthTotalRevenue,
          rentalsToday: activity.filter(
            (opportunity) =>
              format(new Date(opportunity.eventStartDate), "yyyy-M-dd") ===
              todayDate
          ),
          deliveriesToday: activity.filter(
            (opportunity) =>
              format(new Date(opportunity.setupDate), "yyyy-M-dd") === todayDate
          ),
          pickupsToday: activity.filter(
            (opportunity) =>
              format(new Date(opportunity.pickupDate), "yyyy-M-dd") ===
              todayDate
          ),
          allActivity: activity,
        },
      ];

      dispatch({
        type: FETCH_DASHBOARD_VALUES,
        payload: { values },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
