import React from "react";

import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { closeSnackbar } from "../../store/user/actions/snackbarActions/snackbarActions";

const actions = {
  closeSnackbar,
};

const SuccessSnackbar = ({ closeSnackbar, message }) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    closeSnackbar();
  };

  return (
    <Snackbar open={true} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default connect(null, actions)(SuccessSnackbar);
