import { createReducer } from "../../../../common/util/reducerUtil";
import {FETCH_ALL_APP_ADMINS, FETCH_APP_ADMIN} from "../../constants/appAdminAdminsConstants/appAdminAdminsConstants";


const initialState = {
  allAppAdmins: [],
  appAdmin: [],
};

const allAppAdmins = (state, payload) => {
  return {
    ...state,
    allAppAdmins: payload.appAdmins,
  };
};

const getAppAdmin = (state, payload) => {
  return {
    ...state,
    appAdmin: payload.appAdmin,
  };
};


export default createReducer(initialState, {
  [FETCH_ALL_APP_ADMINS]: allAppAdmins,
  [FETCH_APP_ADMIN]: getAppAdmin,
});
