import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteContact,
  fetchContact,
} from "../../store/user/actions/contactsActions/contactsActions";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1.5em",
  },
  button: {
    margin: "0.4em",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
    },
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
}));

const actions = {
  fetchContact,
  deleteContact,
};

const mapStateToProps = (state) => {
  let contact = {};

  if (state.contacts.contact && state.contacts.contact.length > 0) {
    contact = state.contacts.contact[0];
  }

  return {
    contact,
  };
};

const ViewContact = ({ fetchContact, deleteContact, contact }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [dialogOpen, setDialogOpen] = useState(false);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchContact(id);
  }, [id, fetchContact]);

  const handleDeleteContact = async () => {
    await deleteContact(id);
    history.push("/contacts");
    setDialogOpen(false);
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteContact()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/*DETAILS*/}
      <Grid
        item
        container
        direction={"column"}
        style={matchesXS ? { width: "90%" } : { width: "70%" }}
      >
        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item>
            <Typography variant={"h4"}>View Contact</Typography>
          </Grid>

          <Grid
            item
            style={
              matchesMD
                ? { marginTop: "1.5em" }
                : { marginLeft: "auto", marginTop: "1.5em" }
            }
          >
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/contacts/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/contacts")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction={"column"}
          alignItems={"center"}
          style={{ marginTop: "2em" }}
        >
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                Details
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                {contact.firstName + " " + contact.lastName}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                {contact.address1},{" "}
                {contact.address2 ? `${contact.address2},` : ""}
                {contact.city}, {contact.state}, {contact.zipCode}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                {contact.contactNumber}
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "1em" }}>
              <Typography variant={"body1"} className={classes.text}>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                {contact.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item style={{ marginTop: "2em", marginLeft: "auto" }}>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/contacts/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/contacts")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewContact);
