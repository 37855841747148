// TEMPLATES
import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../../user/actions/asyncActions/asyncActions";
import {
    FETCH_ALL_ORGANIZATION_TEMPLATES,
    FETCH_ORGANIZATION_TEMPLATE
} from "../../constants/organizationTemplateConstants/organizationTemplateConstants";


export const fetchAllOrganizationTemplates = () => {
    return async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();
        const state = getState();
        const organizationId = state.firebase.profile.organizationId;

        const templatesQuery = firestore
            .collection("templates")
            .orderBy("templateCategory", "asc")
            .where("organizationId", "==", `${organizationId}`);

        try {
            dispatch(asyncActionStart());
            let query = await templatesQuery.get();

            let allOrganizationTemplates = [];

            for (let i = 0; i < query.docs.length; i++) {
                let template = {
                    ...query.docs[i].data(),
                    id: query.docs[i].id,
                };
                allOrganizationTemplates.push(template);
            }

            dispatch({
                type: FETCH_ALL_ORGANIZATION_TEMPLATES,
                payload: { allOrganizationTemplates },
            });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const fetchOrganizationTemplate = (id) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        const templatesQuery = firestore.collection("templates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            let query = await templatesQuery.get();

            let organizationTemplate = [];

            if (query.exists) {
                let template = {
                    id: query.id,
                    ...query.data(),
                };
                organizationTemplate.push(template);
            }

            if (!query.exists) {
                return;
            }

            dispatch({
                type: FETCH_ORGANIZATION_TEMPLATE,
                payload: { organizationTemplate },
            });

            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError());
            console.log(error);
        }
    };
};

export const addOrganizationTemplate = (values, html) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const state = getState();
        const organizationId = state.firebase.profile.organizationId;

        const templateQuery = firestore.collection("templates");

        try {
            dispatch(asyncActionStart());

            const query = await templateQuery.add({
                ...values,
                html: html,
                organizationId: organizationId,
                templateActive: false,
            });

            dispatch(asyncActionFinish());

            return query;
        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const updateOrganizationTemplate = (id, values, html) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        // const state = getState();
        // const organizationId = state.firebase.profile.organizationId;

        const templateQuery = firestore.collection("templates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            const query = await templateQuery.update({
                ...values,
                html: html,
            });

            dispatch(asyncActionFinish());

            return query;
        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const toggleTemplateStatus = (id, category, value) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const state = getState();
        const organizationId = state.firebase.profile.organizationId;

        const templatesQuery = firestore
            .collection("templates")
            .where("organizationId", "==", `${organizationId}`).where('templateCategory', '==', `${category}`)

        const templateQuery = firestore.collection("templates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            // TEMPLATES QUERY
            let allTemplatesQuery = await templatesQuery.get();

            for (let i = 0; i < allTemplatesQuery.docs.length; i++) {
                const updateQuery = firestore.collection("templates").doc(`${allTemplatesQuery.docs[i].id}`)
                await updateQuery.update({templateActive: false})
            }


            await templateQuery.update({
                templateActive: !value
            });

            dispatch(fetchAllOrganizationTemplates())
            dispatch(asyncActionFinish());

        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};

export const deleteTemplate = (id) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        // const state = getState();

        const templateQuery = firestore.collection("templates").doc(`${id}`);

        try {
            dispatch(asyncActionStart());

            await templateQuery.delete()

            dispatch(fetchAllOrganizationTemplates())
            dispatch(asyncActionFinish());

        } catch (err) {
            dispatch(asyncActionError());
            console.log(err);
        }
    };
};