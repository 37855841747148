import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const AppAdminDashboard = ({ auth, profile }) => {
  const history = useHistory();

  const [appAdmin, setAppAdmin] = useState(false);

  useEffect(() => {
    if (auth.isLoaded === true && auth.isEmpty === false) {
      if (
        profile.isLoaded === true &&
        profile.isEmpty === false &&
        profile.isAppAdmin === true
      ) {
        setAppAdmin(true);
      }
      if (
        profile.isLoaded === true &&
        profile.isEmpty === false &&
        profile.isAppAdmin === false
      ) {
        setAppAdmin(false);
        history.push("/dashboard");
      }
    }
  }, [auth, profile, history, setAppAdmin]);

  return (
    <Grid container justify={"center"} direction={"column"}>
      APP ADMIN {appAdmin}
    </Grid>
  );
};

export default connect(mapStateToProps)(AppAdminDashboard);
