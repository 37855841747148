import React, { Fragment, useEffect, useState } from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteOrganizationUser,
  disableOrganizationUser,
  enableOrganizationUser,
  fetchOrganizationUser,
} from "../../../store/organizationAdmin/actions/organizationUserActions/organizationUserActions";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import profileImage from "../../../assets/avatar/avatar.png";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },

}));


const actions = {
  fetchOrganizationUser,
  disableOrganizationUser,
  enableOrganizationUser,
  deleteOrganizationUser,
};

const mapStateToProps = (state) => {
  let organizationUser = {};

  if (
    state.organizationUsers.organizationUser &&
    state.organizationUsers.organizationUser.length > 0
  ) {
    organizationUser = state.organizationUsers.organizationUser[0];
  }
  return {
    organizationUser,
    profile: state.firebase.profile,
  };
};

const ViewOrganizationUser = ({
  organizationUser,
  fetchOrganizationUser,
  disableOrganizationUser,
  enableOrganizationUser,
  deleteOrganizationUser,
  profile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [userToggle, setUserToggle] = useState(false);

  useEffect(() => {
    fetchOrganizationUser(id);
    if (userToggle) {
      fetchOrganizationUser(id);
    }
  }, [id, fetchOrganizationUser, userToggle]);

  const handleDisableOrganizationUser = async () => {
    setUserToggle(true);
    disableOrganizationUser(id);
    let disableUserQuery = await disableOrganizationUser(id);
    if (disableUserQuery.data === "User Disabled") {
      setUserToggle(false);
    }
  };
  const handleEnableOrganizationUser = async () => {
    setUserToggle(true);
    enableOrganizationUser(id);
    let enableUserQuery = await enableOrganizationUser(id);
    if (enableUserQuery.data === "User Enabled") {
      setUserToggle(false);
    }
  };

  const handleDeleteOrganizationUser = async () => {
    let deleteUserQuery = await deleteOrganizationUser(id);

    if (deleteUserQuery.data === "Deleted User") {
      setDialogOpen(false);
      history.push("/settings/users");
    }
  };

  return (
    <Fragment>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteOrganizationUser()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>


      <Grid item container direction={"column"} alignItems={"center"}>
        <Grid item>
          <Typography
              variant={"h5"}
              style={matchesXS ? { fontSize: "1.5em" } : null}
          >
            View Organization User
          </Typography>
        </Grid>

        <Grid item style={matchesSM ? null : { marginLeft: "auto", paddingRight: '3em' }}>

          <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => history.push("/settings/users")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>


      <Grid item container style={{ marginTop: "2em" }} >
        {!matchesSM && (
            <Grid item lg={2} md={2}>
              {/*BLANK*/}
            </Grid>
        )}
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid item style={{ marginBottom: "2em" }}>
            <Grid item container justify={"center"}>
              <Grid item>
                <img
                    src={profileImage}
                    alt={`${organizationUser.fullName} Profile`}
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item container direction={'column'} alignItems={'center'}>
            <Grid item>
              <Typography variant={"h5"}>
                {organizationUser.fullName}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                  variant={"h6"}
                  style={{ color: theme.palette.primary.main }}
              >
                {organizationUser.organizationAdmin
                    ? "Organization Admin"
                    : "Organization User"}
              </Typography>
            </Grid>

            <Grid item>
              <Grid item container direction={'column'}>
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography variant={"body1"}>
                    <span style={{ fontWeight: "bold" }}>Email: </span>
                    <a href={`mailto:${organizationUser.email}`}>
                      {organizationUser.email}
                    </a>{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"body1"}>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    {organizationUser.contactNumber}
                  </Typography>
                </Grid>


                <Grid item >
                  <Typography
                      variant={"body1"}
                      style={
                        organizationUser.disabled
                            ? { color: theme.palette.error.main }
                            : { color: theme.palette.primary.main }
                      }
                  >
                <span
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.common.black,
                    }}
                >
                  User Status:{" "}
                </span>
                    {organizationUser.disabled ? "Disabled" : "Active"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>





            <Grid item style={{ marginTop: "0.5em" }}>
              <Grid item container>
                <Grid item>
                  <Button
                      disabled={profile.uid === organizationUser.id}
                      color={organizationUser.disabled ? "primary" : "secondary"}
                      variant={"outlined"}
                      size={"small"}
                      style={
                        organizationUser.disabled
                            ? { color: "primary" }
                            : { color: "secondary" }
                      }
                      onClick={() =>
                          organizationUser.disabled
                              ? handleEnableOrganizationUser()
                              : handleDisableOrganizationUser()
                      }
                  >
                    {organizationUser.disabled ? "Enable User" : "Disable User"}
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: "0.5em" }}>
                  <Button
                      disabled={profile.uid === organizationUser.id}
                      color={"secondary"}
                      variant={"contained"}
                      size={"small"}
                      onClick={() => setDialogOpen(true)}
                  >
                    Delete User
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>









        </Grid>
      </Grid>


      <Grid
          item
          container
          alignItems={matchesSM ? "center" : null}
          direction={"column"}
          style={{ marginTop: "2em" }}
      >
        <Grid item style={matchesSM ? null : { marginLeft: "auto", paddingRight: '3em'  }}>
          <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => history.push("/settings/users")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>


    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(ViewOrganizationUser);
