import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import TemplatesTable from "../../../ui/settings/templates/TemplatesTable";
import {fetchAllOrganizationTemplates} from "../../../store/organizationAdmin/actions/organizationTemplateActions/organizationTemplateActions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllOrganizationTemplates,
};

const mapStateToProps = (state) => {
  let organizationTemplates = [];

  if (
    state.organizationTemplates.allOrganizationTemplates &&
    state.organizationTemplates.allOrganizationTemplates.length > 0
  ) {
    organizationTemplates =
      state.organizationTemplates.allOrganizationTemplates;
  }
  return {
    organizationTemplates,
  };
};

const OrganizationTemplates = ({
  fetchAllOrganizationTemplates,
  organizationTemplates,
  profile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  // const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllOrganizationTemplates();
  }, [fetchAllOrganizationTemplates]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Organization Templates</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {/*BLANK*/}
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={matchesSM ? { marginTop: "0.5em" } : null}
        >
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/settings/templates/new")}
              >
                Add Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <TemplatesTable
          rows={
            organizationTemplates
          }
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(OrganizationTemplates);
