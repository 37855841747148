import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";

import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TextInput from "../../../common/form/TextInput";
import SelectInput from "../../../common/form/SelectInput";

import { combineValidators, isRequired } from "revalidate";

import TextEditor from "../../../common/TextEditor/TextEditor";
import {addOrganizationTemplate} from "../../../store/organizationAdmin/actions/organizationTemplateActions/organizationTemplateActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  templateName: isRequired({ message: "Name is required" }),
  templateCategory: isRequired({ message: "Category is required" }),
});

const actions = {
  addOrganizationTemplate,
};

const AddOrganizationTemplate = ({
  addOrganizationTemplate,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();

  const initialValue = "";

  const [editorValue, setEditorValue] = useState(false);

  const handleChange = (content, editor) => {
    setEditorValue(content);
  };

  useEffect(() => {
    setEditorValue(initialValue ? initialValue : "");
  }, [initialValue]);

  const handleAddOrganizationTemplate = async (values) => {
    await addOrganizationTemplate(values, editorValue);
    history.push("/settings/templates");
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleAddOrganizationTemplate)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography
                variant={"h5"}
                style={matchesXS ? { fontSize: "1.5em" } : null}
              >
                Add Organization Template
              </Typography>
            </Grid>

            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/templates")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesSM && (
              <Grid item lg={2} md={2}>
                {/*BLANK*/}
              </Grid>
            )}

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"templateName"}
                  label={"Template Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item className={classes.fieldWrapper}>
                <Field
                    name={"templateDescription"}
                    label={"Template Description"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"templateCategory"}
                  label={"Template Category"}
                  component={SelectInput}
                  type={"text"}
                  variant={"outlined"}
                >
                  <MenuItem value={"newUser"}>New User</MenuItem>
                  <MenuItem value={"quotation"}>Quotation</MenuItem>
                  <MenuItem value={"order"}>Order</MenuItem>
                </Field>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesSM && (
              <Grid item lg={2} md={2}>
                {/*BLANK*/}
              </Grid>
            )}
            <Grid item lg={8} md={8} sm={12} xs={12}>
              MERGE TAGS HERE
            </Grid>
          </Grid>

          <Grid item container style={{ marginTop: "2em" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextEditor
                editorValue={editorValue}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            alignItems={matchesSM ? "center" : null}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/templates")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  actions
)(
  reduxForm({ form: "addOrganizationTemplate", validate })(
    AddOrganizationTemplate
  )
);
