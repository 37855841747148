import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import {
  deleteProduct,
  fetchInventoryProduct,
  fetchInventoryProductHistory,
} from "../../store/user/actions/inventoryActions/inventoryActions";
import CurrencyFormat from "react-currency-format";

import inventoryPlaceholder from "../../assets/placeholder/inventory/inventory.png";
import AvailabilityTable from "../../ui/inventory/AvailabilityTable";
import HistoryTable from "../../ui/inventory/HistoryTable";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1.5em",
  },
  button: {
    margin: "0.4em",
  },
  image: {
    width: "200px",
    height: "200px",
    borderRadius: "5px",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
}));

const actions = {
  fetchInventoryProduct,
  fetchInventoryProductHistory,
  deleteProduct,
};

const mapStateToProps = (state) => {
  let product = {};
  let productHistory = [];

  if (state.inventory.product && state.inventory.product.length > 0) {
    product = state.inventory.product[0];
  }

  if (
    state.inventory.productHistory &&
    state.inventory.productHistory.length > 0
  ) {
    productHistory = state.inventory.productHistory;
  }

  return {
    product,
    productHistory,
  };
};

const ViewProduct = ({
  product,
  productHistory,
  fetchInventoryProduct,
  fetchInventoryProductHistory,
  deleteProduct,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [dialogOpen, setDialogOpen] = useState(false);

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  useEffect(() => {
    fetchInventoryProduct(id);
    fetchInventoryProductHistory(id);
  }, [id, fetchInventoryProduct, fetchInventoryProductHistory]);

  const handleDeleteContact = async () => {
    await deleteProduct(id, product.photoName);
    history.push("/inventory");
    setDialogOpen(false);
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete {product?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteContact()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/*DETAILS*/}
      <Grid
        item
        container
        direction={"column"}
        style={matchesSM ? { width: "90%" } : { width: "70%" }}
      >
        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item>
            <Typography variant={"h4"}>{product?.name}</Typography>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            style={
              matchesMD
                ? { marginTop: "1.5em" }
                : { marginLeft: "auto", marginTop: "1.5em" }
            }
          >
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/inventory/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/inventory")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction={"column"}
          alignItems={"center"}
          style={{ marginTop: "2em" }}
        >
          <Grid item container direction={"column"}>
            {/*DETAILS*/}
            <Grid item>
              <Grid item container direction={matchesXS ? "column" : "row"}>
                <Grid item>
                  <Grid item container justify={matchesXS ? "center" : null}>
                    <img
                      src={
                        product?.photoURL
                          ? product?.photoURL
                          : inventoryPlaceholder
                      }
                      alt={
                        product?.name
                          ? `${product.name} photo`
                          : "inventory image placeholder"
                      }
                      className={classes.image}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={
                    matchesXS ? { marginTop: "1.5em" } : { marginLeft: "1em" }
                  }
                >
                  <Grid item container direction={"column"} spacing={1}>
                    <Grid item>
                      <Typography variant={"body1"} className={classes.text}>
                        <span
                          style={{
                            fontWeight: "500",
                            color: theme.palette.primary.main,
                          }}
                        >
                          Product Name:
                        </span>{" "}
                        {product?.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"body1"} className={classes.text}>
                        <span
                          style={{
                            fontWeight: "500",
                            color: theme.palette.primary.main,
                          }}
                        >
                          Total Owned:
                        </span>{" "}
                        {product?.totalOwned}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant={"body1"} className={classes.text}>
                        <span
                          style={{
                            fontWeight: "500",
                            color: theme.palette.primary.main,
                          }}
                        >
                          Rental Cost:
                        </span>{" "}
                        <CurrencyFormat
                          value={product?.rentalPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant={"body1"} className={classes.text}>
                        <span
                          style={{
                            fontWeight: "500",
                            color: theme.palette.primary.main,
                          }}
                        >
                          Replacement Cost:
                        </span>{" "}
                        <CurrencyFormat
                          value={product?.replacementCost}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*DESCRIPTION*/}
            <Grid item style={{ marginTop: "1em" }}>
              <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                  <Typography variant={"body1"} className={classes.text}>
                    <span
                      style={{
                        fontWeight: "500",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Description:
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"caption"} className={classes.text}>
                    {product?.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/*NOTES*/}
            <Grid item style={{ marginTop: "1em" }}>
              <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                  <Typography variant={"body1"} className={classes.text}>
                    <span
                      style={{
                        fontWeight: "500",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Notes:
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"body1"} className={classes.text}>
                    {product?.notes}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/*AVAILABILITY*/}
            <Grid item style={{ marginTop: "1em" }}>
              <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                  <Typography variant={"body1"} className={classes.text}>
                    <span
                      style={{
                        fontWeight: "500",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Availability:
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <AvailabilityTable product={product} />
                </Grid>
              </Grid>
            </Grid>

            {/*HISTORY*/}
            <Grid item style={{ marginTop: "1.5em" }}>
              <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                  <Typography variant={"body1"} className={classes.text}>
                    <span
                      style={{
                        fontWeight: "500",
                        color: theme.palette.primary.main,
                      }}
                    >
                      History:
                    </span>
                  </Typography>
                </Grid>

                <Grid item>
                  <HistoryTable productHistory={productHistory} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*BUTTON CONTAINER*/}
        <Grid item container direction={"column"} alignItems={"center"}>
          <Grid item style={{ marginTop: "2em", marginLeft: "auto" }}>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.themeBlue,
                color: theme.palette.themeBlue,
              }}
              className={classes.button}
              onClick={() => history.push(`/inventory/edit?id=${id}`)}
            >
              Edit
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              className={classes.button}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
            <Button
              size={"small"}
              variant={"outlined"}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
              }}
              className={classes.button}
              onClick={() => history.push("/inventory")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(ViewProduct);
