import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
    borderRadius: "5px",

    padding: "0.2em",
    marginTop: "1em",
    marginBottom: "1em",
    minHeight: "80px",
  },

  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8em",
    },
  },
  infoText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.5em",
    },
  },
}));

const ActivityItem = ({ activity, type }) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  return (
    <Grid
      item
      container
      direction={"column"}
      className={classes.container}
      style={
        type === "setup"
          ? { backgroundColor: theme.palette.calendarSetup }
          : type === "eventStart"
          ? { backgroundColor: theme.palette.calendarEvent }
          : type === "eventEnd"
          ? { backgroundColor: theme.palette.calendarEvent }
          : type === "pickup"
          ? { backgroundColor: theme.palette.calendarPickup }
          : null
      }
      onClick={() => history.push(`/orders/view?id=${activity.id}`)}
    >
      <Grid item>
        <Typography variant={"subtitle1"} className={classes.title}>
          {activity.title.substring(0, 14)}
        </Typography>
      </Grid>
      <Grid item>
        <Grid item container justify={"center"}>
          <Grid item>
            <Typography variant={"subtitle2"} className={classes.infoText}>
              {type === "setup" && "Setup - "}

              {type === "pickup" && "Pickup -"}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "3px" }}>
            {type === "setup" && (
              <Typography variant={"subtitle2"} className={classes.infoText}>
                {format(new Date(activity.setupDate), "MM/dd/YYY")}
              </Typography>
            )}
            {type === "pickup" && (
              <Typography variant={"subtitle2"} className={classes.infoText}>
                {format(new Date(activity.pickupDate), "MM/dd/YYY")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item container justify={"center"}>
          <Grid item>
            <Typography variant={"subtitle2"} className={classes.infoText}>
              {type === "setup" && "Event Start - "}

              {type === "pickup" && "Event End -"}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "3px" }}>
            {type === "setup" && (
              <Typography variant={"subtitle2"} className={classes.infoText}>
                {format(new Date(activity.eventStartDate), "MM/dd/YYY")}
              </Typography>
            )}
            {type === "pickup" && (
              <Typography variant={"subtitle2"} className={classes.infoText}>
                {format(new Date(activity.eventEndDate), "MM/dd/YYY")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityItem;
