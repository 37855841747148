import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: 300,
  },
}));

const CheckboxInput = ({
  input,
  label,
  checkboxClass,
  checkboxLabelClass,
  meta: { touched, error },
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color={"primary"}
            size={"small"}
            className={checkboxClass}
          />
        }
        className={checkboxLabelClass}
        label={label}
      />
      {touched && error && (
        <Typography variant={"subtitle1"} className={classes.error}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default CheckboxInput;
