import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import TextInput from "../../../common/form/TextInput";

import { updateMainCategory } from "../../../store/user/actions/categoryActions/categoryActions";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
}));

const validate = combineValidators({
  mainCategory: isRequired({ message: "Category Name is required" }),
});

const actions = {
  updateMainCategory,
};

const mapStateToProps = (state) => {
  let mainCategory = {};

  if (
    state.categories.mainCategory &&
    state.categories.mainCategory.length > 0
  ) {
    mainCategory = state.categories.mainCategory[0];
  }
  return {
    initialValues: state.categories.mainCategory[0],
    mainCategory,
  };
};

const EditMainCategory = ({
  updateMainCategory,
  category,
  handleSubmit,
  setUpdate,
  submitting,
}) => {
  const classes = useStyles();

  const handleUpdateMainCategory = async (values) => {
    try {
      await updateMainCategory(category.id, values);
      setUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction={"column"}>
      <Grid item container direction={"column"}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleUpdateMainCategory)}
        >
          {/*BUTTON/INPUT CONTAINER*/}

          <Grid item container alignItems={"center"} spacing={1}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Field
                name={"mainCategory"}
                label={"Category Name"}
                component={TextInput}
                type={"text"}
                variant={"outlined"}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editMainCategory",
    validate,
    enableReinitialize: true,
  })(EditMainCategory)
);
