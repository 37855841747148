import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { connect } from "react-redux";

import {
  openDialog,
  openSubDialog,
} from "../../store/user/actions/dialogActions/dialogActions";

import { fetchAllInventory } from "../../store/user/actions/inventoryActions/inventoryActions";
import LiveSearch from "../search/LiveSearch";
import { useTheme } from "@material-ui/styles";
import AddOpportunityItemTable from "./AddOpportunityItemTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableCell: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
  paginationText: {
    fontSize: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
  },
}));

const actions = {
  openDialog,
  openSubDialog,
  fetchAllInventory,
};

const mapStateToProps = (state) => {
  let inventory = [];

  if (state.inventory.allInventory && state.inventory.allInventory.length > 0) {
    inventory = state.inventory.allInventory;
  }

  return {
    inventory,
  };
};

const AddOpportunityItem = ({
  jobId,
  inventory,
  // fetchAllInventory,
  opportunity,
  type,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [searchResults, setSearchResults] = useState([]);

  // useEffect(() => {
  //   fetchAllInventory(opportunity, "name");
  // }, [fetchAllInventory, opportunity]);

  let filteredInventory = inventory.filter(function (array_el) {
    return (
      opportunity.items.filter(function (anotherOne_el) {
        return anotherOne_el.itemId === array_el.id;
      }).length === 0
    );
  });



  return (
    <Fragment>
      <LiveSearch
        data={filteredInventory}
        setSearchResults={setSearchResults}
      />
      <div style={{ marginBottom: "1.5em" }} />
      <AddOpportunityItemTable
        rows={
          searchResults < 1
            ? filteredInventory
            : filteredInventory.filter((contact) => contact.search)
        }
        opportunity={opportunity}
        jobId={jobId}
        type={type}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, actions)(AddOpportunityItem);
