import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import {
  deleteSubCategory,
  fetchSubCategory,
} from "../../../store/user/actions/categoryActions/categoryActions";
import EditSubCategory from "./EditSubCategory";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  button: {
    margin: "0.4em",
  },
}));

const actions = {
  deleteSubCategory,
  fetchSubCategory,
};

const SubCategoryListItem = ({
  subCategory,
  deleteSubCategory,
  fetchSubCategory,
  mainCategories,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [update, setUpdate] = useState(false);

  const handleUpdated = () => {
    setUpdate(false);
  };

  const mainCategory = mainCategories.filter(
    (category) => category.id === subCategory.mainCategory
  )[0];

  return (
    <Grid container className={classes.container} direction={"column"}>
      {!update && (
        <Grid item container alignItems={"center"}>
          <Grid item>
            <Grid item container direction={"column"}>
              <Grid item>
                <Grid item container alignItems={"center"}>
                  <Grid item>
                    <Typography
                      variant={"subtitle1"}
                      style={{ fontWeight: "bold" }}
                    >
                      Main Category:
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "0.5em" }}>
                    <Typography variant={"subtitle1"}>
                      {mainCategory?.mainCategory}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid item container alignItems={"center"}>
                  <Grid item>
                    <Typography
                      variant={"subtitle1"}
                      style={{ fontWeight: "bold" }}
                    >
                      Sub Category:
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "0.5em" }}>
                    <Typography variant={"subtitle1"}>
                      {subCategory.subCategory}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Grid item container>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{
                    backgroundColor: theme.palette.themeBlue,
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={() => {
                    fetchSubCategory(subCategory.id);
                    setUpdate(true);
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={() => deleteSubCategory(subCategory.id)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {update && (
        <EditSubCategory subCategory={subCategory} setUpdate={handleUpdated} />
      )}
    </Grid>
  );
};

export default connect(null, actions)(SubCategoryListItem);
