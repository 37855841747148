import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import {
  FETCH_ALL_MAIN_CATEGORIES,
  FETCH_ALL_SUB_CATEGORIES,
  FETCH_MAIN_CATEGORY,
  FETCH_SUB_CATEGORY,
} from "../../constants/categoryConstants/categoryConstants";
import { closeDialog } from "../dialogActions/dialogActions";

export const fetchAllMainCategories = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const categoriesQuery = firestore
      .collection("mainCategories")
      .orderBy("mainCategory", "asc")
      .where("organizationId", "==", `${organizationId}`);

    try {
      dispatch(asyncActionStart());
      let query = await categoriesQuery.get();

      let mainCategories = [];

      for (let i = 0; i < query.docs.length; i++) {
        let mainCategory = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
        };
        mainCategories.push(mainCategory);
      }

      dispatch({
        type: FETCH_ALL_MAIN_CATEGORIES,
        payload: { mainCategories },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchMainCategory = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const categoryQuery = firestore.collection("mainCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let query = await categoryQuery.get();

      let mainCategory = [];

      if (query.exists) {
        let cat = {
          id: query.id,
          ...query.data(),
        };
        mainCategory.push(cat);
      }

      if (!query.exists) {
        return;
      }

      dispatch({ type: FETCH_MAIN_CATEGORY, payload: { mainCategory } });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewMainCategory = (values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const categoryQuery = firestore.collection("mainCategories");

    try {
      dispatch(asyncActionStart());

      await categoryQuery.add({
        ...values,
        organizationId: organizationId,
      });

      dispatch(fetchAllMainCategories());
      dispatch(closeDialog());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateMainCategory = (id, values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const categoryQuery = firestore.collection("mainCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await categoryQuery.update({
        ...values,
      });

      dispatch(fetchAllMainCategories());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteMainCategory = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const categoryQuery = firestore.collection("mainCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await categoryQuery.delete();

      dispatch(fetchAllMainCategories());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

// SUB CATEGORIES
export const fetchAllSubCategories = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const subCategoriesQuery = firestore
      .collection("subCategories")
      .orderBy("subCategory", "asc")
      .where("organizationId", "==", `${organizationId}`);

    try {
      dispatch(asyncActionStart());
      let query = await subCategoriesQuery.get();

      let subCategories = [];

      for (let i = 0; i < query.docs.length; i++) {
        let subCategory = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
        };
        subCategories.push(subCategory);
      }

      dispatch({
        type: FETCH_ALL_SUB_CATEGORIES,
        payload: { subCategories },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewSubCategory = (values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const subCategoryQuery = firestore.collection("subCategories");

    try {
      dispatch(asyncActionStart());

      await subCategoryQuery.add({
        ...values,
        organizationId: organizationId,
      });

      dispatch(fetchAllSubCategories());
      dispatch(closeDialog());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchSubCategory = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const subCategoryQuery = firestore.collection("subCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let query = await subCategoryQuery.get();

      let subCategory = [];

      if (query.exists) {
        let category = {
          id: query.id,
          ...query.data(),
        };
        subCategory.push(category);
      }

      if (!query.exists) {
        return;
      }

      dispatch({ type: FETCH_SUB_CATEGORY, payload: { subCategory } });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateSubCategory = (id, values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const subCategoryQuery = firestore.collection("subCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await subCategoryQuery.update({
        ...values,
      });

      dispatch(fetchAllSubCategories());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteSubCategory = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const subCategory = firestore.collection("subCategories").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await subCategory.delete();

      dispatch(fetchAllSubCategories());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
