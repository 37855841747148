import { createReducer } from "../../../../common/util/reducerUtil";
import { FETCH_INVENTORY_REPORTS } from "../../constants/reportsConstants/reportsConstants";

const initialState = {
  inventoryReports: [],
};

const getInventoryReports = (state, payload) => {
  return {
    ...state,
    inventoryReports: payload.inventoryReports,
  };
};

export default createReducer(initialState, {
  [FETCH_INVENTORY_REPORTS]: getInventoryReports,
});
