import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  closeDialog,
  closeSubDialog, openDialog,
} from "../../store/user/actions/dialogActions/dialogActions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import TextInput from "../../common/form/TextInput";

import { addOpportunityItem } from "../../store/user/actions/opportunityActions/opportunityActions";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  button: {
    margin: "0.4em",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
}));

const actions = {
  closeSubDialog,
  closeDialog,
  addOpportunityItem,
};

const AddItemDialog = ({
  closeSubDialog,
  closeDialog,
  row,
  jobId,
  opportunity,
  addOpportunityItem,
  type,
  handleSubmit,
}) => {
  const classes = useStyles();

  const [error, serError] = useState("");

  const handleAddItem = async (values) => {
    if (values.qty > row.available) {
      serError(`Qty available is ${row.available}`);
    } else {
      await addOpportunityItem(row, jobId, opportunity, values, type);

      closeSubDialog();
      // closeDialog();

    }
  };

  const handleClose = () => {
    closeSubDialog();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{row?.name} Qty</DialogTitle>
      <DialogContent>
        <form autoComplete={"off"} onSubmit={handleSubmit(handleAddItem)}>
          <Grid item container alignItems={"center"}>
            <Grid item className={classes.fieldWrapper}>
              <Field
                name={"qty"}
                label={"Enter Qty"}
                component={TextInput}
                type={"text"}
                variant={"outlined"}
              />
            </Grid>

            <Grid item>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          {error && (
            <Grid item container>
              <Grid item>
                <Typography variant={"subtitle1"} className={classes.error}>
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeSubDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "addItem" })(AddItemDialog));
