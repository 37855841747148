import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "10px",
  },
  tableCell: {
    fontSize: "1.2em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  },
  paginationText: {
    fontSize: "1.2em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  },
}));

const HistoryTable = ({ productHistory }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} style={{ width: "20%" }}>
                Date
              </TableCell>
              <TableCell className={classes.tableCell}>Job Title</TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: "center" }}
              >
                Job Qty
              </TableCell>
              <TableCell className={classes.tableCell}>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {productHistory
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} className={classes.tableCell}>
                  <TableCell className={classes.tableCell}>
                    {row.date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.jobTitle}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    {row.jobQty}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.status === "order"
                      ? "Order"
                      : row.status === "quote"
                      ? "Quote"
                      : row.status === "archived"
                      ? "Archived"
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{
          caption: classes.paginationText,
          root: classes.paginationText,
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={productHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default HistoryTable;
