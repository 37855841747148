import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { deleteContact } from "../../../../store/user/actions/contactsActions/contactsActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  image: {
    width: "50px",
  },
}));

const actions = {
  deleteContact,
};

const OrganizationsTable = ({ rows, deleteContact }) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [contactId, setContactId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteContact = () => {
    deleteContact(contactId);
    setContactId("");
    setDialogOpen(false);
  };

  return (
    <Fragment>
      {/*DIALOG*/}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to delete organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteContact()}
            style={{ color: theme.palette.error.main }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "160px" }}>Name</TableCell>
                <TableCell style={{ width: "200px" }}>Email</TableCell>
                <TableCell style={{ width: "200px" }}>Contact Number</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.organizationName}</TableCell>
                    <TableCell>{row.organizationEmail}</TableCell>
                    <TableCell>{row.organizationContactNumber}</TableCell>
                    <TableCell>
                      {row.organizationAddress1}, {row.organizationAddress2 ? `${row.organizationAddress2},` : ""}
                      {row.organizationCity}, {row.organizationState}, {row.organizationZipCode}
                    </TableCell>
                    <TableCell>
                      <Grid item container>
                        <Grid item>
                          <Button
                            color={"primary"}
                            variant={"contained"}
                            size={"small"}
                            style={{ color: "white" }}
                            onClick={() =>
                              history.push(`/admin/organizations/view?id=${row.id}`)
                            }
                          >
                            View
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={"contained"}
                            size={"small"}
                            style={{
                              backgroundColor: theme.palette.themeBlue,
                              color: "white",
                              marginLeft: "0.5em",
                            }}
                            onClick={() =>
                              history.push(`/admin/organizations/edit?id=${row.id}`)
                            }
                          >
                            Edit
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: "0.5em" }}>
                          <Button
                            color={"secondary"}
                            variant={"contained"}
                            size={"small"}
                            style={{ color: "white" }}
                            onClick={() => {
                              setContactId(row.id);
                              setDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default connect(null, actions)(OrganizationsTable);
