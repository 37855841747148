import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import TextInput from "../../../common/form/TextInput";

import { updateSubCategory } from "../../../store/user/actions/categoryActions/categoryActions";
import SelectInput from "../../../common/form/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
}));

const validate = combineValidators({
  mainCategory: isRequired({ message: "Select Main Category" }),
  subCategory: isRequired({ message: "Sub Category is required" }),
});

const actions = {
  updateSubCategory,
};

const mapStateToProps = (state) => {
  let subCategory = {};
  let mainCategories = {};

  if (state.categories.subCategory && state.categories.subCategory.length > 0) {
    subCategory = state.categories.subCategory[0];
  }
  if (
    state.categories.mainCategories &&
    state.categories.mainCategories.length > 0
  ) {
    mainCategories = state.categories.mainCategories;
  }
  return {
    initialValues: state.categories.subCategory[0],
    subCategory,
    mainCategories,
  };
};

const EditSubCategory = ({
  updateSubCategory,
  subCategory,
  mainCategories,
  handleSubmit,
  setUpdate,
  submitting,
}) => {
  const classes = useStyles();

  const handleUpdateSubCategory = async (values) => {
    try {
      await updateSubCategory(subCategory.id, values);
      setUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction={"column"}>
      <Grid item container direction={"column"}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleUpdateSubCategory)}
        >
          {/*BUTTON/INPUT CONTAINER*/}

          <Grid
            item
            container
            alignItems={"flex-end"}
            className={classes.fieldWrapper}
            spacing={1}
          >
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Grid item container direction={"column"}>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"mainCategory"}
                    label={"Main Category"}
                    component={SelectInput}
                    type={"text"}
                    variant={"outlined"}
                  >
                    {mainCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.mainCategory}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item className={classes.fieldWrapper}>
                  <Field
                    name={"subCategory"}
                    label={"Sub Category Name"}
                    component={TextInput}
                    type={"text"}
                    variant={"outlined"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editSubCategory",
    validate,
    enableReinitialize: true,
  })(EditSubCategory)
);
