import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import MonthlyRentalPipeline from "../../ui/dashboard/MonthlyRentalPipeline";
import DashboardItem from "../../ui/dashboard/DashboardItem";
import { fetchDashboardValues } from "../../store/user/actions/dashboardActions/dashboardActions";
import { connect } from "react-redux";
import CalendarView from "../../ui/dashboard/CalendarView";
import Loading from "../../ui/loading/Loading";

const useStyles = makeStyles((theme) => ({
  pipelineWrapper: {
    marginLeft: "1em",
    marginRight: "1em",
    border: "1px solid" + theme.palette.primary.main,
  },
  wrapper: {
    marginTop: "1.5em",
    marginLeft: "1em",
    marginRight: "1em",
  },
  calendar: {
    marginLeft: "1em",
    marginRight: "1em",
  },
}));

const actions = {
  fetchDashboardValues,
};

const mapStateToProps = (state) => {
  let values = {};
  let organization = {};

  if (state.dashboard.values && state.dashboard.values.length > 0) {
    values = state.dashboard.values[0];
  }

  if (
    state.currentOrganization.organization &&
    state.currentOrganization.organization.length > 0
  ) {
    organization = state.currentOrganization.organization[0];
  }

  return {
    values,
    organization,
    loading: state.loading.loading
  };
};

const Dashboard = ({ fetchDashboardValues, values, organization, loading }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchDashboardValues(organization.id);
  }, [fetchDashboardValues, organization]);

  return (
    <Grid item container direction={"column"}>
      <Grid item className={classes.pipelineWrapper}>
        <MonthlyRentalPipeline values={values} loading={loading} />
      </Grid>
      <Grid item className={classes.wrapper}>
        <Grid item container>
          <DashboardItem
            title={"Rentals today"}
            value={values?.rentalsToday ? values.rentalsToday?.length : 0}
            loading={loading}
          />
          <DashboardItem
            title={"Deliveries today"}
            value={values?.deliveriesToday ? values.deliveriesToday?.length : 0}
            loading={loading}
          />
          <DashboardItem
            title={"Pickups today"}
            value={values?.pickupsToday ? values.pickupsToday?.length : 0}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.calendar}>

            <CalendarView loading={loading} />


      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(Dashboard);
