import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { Field, reduxForm } from "redux-form";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import TextInput from "../../../common/form/TextInput";
import { combineValidators, isRequired } from "revalidate";

import profileImage from "../../../assets/avatar/avatar.png";

import { openSnackbar } from "../../../store/user/actions/snackbarActions/snackbarActions";
import {
  fetchOrganizationUser,
  toggleOrganizationUserType,
  updateOrganizationUser,
  updateOrganizationUserAuthEmail,
} from "../../../store/organizationAdmin/actions/organizationUserActions/organizationUserActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  fullName: isRequired({ message: "Name is required" }),
  email: isRequired({ message: "Email is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
});

const actions = {
  openSnackbar,
  fetchOrganizationUser,
  toggleOrganizationUserType,
  updateOrganizationUserAuthEmail,
  updateOrganizationUser,
};

const mapStateToProps = (state) => {
  let organizationUser = {};

  if (
    state.organizationUsers.organizationUser &&
    state.organizationUsers.organizationUser.length > 0
  ) {
    organizationUser = state.organizationUsers.organizationUser[0];
  }
  return {
    initialValues: state.organizationUsers.organizationUser[0],
    profile: state.firebase.profile,
    organizationUser,
  };
};

const EditOrganizationUser = ({
  fetchOrganizationUser,
  toggleOrganizationUserType,
  updateOrganizationUserAuthEmail,
  updateOrganizationUser,
  handleSubmit,
  organizationUser,
  profile,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  const id = queryParams.get("id");

  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrganizationUser(id);
  }, [id, fetchOrganizationUser]);

  const handleUpdateOrganizationUser = async (values) => {
    setError("");
    let updateUserQuery = await updateOrganizationUserAuthEmail(
      id,
      values.email
    );

    if (updateUserQuery.data === "User Auth Email Updated") {
      await updateOrganizationUser(organizationUser, values);
      history.push('/settings/users')
    }
    if (
      updateUserQuery.data.message ===
      "The email address is improperly formatted."
    ) {
      setError(updateUserQuery.data.message);
    }

    if (
      updateUserQuery.data.message ===
      "The email address is already in use by another account."
    ) {
      setError(updateUserQuery.data.message);
    }
  };

  const handleOrganizationUserType = () => {
    toggleOrganizationUserType(id, organizationUser.organizationAdmin);
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"} style={{ width: "70%" }}>
        {/*FORM*/}
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(handleUpdateOrganizationUser)}
        >
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography
                variant={"h5"}
                style={matchesXS ? { fontSize: "1.5em" } : null}
              >
                Edit Organization User
              </Typography>
            </Grid>

            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/users")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*INPUT CONTAINER*/}
          <Grid item container style={{ marginTop: "2em" }}>
            {!matchesSM && (
              <Grid item lg={2} md={2}>
                {/*BLANK*/}
              </Grid>
            )}
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid item style={{ marginBottom: "2em" }}>
                <Grid item container justify={"center"}>
                  <Grid item>
                    <img
                      src={profileImage}
                      alt={`${organizationUser.fullName} Profile`}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"fullName"}
                  label={"Full Name"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"email"}
                  label={"Email"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item className={classes.fieldWrapper}>
                <Field
                  name={"contactNumber"}
                  label={"Contact Number"}
                  component={TextInput}
                  type={"text"}
                  variant={"outlined"}
                />
              </Grid>

              <Grid item style={{ marginTop: "0.5em" }}>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.primary.main }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.common.black,
                    }}
                  >
                    User Type:{" "}
                  </span>
                  {organizationUser.organizationAdmin
                    ? "Organization Admin"
                    : "Organization User"}
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: "0.5em" }}>
                <Button
                  size={"small"}
                  disabled={profile.uid === organizationUser.id}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => handleOrganizationUserType()}
                >
                  {organizationUser.organizationAdmin
                    ? "Make User"
                    : "Make Admin"}
                </Button>
              </Grid>

              {error && (
                <Grid item style={{ marginTop: "2em" }}>
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            alignItems={matchesSM ? "center" : null}
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={matchesSM ? null : { marginLeft: "auto" }}>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/settings/users")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editOrganizationUser",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
  })(EditOrganizationUser)
);
