import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../asyncActions/asyncActions";
import {
  FETCH_ALL_CONTACTS,
  FETCH_CONTACT,
} from "../../constants/contactsConstants/contactsConstants";

export const fetchAllContacts = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const contactsQuery = firestore
      .collection("contacts")
      .orderBy("lastName", "asc")
      .where("organizationId", "==", `${organizationId}`);

    try {
      dispatch(asyncActionStart());
      let query = await contactsQuery.get();

      let contacts = [];

      for (let i = 0; i < query.docs.length; i++) {
        let contact = {
          ...query.docs[i].data(),
          id: query.docs[i].id,
        };
        contacts.push(contact);
      }

      dispatch({
        type: FETCH_ALL_CONTACTS,
        payload: { contacts },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const fetchContact = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const contactQuery = firestore.collection("contacts").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      let query = await contactQuery.get();

      let contacts = [];

      if (query.exists) {
        let contact = {
          id: query.id,
          ...query.data(),
        };
        contacts.push(contact);
      }

      if (!query.exists) {
        return;
      }

      dispatch({ type: FETCH_CONTACT, payload: { contacts } });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const addNewContact = (values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const organizationId = state.firebase.profile.organizationId;

    const contactQuery = firestore.collection("contacts");

    try {
      dispatch(asyncActionStart());

      await contactQuery.add({
        ...values,
        createdAt: new Date().toISOString(),
        organizationId: organizationId,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const updateContact = (id, values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const contactQuery = firestore.collection("contacts").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await contactQuery.update({
        ...values,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};

export const deleteContact = (id, values) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const contactQuery = firestore.collection("contacts").doc(`${id}`);

    try {
      dispatch(asyncActionStart());

      await contactQuery.delete();

      dispatch(fetchAllContacts());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error);
    }
  };
};
