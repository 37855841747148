import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { reduxForm } from "redux-form";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAllContacts } from "../../store/user/actions/contactsActions/contactsActions";

import { openDialog } from "../../store/user/actions/dialogActions/dialogActions";

import { addNewOpportunity } from "../../store/user/actions/opportunityActions/opportunityActions";
import AddOpportunityDetails from "../../ui/opportunities/AddOpportunityDetails";
import { combineValidators, isRequired } from "revalidate";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0.4em",
  },
  fieldWrapper: {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icon: {
    fontSize: "3em",
    color: theme.palette.common.textGrey,
  },
}));

const validate = combineValidators({
  // EXISTING CLIENT
  clientId: isRequired({ message: "Client Name is required" }),

  // NEW CLIENT
  firstName: isRequired({ message: "First Name is required" }),
  lastName: isRequired({ message: "Last Name is required" }),
  address1: isRequired({ message: "Address is required" }),
  city: isRequired({ message: "City is required" }),
  state: isRequired({ message: "State is required" }),
  zipCode: isRequired({ message: "Zip Code is required" }),
  contactNumber: isRequired({ message: "Contact Number is required" }),
  email: isRequired({ message: "Email is required" }),

  // DELIVERY ADDRESS
  // deliveryContactName: isRequired({ message: "Contact Name is required" }),
  deliveryAddress1: isRequired({ message: "Address is required" }),
  deliveryCity: isRequired({ message: "City is required" }),
  deliveryState: isRequired({ message: "State is required" }),
  deliveryZipCode: isRequired({ message: "Zip Code is required" }),
  // deliveryContactNumber: isRequired({ message: "Contact Number is required" }),

  // INQUIRY DETAILS
  title: isRequired({ message: "Inquiry Name is required" }),
  setupDate: isRequired({ message: "Setup Date is required" }),
  pickupDate: isRequired({ message: "Pickup Date is required" }),
  eventStartDate: isRequired({ message: "Event Start Date is required" }),
  eventEndDate: isRequired({ message: "Event End Date is required" }),
});

const actions = {
  openDialog,
  addNewOpportunity,
  fetchAllContacts,
};

const mapStateToProps = (state) => {
  let contacts = [];

  if (state.contacts.allContacts && state.contacts.allContacts.length > 0) {
    contacts = state.contacts.allContacts;
  }

  return {
    contacts,
  };
};

const AddOrder = ({
  addNewOpportunity,
  fetchAllContacts,
  contacts,
  handleSubmit,
  submitting,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [contact, setContact] = useState({});
  const [showAddress, setShowAddress] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(true);

  const [showNewClient, setShowNewClient] = useState(false);

  useEffect(() => {
    fetchAllContacts();
  }, [fetchAllContacts]);

  const handleAddInquiry = async (values) => {
    try {
      let opportunity = await addNewOpportunity(values, showNewClient, "order");
      history.push(`/orders/edit?id=${opportunity.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactChange = (id) => {
    const filteredContacts = contacts.filter((contact) => contact.id === id);
    setContact(filteredContacts[0]);
    setShowAddress(true);
  };

  const handleNewClient = () => {
    setContact({});
    setShowAddress(false);
    setShowNewClient(true);
  };

  const handleExistingClient = () => {
    setContact({});
    setShowAddress(false);
    setShowNewClient(false);
  };

  const handleDeliveryAddress = () => {
    setShowDeliveryAddress(!showDeliveryAddress);
  };

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item container direction={"column"}>
        {/*FORM*/}
        <form autoComplete={"off"} onSubmit={handleSubmit(handleAddInquiry)}>
          {/*BUTTON/TITLE CONTAINER*/}
          <Grid item container direction={"column"} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h4"}>Add Order</Typography>
            </Grid>

            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/orders")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/*DETAILS*/}
          <AddOpportunityDetails
            contacts={contacts}
            contact={contact}
            handleContactChange={handleContactChange}
            showAddress={showAddress}
            showNewClient={showNewClient}
            handleNewClient={handleNewClient}
            handleExistingClient={handleExistingClient}
            showDeliveryAddress={showDeliveryAddress}
            handleDeliveryAddress={handleDeliveryAddress}
          />

          {/*BUTTON CONTAINER*/}
          <Grid
            item
            container
            direction={"column"}
            style={{ marginTop: "2em" }}
          >
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant={"outlined"}
                color={"primary"}
                className={classes.button}
                type={"submit"}
                disabled={submitting}
              >
                Create
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                className={classes.button}
                onClick={() => history.push("/orders")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "addOrder", validate })(AddOrder));
