import React, { Fragment, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Typography from "@material-ui/core/Typography";
import { addDays, format } from "date-fns";
import { connect } from "react-redux";
import ActivityItem from "./ActivityItem";
import Loading from "../loading/Loading";

const useStyles = makeStyles((theme) => ({
  availabilityContainer: {
    border: "1px solid" + theme.palette.primary.main,
    borderRadius: "5px",
  },
  dateColumn: {
    width: "14.28%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "33.33%",
    },
  },
  todaysDate: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const mapStateToProps = (state) => {
  let activities = [];

  if (state.dashboard.values && state.dashboard.values.length > 0) {
    activities = state.dashboard.values[0].allActivity;
  }

  return {
    activities,
  };
};

const CalendarView = ({ activities, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMDUP = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const isoDate = new Date();
  const [next, setNext] = useState(0);

  // HANDLERS
  const handleNext = () => {
    if (matchesMDUP) {
      setNext(next + 7);
    }
    if (matchesSM) {
      setNext(next + 4);
    }

    if (matchesXS) {
      setNext(next + 3);
    }
  };

  const handlePrevious = () => {
    if (matchesMDUP) {
      setNext(next - 7);
    }
    if (matchesSM) {
      setNext(next - 4);
    }

    if (matchesXS) {
      setNext(next - 3);
    }
  };

  let activityData = [
    {
      day1: {
        date: format(addDays(isoDate, next), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next), "EEE MMM do")
        ),
      },
    },
    {
      day2: {
        date: format(addDays(isoDate, next + 1), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 1), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 1), "EEE MMM do")
        ),
      },
    },
    {
      day3: {
        date: format(addDays(isoDate, next + 2), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 2), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 2), "EEE MMM do")
        ),
      },
    },
    {
      day4: {
        date: format(addDays(isoDate, next + 3), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 3), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 3), "EEE MMM do")
        ),
      },
    },

    {
      day5: {
        date: format(addDays(isoDate, next + 4), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 4), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 4), "EEE MMM do")
        ),
      },
    },

    {
      day6: {
        date: format(addDays(isoDate, next + 5), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 5), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 5), "EEE MMM do")
        ),
      },
    },

    {
      day7: {
        date: format(addDays(isoDate, next + 6), "EEE MMM do"),
        setupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.setupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 6), "EEE MMM do")
        ),
        pickupActivity: activities?.filter(
          (activity) =>
            format(new Date(activity.pickupDate), "EEE MMM do") ===
            format(addDays(isoDate, next + 6), "EEE MMM do")
        ),
      },
    },
  ];

  return (
    <Fragment>
      <Grid
        item
        container
        direction={"column"}
        className={classes.availabilityContainer}
      >
        <Grid item>
          {/*ARROWS*/}
          <Grid item container>
            <Grid item>
              <IconButton onClick={() => handlePrevious()}>
                <SkipPreviousIcon />
              </IconButton>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <IconButton onClick={() => handleNext()}>
                <SkipNextIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/*DATES*/}
          <Grid item container className={classes.dateContainer}>
            <Grid item className={classes.dateColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {activityData[0]?.day1.date}
              </Typography>
            </Grid>
            <Grid item className={classes.dateColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next + 1), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {activityData[1]?.day2.date}
              </Typography>
            </Grid>
            <Grid item className={classes.dateColumn}>
              <Typography
                variant={"caption"}
                className={
                  format(addDays(isoDate, next + 2), "EEE MMM do y") ===
                  format(isoDate, "EEE MMM do y")
                    ? classes.todaysDate
                    : null
                }
              >
                {activityData[2]?.day3.date}
              </Typography>
            </Grid>

            {!matchesXS && (
              <Grid item className={classes.dateColumn}>
                <Typography
                  variant={"caption"}
                  className={
                    format(addDays(isoDate, next + 3), "EEE MMM do y") ===
                    format(isoDate, "EEE MMM do y")
                      ? classes.todaysDate
                      : null
                  }
                >
                  {activityData[3]?.day4.date}
                </Typography>
              </Grid>
            )}

            {!matchesSM && (
              <Fragment>
                <Grid item className={classes.dateColumn}>
                  <Typography
                    variant={"caption"}
                    className={
                      format(addDays(isoDate, next + 4), "EEE MMM do y") ===
                      format(isoDate, "EEE MMM do y")
                        ? classes.todaysDate
                        : null
                    }
                  >
                    {activityData[4]?.day5.date}
                  </Typography>
                </Grid>

                <Grid item className={classes.dateColumn}>
                  <Typography
                    variant={"caption"}
                    className={
                      format(addDays(isoDate, next + 5), "EEE MMM do y") ===
                      format(isoDate, "EEE MMM do y")
                        ? classes.todaysDate
                        : null
                    }
                  >
                    {activityData[5]?.day6.date}
                  </Typography>
                </Grid>
                <Grid item className={classes.dateColumn}>
                  <Typography
                    variant={"caption"}
                    className={
                      format(addDays(isoDate, next + 6), "EEE MMM do y") ===
                      format(isoDate, "EEE MMM do y")
                        ? classes.todaysDate
                        : null
                    }
                  >
                    {activityData[6]?.day7.date}
                  </Typography>
                </Grid>
              </Fragment>
            )}
          </Grid>

          {loading && (
              <Loading/>
          )}
          {!loading && (
            <Grid item container>
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[0]?.day1.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[0]?.day1.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[1]?.day2.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[1]?.day2.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[2]?.day3.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[2]?.day3.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>

          {!matchesXS && (
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[3]?.day4.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[3]?.day4.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>
            )}

          {!matchesSM && (
            <Fragment>
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[4]?.day5.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[4]?.day5.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>

            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[5]?.day6.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[5]?.day6.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>
            <Grid
            item
            className={classes.dateColumn}
            style={{ padding: "0.5em" }}
            >
          {activityData[6]?.day7.setupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"setup"}
            />
            ))}

          {activityData[6]?.day7.pickupActivity.map((activity) => (
            <ActivityItem
            key={activity.id}
            activity={activity}
            type={"pickup"}
            />
            ))}
            </Grid>
            </Fragment>
            )}
            </Grid>
          )}


        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(mapStateToProps)(CalendarView);
