import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import LiveSearch from "../../../ui/search/LiveSearch";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { fetchAllOrganizations } from "../../../store/appAdmin/actions/appAdminActions/appAdminActions";
import OrganizationsTable from "../../../ui/appAdmin/settings/organizations/OrganizationsTable";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },

  buttonWrapper: {
    paddingLeft: "0.5em",
  },
  tableContainer: {
    marginTop: "2em",
  },
}));

const actions = {
  fetchAllOrganizations,
};

const mapStateToProps = (state) => {
  let organizations = [];

  if (
    state.organizations.allOrganizations &&
    state.organizations.allOrganizations.length > 0
  ) {
    organizations = state.organizations.allOrganizations;
  }
  return {
    organizations,
  };
};

const AppAdminOrganizations = ({ fetchAllOrganizations, organizations }) => {
  const classes = useStyles();

  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchAllOrganizations();
  }, [fetchAllOrganizations]);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"column"}
      className={classes.container}
    >
      <Grid item>
        <Typography variant={"h4"}>Organizations</Typography>
      </Grid>

      <Grid item container alignItems={"center"} style={{ marginTop: "2em" }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LiveSearch
            data={organizations}
            setSearchResults={setSearchResults}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid item container justify={"flex-end"}>
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                size={"medium"}
                startIcon={<AddIcon />}
                onClick={() => history.push("/admin/organizations/new")}
              >
                Add Organization
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.tableContainer}>
        <OrganizationsTable
          rows={
            searchResults < 1
              ? organizations
              : organizations.filter((contact) => contact.search)
          }
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, actions)(AppAdminOrganizations);
