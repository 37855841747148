import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_CONTACTS,
  FETCH_CONTACT,
} from "../../constants/contactsConstants/contactsConstants";

const initialState = {
  allContacts: [],
  contact: [],
};

const allContacts = (state, payload) => {
  return {
    ...state,
    allContacts: payload.contacts,
  };
};

const getContact = (state, payload) => {
  return {
    ...state,
    contact: payload.contacts,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_CONTACTS]: allContacts,
  [FETCH_CONTACT]: getContact,
});
