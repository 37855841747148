import { createReducer } from "../../../../common/util/reducerUtil";
import {
  FETCH_ALL_INVENTORY,
  FETCH_INVENTORY_PRODUCT,
  FETCH_INVENTORY_PRODUCT_HISTORY,
} from "../../constants/inventoryConstants/inventoryConstants";

const initialState = {
  allInventory: [],
  product: [],
  productHistory: [],
};

const getAllInventory = (state, payload) => {
  return {
    ...state,
    allInventory: payload.inventory,
  };
};

const getInventoryProduct = (state, payload) => {
  return {
    ...state,
    product: payload.product,
  };
};

const getInventoryProductHistory = (state, payload) => {
  return {
    ...state,
    productHistory: payload.productHistory,
  };
};

export default createReducer(initialState, {
  [FETCH_ALL_INVENTORY]: getAllInventory,
  [FETCH_INVENTORY_PRODUCT]: getInventoryProduct,
  [FETCH_INVENTORY_PRODUCT_HISTORY]: getInventoryProductHistory,
});
