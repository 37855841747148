import React from "react";
import { connect } from "react-redux";
import SuccessSnackbar from "./success/SuccessSnackbar";

const snackbarLookup = {
  SuccessSnackbar,
};

const mapStateToProps = (state) => ({
  currentSnackbar: state.snackbar.snackbar,
});

const SnackbarManager = ({ currentSnackbar }) => {
  let renderedSnackbar;

  if (currentSnackbar) {
    const { snackbarType, snackbarProps } = currentSnackbar;
    const DialogComponent = snackbarLookup[snackbarType];

    renderedSnackbar = <DialogComponent {...snackbarProps} />;
  }

  return <span>{renderedSnackbar}</span>;
};

export default connect(mapStateToProps)(SnackbarManager);
